import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import icon_logo from "../../assets/images/icon_logo.png";
import ic_hide_eyes from "../../assets/images/ic_hide_eyes.svg";
import ic_show from "../../assets/images/ic_show_eyes.svg";
import Logo from "../../assets/images/wifi-attendance.svg";
import { ToastMsg } from "../../pages/TostMsg";
import OtpInput from 'react-otp-input';
import OTPInput from "react-otp-input";

const CreateAccount = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");

  const [isCreateAccount,setIsCreateAccount] = useState(false)
  const [isOtpValidation,setIsOtpValidation] = useState(false)

  const [otp,setOtp] = useState('')
  const [showOtpScreen,setShowOtpScreen] = useState(false)

  const [userResponse,setUserResponse]= useState({})

  const navigate = useNavigate();
  const [user, setUser] = useState({
    Phone: "",
    Email: "",
    Password: "",
    ProfileName: "",
  });
  console.log(user, "user");
  const [errMsg, setErrMsg] = useState({
    Phone: "",
    Email: "",
    Password: "",
    ProfileName: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const usercopy = { ...user };
    const errMsgcopy = { ...errMsg };
    usercopy[name] = value;
    errMsgcopy[name] = "";
    setErrMsg(errMsgcopy);
    setUser(usercopy);
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return password.length > minLength ? false : regex.test(password);
  };

  const AccountCreateApi = (e) => {
    e.preventDefault();
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/;
    const newErrMsg = {};
  
    if (user.ProfileName.trim().length === 0) {
      newErrMsg.ProfileName = "Please Enter Profile Name";
    }
    if (user.Email.trim().length === 0) {
      newErrMsg.Email = "Please  Enter Email ID";
    } else if (!regex.test(user.Email)) {
      newErrMsg.Email = "Please  Enter Valid Email ID";
    }
    if (user.Phone.trim().length === 0) {
      newErrMsg.Phone = "Please  Enter Phone Number";
    } else if (user.Phone.length !== 10) {
      newErrMsg.Phone = "Phone number must be 10 digits long";
    }
if (!validatePassword(user.Password)) {
    if (user.Password.length > 8) {
      newErrMsg.Password = "You can only enter 8 characters.";
    } else {
      newErrMsg.Password = "Use 8 or more characters with a mix of letters, numbers & symbols";
    }
  }
  
    if (Object.keys(newErrMsg).length > 0) {
      setErrMsg(newErrMsg);
      return;
    }
      setIsCreateAccount(true)

      PostCallWithErrorResponse(
        ApiConfig.CREATE_ACCOUNT,
        JSON.stringify({ ...user })
      )
        .then((res) => {
          console.log(res);
          if (res.json && res.json?.Success === true) {
            ToastMsg("success", res.json.Message);
            setUserResponse(res?.json?.Data)
            setShowOtpScreen(true)
          } else {
            ToastMsg("error", res.json.Message);
          }
          setIsCreateAccount(false)
        })
        .catch((err) => console.log(err));
    // }
  };

  const handleClickShowPassword = () => {
    setUser({ ...user, showPassword: !user.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleNavigate = (path, state) => {
    navigate(path, { state });
    console.log(`Navigating to ${path} with state:`, state);
  };
  const otpValidation = ()=>{

    userResponse.Otp=otp

    setIsOtpValidation(true)

    PostCallWithErrorResponse(ApiConfig?.VERYFY_OTP,JSON.stringify({
      ...userResponse
    })).then((res)=>{

      if(res?.json?.Success){
        navigate('/OrganizationRegistration', { state: { OTPresponse: res?.json?.Data } });
        ToastMsg('success',res?.json?.Message)
        console.log("OTP Successs--->",res?.json)

      }else{

        ToastMsg('error',res?.json?.Message)

      }

      setIsOtpValidation(false)

    })

  }

  return (
    <main>
      <div className="main-auth createAccount " id="create-acc-main">
        <div className="top-logo ms-5">
          <img src={Logo} alt="Logo" height={40} width={240} />
        </div>
        {
          showOtpScreen?<div className="d-flex justify-content-center align-items-center " style={{marginTop:"10%"}}>
            <div className="text-center">
            <div>
               <h1>OTP Verification</h1>
               <p className="text-secondary">Otp Sended On Your Email Id</p>
            </div>
           
            <div className="d-flex justify-content-center mt-3">
              <OtpInput
              onChange={setOtp}
              value={otp}
              numInputs={4}
              renderInput={(props) => <input {...props} 
              style={{marginLeft:'5px',width:'50px',height:'50px',textAlign:"center",fontSize:"20px",fontWeight:'bolder'}} />}
              />
            </div>
            <div className="mt-3 ">
              <button className="modal-create-btn"
              onClick={otpValidation}
              disabled={isOtpValidation}
              >Verify Otp</button>
            </div>
            </div>
          </div>:
        <div className="create-ac-form">
          <form action="">
            <div className="top-heading">
              <div>
                <label className="heading">Create an account</label>
              </div>
              <div>
                <label htmlFor="" className="para-below">
                  Already have an account? <Link to="/">Log in</Link>
                </label>
              </div>
            </div>
            <div className="form-control-div">
              <label for="exampleFormControlInput1" className="form-label">
                What should we call you?
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter your profile name"
                name="ProfileName"
                value={user.ProfileName}
                onChange={handleChange}
              />
                  {errMsg.ProfileName && (
        <span className="text-danger ">{errMsg.ProfileName}</span>
      )}
            </div>
            <div className="form-control-div">
              <label for="exampleFormControlInput1" className="form-label">
                What’s your email?
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter your email address"
                name="Email"
                onChange={handleChange}
              />
              {errMsg.Email && (
                <span className="text-danger text-center">{errMsg.Email}</span>
              )}
            </div>
            <div className="form-control-div">
              <label for="exampleFormControlInput1" className="form-label">
                What is your Contact number ?
              </label>
              <input
                type="number"
                maxLength={10}
                minLength={10}
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter contact number"
                name="Phone"
                onChange={handleChange}
              />
              {errMsg.Phone && (
                <span className="text-danger text-center">{errMsg.Phone}</span>
              )}
            </div>
            <div className="form-control-div changeMB">
              <div className="pass-main">
                <div className="left-pass">
                  <label for="exampleFormControlInput2" className="form-label">
                    Password
                  </label>
                </div>
                <div
                  className="right-pass"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  <img
                    src={user.showPassword == true ? ic_show : ic_hide_eyes}
                    alt="ic_hide_eyes"
                  />

                  <label for="exampleFormControlInput2" className="form-label">
                    {user.showPassword == true ? "Show" : "Hide"}
                  </label>
                </div>
              </div>

              <input
                className="form-control"
                id="exampleFormControlInput2"
                placeholder="Enter your password"
                type={user.showPassword ? "text" : "password"}
                name="Password"
                onChange={(event) => {
                  handleChange(event);
                }}
                value={user.Password}
              />
              {errMsg.Password && (
                <span className="text-danger text-center">
                  {errMsg.Password}
                </span>
              )}
              <div className="below-text">
                <label>
                  Use 8 or more characters with a mix of letters, numbers &
                  symbols
                </label>
              </div>
            </div>
            <div className="para-above-btn">
              <label htmlFor="">
                By creating an account, you agree to the{" "}
                <Link to="#">Terms of use</Link> and{" "}
                <Link to="#">Privacy Policy</Link> .
              </label>
            </div>
            <div className="ac-button">
              <Link to="">
                <button type="submit" onClick={AccountCreateApi}
                disabled={isCreateAccount}
                >
                  Create an account
                </button>
              </Link>
            </div>
            <div className="need">
              <p>
                <label htmlFor="">Do you need help?</label>
              </p>
              <p>
                <Link to="#">Customer support</Link>
              </p>
            </div>
          </form>
        </div>
        }
      </div>
    </main>
  );
};

export default CreateAccount;
