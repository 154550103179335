import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import shareIcon from "../../assets/images/ic_export1.svg";
import BookIcon from "../../assets/images/ic_excel.svg";
import Modal from "react-bootstrap/Modal";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
// import DelectAc from '../../assets/images/DelectAc.svg'
import DelectAc from "../../assets/images/DelectAcBlc.svg";
// import Editac from '../../assets/images/Editac.svg'
import Editac from "../../assets/images/bx_edit.svg";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
  DeleteCallWithErrorResponseWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import { notifyError, notifySuccess } from "../../toaster/notify";
import Pagination from "../../sharedComponent/Pagination";
import { getDateddmmyyyy } from "../../common";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";
import { useSearchParams } from "react-router-dom";

const Projects = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [filterBy, setFilterBy] = useState("Active");
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);
  const [departmentOptions, setdepartmentOptions] = useState([]);
  const [adminsList, setAdminsList] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const [projectData, setProjectData] = useState([]);

  const [deleteProjectName, setDeleteProjectName] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(5);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [useDeleteTaskList, setDeleteTaskList] = useState("");
  const [excelLoading, setExcelLoading] = useState(false);

  const searchedDataPageCounts =
    projectData &&
    projectData.length &&
    projectData.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  const [projStateData, setProjStateData] = useState({
    ProjectName: "",
    Description: "",
    ProjAdmin: 0,
    StartDate: "",
    EndDate: "",
    IsInternal: false,
    EstimatedTime: "",
    Status: 0,
    DepartmentId: "",
    ProjectAwardedDate: "",
    ContractedModel: false,
    Rate: 0,
    ClientId: 0,
  });

  let projectAwardedDate =
    projStateData &&
    projStateData?.ProjectAwardedDate?.split("T")[0].replaceAll("/", "-");
  let StartDate =
    projStateData &&
    projStateData.StartDate?.split("T")[0].replaceAll("/", "-");
  let EndDate =
    projStateData && projStateData.EndDate?.split("T")[0].replaceAll("/", "-");

  const changeData = (e) => {
    const { name, value } = e.target;
    setProjStateData({
      ...projStateData,
      [name]: value,
    });
  };

  const [clientErr, setClientErr] = useState({
    ProjectNameErr: "",
    DescriptionErr: "",
    ProjAdminErr: "",
    StartDateErr: "",
    EndDateErr: "",
    IsInternalErr: false,
    EstimatedTimeErr: "",
    Status: 0,
    DepartmentIdErr: "",
    ProjectAwardedDateErr: "",
    ContractedModelErr: false,
    RateErr: "",
    ClientIdErr: "",
  });

  const [createProject] = useSearchParams();

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setProjStateData({
      ProjectName: "",
      Description: "",
      ProjAdmin: 0,
      StartDate: "",
      EndDate: "",
      IsInternal: false,
      EstimatedTime: "",
      Status: 0,
      DepartmentId: "",
      ProjectAwardedDate: "",
      ContractedModel: false,
      Rate: 0,
      ClientId: 0,
    });
  };

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    PostCallWithErrorResponse(ApiConfig.PROJECT_DOWNLOAD_EXCEL)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addprojStateData = (e) => {
    e.preventDefault();
    addProject();
    // if (projStateData.ProjectName === "") {
    //     setClientErr({
    //         ...clientErr,
    //         ProjectNameErr: "Please Enter Project Name"
    //     })
    //     return;
    // }
    // if (projStateData.Description === "") {
    //     setClientErr({
    //         ...clientErr,
    //         DescriptionErr: "Please Enter Description"
    //     })
    //     return;
    // }
    // if (projStateData.ProjAdmin === 0) {
    //     setClientErr({
    //         ...clientErr,
    //         ProjAdminErr: "Please Select Project/Admin Name"
    //     })
    //     return;
    // }
    // if (projStateData.StartDate === "") {
    //     setClientErr({
    //         ...clientErr,
    //         StartDateErr: "Please Enter Start Date"
    //     })
    //     return;
    // }
    // if (projStateData.EndDate === "") {
    //     setClientErr({
    //         ...clientErr,
    //         EndDateErr: "Please Enter End Date"
    //     })
    //     return;
    // }
    // if (projStateData.ClientSelectName.length == 0) {
    //     setClientErr({
    //         ...clientErr,
    //         ClientNameSelectErr: "Please Select Client Name"
    //     })
    //     return;
    // }
  };

  const addProject = (event) => {
    event.preventDefault();
    PostCallWithErrorResponse(
      ApiConfig.ADD_PROJECT,
      JSON.stringify(projStateData)
    )
      .then((res) => {
        if (res?.json?.Success === true) {
          setShow(false);
          notifySuccess("Project sdded successfully");
          fetchProjectList();
        }
      })
      .catch((err) => {
        console.log("error,", err);
      });
  };

  // Client option List
  const FetchModalprojStateData = () => {
    simpleGetCallWithToken(ApiConfig.GET_MODAL_CLIENT_OPTIONS)
      .then((res) => {
        if (res.Success === true) {
          setClientOptions(res?.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Department Option List
  const fetchModalDepartment = () => {
    simpleGetCallWithToken(ApiConfig.GET_DEPARTMENT_MODAL_OPTIONS)
      .then((res) => {
        if (res.Success === true) {
          setdepartmentOptions(res?.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (createProject.get("createProject") === "CreateProject") {
      handleShow();
    }
  }, []);

  // Admin List Option List
  const fetchModalAdmins = () => {
    simpleGetCallWithToken(ApiConfig.GET_ADMIN_NAMES_MODAL_OPTIONS)
      .then((res) => {
        if (res.Success === true) {
          setAdminsList(res?.Data);
        }
      })
      .catch((err) => {
        console.log("fetchModalAdmins", err);
      });
  };
  console.log("");
  //Owner List Option List
  const fetchOwnerAdmin = () => {
    simpleGetCallWithToken(ApiConfig.GET_OWNER_NAMES_MODEL_OPTIONS)
      .then((res) => {
        if (res.Success === true) {
          setOwnerList(res?.Data);
        }
      })
      .catch((err) => {
        console.log("fetchOwnerAdmin", err);
      });
  };

  const fetchProjectList = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_PROJECT_LIST)
      .then((res) => {
        if (res.Success === true) {
          setLoading(false);
          setProjectData(res?.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchProjectList();
  }, []);
  const [projBool, setProjBool] = useState(false);
  useEffect(() => {
    setProjBool(projBool);
  }, [projBool]);
  // GET PROJECT EDITED DATA BY PROJECT ID API
  const getProjectDataById = (projectId) => {
    console.log("projectId,", projectId);
    let payLoad = {
      ProjectId: projectId,
    };

    PostCallWithErrorResponse(
      ApiConfig.POST_DATA_WITH_PROJECT_ID,
      JSON.stringify(payLoad)
    )
      .then((res) => {
        console.log("res,", res);
        if (res.json.Success === true) {
          let responce = res.json.Data;

          setProjStateData({
            projectId: responce?.Id,
            ClientId: responce?.CustomerId,
            ProjectName: responce?.ProjectName,
            Description: responce?.Description,
            ProjAdmin: responce?.ProjAdmin,
            StartDate: responce?.StartDate,
            EndDate: responce?.EndDate,
            IsInternal: responce?.IsInternal,
            EstimatedTime: responce?.EstimatedTime,
            Status: responce?.Status,
            DepartmentId: responce?.DepartmentId,
            ProjectAwardedDate: responce?.ProjectAwardedDate,
            ContractedModel: responce?.ContractedModel,
            Rate: responce?.Rate,
          });
          setProjBool(responce?.IsInternal);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // UPDATE PROJECT API
  const updateProject = () => {
    PostCallWithErrorResponse(
      ApiConfig.UPDATE_PROJECT,
      JSON.stringify(projStateData)
    )
      .then((res) => {
        if (res.json.Success === true) {
          notifySuccess("Project updated successfully");
          fetchProjectList();
          setEdit(false);
        } else {
          console.log("error res,", res);
        }
      })
      .catch((err) => {
        console.log("error response,", err);
      });
  };

  // DELETE PROJECT API
  const deleteProject = (projectId) => {
    let payLoad = {
      ProjectId: projectId,
    };

    DeleteCallWithErrorResponseWithToken(
      ApiConfig.DELETE_PROJECT,
      JSON.stringify(payLoad)
    )
      .then((res) => {
        if (res.json.Success === true) {
          notifyError("Project deleted successfully");
          fetchProjectList();
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => {
        console.log("error response", err);
      });
  };

  const displayAdminTask = (
    <table>
      <thead>
        <tr className="firstTabelRow">
          <th>Sr.no</th>
          <th>Project Name</th>
          <th>Project Admin</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Estimate Hours</th>
          <th>Is Internal</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {projectData
          ?.filter((data) => data?.Status == filterBy)
          .slice(startIndex, endIndex)
          .map((admin, index) => {
            return (
              <tr className="table-row-custom" key={index}>
                <td>{startIndex + index + 1}</td>
                <td>{admin.ProjectName ? admin.ProjectName : "No Data"}</td>
                <td>{admin.AdminName ? admin.AdminName : "No Data"}</td>
                {/* <td>{moment(admin.StartDate).format("en-US")}</td> */}
                <td>
                  {admin.StartDate
                    ? getDateddmmyyyy(admin.StartDate)
                    : "No Data"}
                </td>
                <td>{getDateddmmyyyy(admin.EndDate)}</td>
                <td>{admin.BudgetHours ? admin.BudgetHours : "No Data"}</td>
                <td>
                  {String(admin?.IsInternal) == "true"
                    ? "Internal"
                    : "External"}
                </td>
                <td>{admin.Status ? admin.Status : "No Data"}</td>
                <td>
                  {permissions && permissions["Edit Project"] && (
                    <img
                      src={Editac}
                      onClick={() => {
                        getProjectDataById(admin.ProjectId);
                        setEdit(true);
                        FetchModalprojStateData();
                        fetchModalDepartment();
                        fetchOwnerAdmin();
                      }}
                      className="mx-2 editBtn"
                      alt=""
                    />
                  )}
                  {permissions && permissions["Delete Project"] && (
                    <img
                      src={DelectAc}
                      // onClick={() => { deleteProject(admin.ProjectId)
                      onClick={() => {
                        setDeleteProjectName(
                          admin.ProjectName ? admin.ProjectName : "project"
                        );
                        handleShow3();
                        setDeleteTaskList(admin.ProjectId);
                      }}
                      className="deleteBtn"
                      alt=""
                    />
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
      {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
    </table>
  );

  const displaySearchAdminTask = (
    <table>
      <thead>
        <tr className="firstTabelRow">
          <th>Sr.no</th>
          <th>Project Name</th>
          <th>Project Admin</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Estimate Hours</th>
          <th>Is Internal</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {projectData
          .filter((item) =>
            item.ProjectName.toLowerCase().includes(searchValue.toLowerCase())
          )
          .slice(startIndex, endIndex)
          ?.filter((data) => data?.Status == filterBy)
          .map((admin, index) => {
            return (
              <tr className="table-row-custom" key={index}>
                <td>{startIndex + index + 1}</td>
                <td>{admin.ProjectName ? admin.ProjectName : "No Data"}</td>
                <td>{admin.AdminName ? admin.AdminName : "No Data"}</td>
                <td>{getDateddmmyyyy(admin.StartDate)}</td>
                <td>{getDateddmmyyyy(admin.EndDate)}</td>
                <td>{admin.BudgetHours ? admin.BudgetHours : "No Data"}</td>
                <td>
                  {String(admin?.IsInternal) == "true"
                    ? "Internal"
                    : "External"}
                </td>
                <td>{admin.Status ? admin.Status : "No Data"}</td>
                <td>
                  {permissions && permissions["Edit Project"] && (
                    <img
                      src={Editac}
                      onClick={() => {
                        getProjectDataById(admin.ProjectId);
                        setEdit(true);
                        FetchModalprojStateData();
                        fetchModalDepartment();
                        fetchOwnerAdmin();
                      }}
                      className="mx-2 editBtn"
                      alt=""
                    />
                  )}
                  {permissions && permissions["Delete Project"] && (
                    <img
                      src={DelectAc}
                      onClick={() => {
                        // deleteProject(admin.ProjectId)
                        handleShow3();
                        setDeleteTaskList(admin.ProjectId);
                      }}
                      className="deleteBtn"
                      alt=""
                    />
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
      {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
    </table>
  );

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Projects</h6>
            {permissions && permissions["Create Project"] && (
              <button
                className="create-btn-sub-header"
                variant="primary"
                onClick={() => {
                  handleShow();
                  FetchModalprojStateData();
                  fetchModalDepartment();
                  fetchOwnerAdmin();
                }}
              >
                + Add Project
              </button>
            )}
          </div>
          <div className="d-flex justify-content-between mainHeader custom-mb">
            <div className="role-table-header-main">
              <div className="search-input-wrapper m-0">
                <input
                  type="text"
                  placeholder="Search Project Name"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper-main">
                <p>Entries per page </p>&nbsp;
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
              <div className="entries-wrapper-main">
                <p>Filter By Status </p>&nbsp;
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setFilterBy(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="Active">Active</option>
                  <option value="New">New</option>
                  <option value="Close">Close</option>
                  <option value="Holded">Hold</option>
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-between mainIcons">
              {permissions && permissions["Project Bulk Upload"] && (
                <div className="shareIcon mx-3">
                  <img src={shareIcon} className="innerIconsBtn" alt="" />
                </div>
              )}
              {permissions && permissions["Project Export"] && (
                <div className="booksIcon">
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={BookIcon}
                      onClick={(e) => downloadExcelFile(e)}
                      className="innerIconsBtn"
                      alt=""
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            {loading ? (
              <div>
                <Loader />
              </div>
            ) : (
              <>
                {projectData && projectData.length > 0 ? (
                  <>
                    {searchValue === ""
                      ? displayAdminTask
                      : displaySearchAdminTask}
                  </>
                ) : (
                  <>
                    <NoData />
                  </>
                )}
              </>
            )}
          </div>
          <Pagination
            data={projectData?.filter((data) => data?.Status == filterBy)}
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
            searchValue={searchValue}
            searchedDataPageCounts={searchedDataPageCounts}
          />
        </div>
      </div>

      {/* MODALS*/}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Add New Project
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="modalProp mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Project Name
                </label>
                <input
                  type="text"
                  name="ProjectName"
                  onChange={(e) => {
                    changeData(e);
                    setClientErr({
                      ...clientErr,
                      ProjectNameErr: "",
                    });
                  }}
                  className="form-control tasKCategory1"
                />
                {clientErr.ProjectNameErr && (
                  <label className="validationTxt">
                    {clientErr.ProjectNameErr}
                  </label>
                )}
              </div>
              <div className="modalProp mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Client Name
                </label>
                <select
                  className="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setProjStateData({
                      ...projStateData,
                      ClientId: Number(e.target.value),
                    });
                    setClientErr({
                      ...clientErr,
                      CustomerName: "",
                    });
                  }}
                  name="ClientId"
                  value={projStateData?.ClientId}
                >
                  <option>Select Client Name</option>
                  {clientOptions.map((name, index) => {
                    return (
                      <option value={Number(name.Id)}>
                        {name.CustomerName}
                      </option>
                    );
                  })}
                </select>
                {clientErr.ClientLocationErr && (
                  <label className="validationTxt">
                    {clientErr.ClientLocationErr}
                  </label>
                )}
              </div>
              <div className="modalProp mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Department
                </label>
                <select
                  className="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setProjStateData({
                      ...projStateData,
                      DepartmentId: e.target.value,
                    });
                    setClientErr({
                      ...clientErr,
                      DepartmentName: "",
                    });
                  }}
                  value={projStateData?.DepartmentId}
                  name="DepartmentId"
                >
                  <option>Select Department Name</option>
                  {departmentOptions &&
                    departmentOptions.map((data) => {
                      return (
                        <option value={data.Id}>{data.DepartmentName}</option>
                      );
                    })}
                </select>
                {clientErr.DepartmentIdErr && (
                  <label className="validationTxt">
                    {clientErr.DepartmentIdErr}
                  </label>
                )}
              </div>
              <div className="modalProp  mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Project Owner/Admin
                </label>
                <select
                  className="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setProjStateData({
                      ...projStateData,
                      ProjAdmin: Number(e.target.value),
                    });
                    setClientErr({
                      ...clientErr,
                      ProjAdminErr: "",
                    });
                  }}
                  value={projStateData.ProjAdmin}
                  name="ProjAdmin"
                >
                  <option>Select Owner/Admin Name</option>
                  {ownerList &&
                    ownerList.map((data) => {
                      return (
                        <option value={data.Id}>
                          {data.FirstName + " " + data.LastName}
                        </option>
                      );
                    })}
                </select>
                {clientErr.ProjAdminErr && (
                  <label className="validationTxt">
                    {clientErr.ProjAdminErr}
                  </label>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Project Type
                </label>
                {/* <div className="d-flex justify-content-between"> */}
                <div class="form-check">
                  <input
                    class="form-check-input"
                    onChange={(e) => {
                      setProjStateData({
                        ...projStateData,
                        IsInternal: e.target.checked,
                      });
                    }}
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label
                    class="form-check-label cursor"
                    for="flexRadioDefault1"
                  >
                    Internal
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    onChange={(e) => {
                      setProjStateData({
                        ...projStateData,
                        IsInternal: !e.target.checked,
                      });
                    }}
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                  />
                  <label
                    class="form-check-label cursor"
                    for="flexRadioDefault2"
                  >
                    External
                  </label>
                </div>
                {/* </div> */}
              </div>
              <div className="mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Contract Model
                </label>
                {/* <div className="d-flex justify-content-between"> */}
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault3"
                    id="Time&Material"
                    onChange={(e) => {
                      setProjStateData({
                        ...projStateData,
                        ContractedModel: e.target.checked,
                      });
                    }}
                  />
                  <label class="form-check-label cursor" for="Time&Material">
                    Time & Material
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault3"
                    id="FixedPrice"
                    onChange={(e) => {
                      setProjStateData({
                        ...projStateData,
                        ContractedModel: !e.target.checked,
                      });
                    }}
                  />
                  <label class="form-check-label cursor" for="FixedPrice">
                    Fixed Price
                  </label>
                </div>
                {/* </div> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="modalProp mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Rate
                </label>
                <input
                  type="text"
                  name="Rate"
                  onChange={(e) => {
                    setProjStateData({
                      ...projStateData,
                      Rate: Number(e.target.value),
                    });
                    setClientErr({
                      ...clientErr,
                      RateErr: "",
                    });
                  }}
                  value={projStateData.Rate}
                  className="form-control tasKCategory1"
                />
                {clientErr.RateErr && (
                  <label className="validationTxt">{clientErr.RateErr}</label>
                )}
              </div>
              <div className="modalProp mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Project Awarded Date
                </label>
                <input
                  type="date"
                  name="ProjectAwardedDate"
                  onChange={(e) => {
                    changeData(e);
                    setClientErr({
                      ...clientErr,
                      ProjectAwardedDateErr: "",
                    });
                  }}
                  value={projStateData.ProjectAwardedDate}
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
                {clientErr.ProjectAwardedDateErr && (
                  <label className="validationTxt">
                    {clientErr.ProjectAwardedDateErr}
                  </label>
                )}
              </div>
              <div className="modalProp mb-4">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Project Start Date
                </label>
                <input
                  onChange={(e) => {
                    changeData(e);
                  }}
                  name="StartDate"
                  value={projStateData.StartDate}
                  type="date"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
                {clientErr.StartDateErr && (
                  <label className="validationTxt">
                    {clientErr.StartDateErr}
                  </label>
                )}
              </div>
              <div className="modalProp  mb-4">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Project End Date
                </label>
                <input
                  onChange={(e) => {
                    changeData(e);
                  }}
                  name="EndDate"
                  value={projStateData.EndDate}
                  type="date"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
                {clientErr.EndDateErr && (
                  <label className="validationTxt">
                    {clientErr.EndDateErr}
                  </label>
                )}
              </div>
              <div className="modalProp mb-4">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Estimate Hours
                </label>
                <input
                  onChange={(e) => {
                    changeData(e);
                    setClientErr({
                      ...clientErr,
                      EstimatedTimeErr: "",
                    });
                  }}
                  name="EstimatedTime"
                  value={projStateData.EstimatedTime}
                  type="number"
                  placeholder="0"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
                {clientErr.EstimatedTimeErr && (
                  <label className="validationTxt">
                    {clientErr.EstimatedTimeErr}
                  </label>
                )}
              </div>
              <div className="modalProp mb-4">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Description
                </label>
                <textarea
                  class="form-control tasKCategory1"
                  rows={3}
                  placeholder="Leave a comment here"
                  id="floatingTextarea"
                  onChange={(e) => {
                    changeData(e);
                    setClientErr({
                      ...clientErr,
                      DescriptionErr: "",
                    });
                  }}
                  name="Description"
                  value={projStateData.Description}
                ></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>

        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          {/* <div className='Bottom-btn-arbaz-responsive'> */}
          <button
            type="button"
            className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
            onClick={addProject}
          >
            Add
          </button>
          {/* </div> */}
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={edit}
        onHide={() => {
          setEdit(false);
          setProjStateData({
            ProjectName: "",
            Description: "",
            ProjAdmin: 0,
            StartDate: "",
            EndDate: "",
            IsInternal: false,
            EstimatedTime: "",
            Status: 0,
            DepartmentId: "",
            ProjectAwardedDate: "",
            ContractedModel: false,
            Rate: 0,
            ClientId: 0,
          });
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Edit Project
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setEdit(false)} />
          </div>
          {/* <img src={close} alt="" onClick={handleClose} className="cr-class" /> */}
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="modalProp mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Project Name
                </label>
                <input
                  type="text"
                  value={projStateData?.ProjectName}
                  name="ProjectName"
                  onChange={(e) => {
                    changeData(e);
                    setClientErr({
                      ...clientErr,
                      ProjectNameErr: "",
                    });
                  }}
                  className="form-control tasKCategory1"
                />
                {clientErr.ProjectNameErr && (
                  <label className="validationTxt">
                    {clientErr.ProjectNameErr}
                  </label>
                )}
              </div>
              <div className="modalProp mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Client Name
                </label>
                <select
                  className="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setProjStateData({
                      ...projStateData,
                      ClientId: Number(e.target.value),
                    });
                    setClientErr({
                      ...clientErr,
                      CustomerName: "",
                    });
                  }}
                  value={projStateData?.ClientId}
                >
                  <option>Select Client Name</option>
                  {clientOptions.map((name, index) => {
                    return <option value={name.Id}>{name.CustomerName}</option>;
                  })}
                </select>
                {clientErr.CustomerName && (
                  <label className="validationTxt">
                    {clientErr.CustomerName}
                  </label>
                )}
              </div>
              <div className="modalProp mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Department
                </label>
                <select
                  className="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setProjStateData({
                      ...projStateData,
                      DepartmentId: e.target.value,
                    });
                    setClientErr({
                      ...clientErr,
                      DepartmentName: "",
                    });
                  }}
                  value={projStateData?.DepartmentId}
                >
                  <option>Select Department Name</option>
                  {departmentOptions &&
                    departmentOptions.map((data) => {
                      return (
                        <option value={data.Id}>{data.DepartmentName}</option>
                      );
                    })}
                </select>
                {clientErr.DepartmentName && (
                  <label className="validationTxt">
                    {clientErr.DepartmentName}
                  </label>
                )}
              </div>
              <div className="modalProp  mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Project Owner/Admin
                </label>
                <select
                  className="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setProjStateData({
                      ...projStateData,
                      ProjAdmin: Number(e.target.value),
                    });
                    setClientErr({
                      ...clientErr,
                      ProjAdminErr: "",
                    });
                  }}
                  value={projStateData.ProjAdmin}
                >
                  <option>Select Owner/Admin Name</option>
                  {ownerList &&
                    ownerList.map((data) => {
                      return (
                        <option value={data.Id}>
                          {data.FirstName + " " + data.LastName}
                        </option>
                      );
                    })}
                </select>
                {clientErr.ProjAdminErr && (
                  <label className="validationTxt">
                    {clientErr.ProjAdminErr}
                  </label>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Project Type
                </label>
                {/* <div className="d-flex justify-content-between"> */}
                {String(projBool) == "true" ? (
                  <>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        onChange={(e) => {
                          // e.target.checked
                          setProjBool(!projStateData.IsInternal);
                          setProjStateData({
                            ...projStateData,
                            IsInternal: e.target.checked,
                          });
                        }}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={projStateData.IsInternal}
                      />
                      <label
                        class="form-check-label cursor"
                        for="flexRadioDefault1"
                      >
                        Internal
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        onChange={(e) => {
                          setProjBool(!projStateData.IsInternal);
                          setProjStateData({
                            ...projStateData,
                            IsInternal: !e.target.checked,
                          });
                        }}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={!projStateData.IsInternal}
                      />
                      <label
                        class="form-check-label cursor"
                        for="flexRadioDefault2"
                      >
                        External
                      </label>
                    </div>
                  </>
                ) : (
                  <>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        onChange={(e) => {
                          setProjBool(!projStateData.IsInternal);
                          // e.target.checked
                          setProjStateData({
                            ...projStateData,
                            IsInternal: e.target.checked,
                          });
                        }}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={projStateData.IsInternal}
                      />
                      <label
                        class="form-check-label cursor"
                        for="flexRadioDefault1"
                      >
                        Internal
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        onChange={(e) => {
                          setProjBool(!projStateData.IsInternal);
                          setProjStateData({
                            ...projStateData,
                            IsInternal: !e.target.checked,
                          });
                        }}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={!projStateData.IsInternal}
                      />
                      <label
                        class="form-check-label cursor"
                        for="flexRadioDefault2"
                      >
                        External
                      </label>
                    </div>
                  </>
                )}
                {/* </div> */}
              </div>
              <div className="mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Contract Model
                </label>
                {/* <div className="d-flex justify-content-between"> */}
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault3"
                    id="Time&Material"
                  />
                  <label
                    class="form-check-label cursor"
                    for="Time&Material"
                    onChange={(e) => {
                      setProjStateData({
                        ...projStateData,
                        ContractedModel: e.target.checked,
                      });
                    }}
                    checked={projStateData.ContractedModel}
                  >
                    Time & Material
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault3"
                    id="FixedPrice"
                    onChange={(e) => {
                      setProjStateData({
                        ...projStateData,
                        ContractedModel: !e.target.checked,
                      });
                    }}
                    checked={projStateData?.ContractedModel}
                  />
                  <label class="form-check-label cursor" for="FixedPrice">
                    Fixed Price
                  </label>
                </div>
                {/* </div> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="modalProp mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Rate
                </label>
                <input
                  type="text"
                  name="Rate"
                  onChange={(e) => {
                    setProjStateData({
                      ...projStateData,
                      Rate: Number(e.target.value),
                    });
                    setClientErr({
                      ...clientErr,
                      RateErr: "",
                    });
                  }}
                  value={projStateData?.Rate}
                  className="form-control tasKCategory1"
                />
                {clientErr.RateErr && (
                  <label className="validationTxt">{clientErr.RateErr}</label>
                )}
              </div>
              <div className="modalProp mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Project Awarded Date
                </label>
                <input
                  type="date"
                  name="ProjectAwardedDate"
                  onChange={(e) => {
                    changeData(e);
                    setClientErr({
                      ...clientErr,
                      ProjectAwardedDateErr: "",
                    });
                  }}
                  value={projectAwardedDate}
                  className="form-control tasKCategory1"
                />
                {clientErr.ProjectAwardedDateErr && (
                  <label className="validationTxt">
                    {clientErr.ProjectAwardedDateErr}
                  </label>
                )}
              </div>
              <div className="modalProp mb-4">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Project Start Date
                </label>
                <input
                  onChange={(e) => {
                    changeData(e);
                  }}
                  name="StartDate"
                  value={StartDate}
                  type="date"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp  mb-4">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Project End Date
                </label>
                <input
                  onChange={(e) => {
                    changeData(e);
                  }}
                  name="EndDate"
                  value={EndDate}
                  type="date"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-4">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Estimate Hours
                </label>
                <input
                  onChange={(e) => {
                    changeData(e);
                  }}
                  name="EstimatedTime"
                  value={projStateData?.EstimatedTime}
                  type="number"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                />
              </div>
              <div className="modalProp mb-3">
                <label htmlFor="" className="enter-role-text my-2">
                  Status
                </label>
                <select
                  className="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  value={projStateData?.Status}
                  name="Status"
                  onChange={(e) => {
                    setProjStateData({
                      ...projStateData,
                      Status: parseInt(e.target.value),
                    });
                    // setClientErr({
                    //   ...clientErr,
                    //   DepartmentName: ''
                    // })
                  }}
                >
                  <option value="">Select Status</option>
                  <option value="1">New</option>
                  <option value="2">Active</option>
                  <option value="3">Closed</option>
                  <option value="4">Holded</option>
                  {/* {departmentOptions &&
                    departmentOptions.map(data => {
                      return (
                        <option value={data.Id}>{data.DepartmentName}</option>
                      )
                    })} */}
                </select>
                {/* {clientErr.DepartmentName && (
                  <label className='validationTxt'>
                    {clientErr.DepartmentName}
                  </label>
                )} */}
              </div>
              <div className="modalProp mb-4">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "14px" }}
                >
                  Descriptions
                </label>
                <textarea
                  class="form-control tasKCategory1"
                  rows={3}
                  placeholder="Leave a comment here"
                  id="floatingTextarea"
                  onChange={(e) => {
                    changeData(e);
                  }}
                  name="Description"
                  value={projStateData?.Description}
                ></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>

        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <button
            className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
            onClick={() => setEdit(false)}
          >
            Cancel
          </button>
          <button
            className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
            onClick={updateProject}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
      {/* Delete News and Blogs Modal Start */}
      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>
            Are you sure you want to delete this{" "}
            {"( " + deleteProjectName + " )"} ?
          </p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              deleteProject(useDeleteTaskList);
              handleClose3();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
      {/* Delete News and Blogs Modal End */}
    </>
  );
};

export default Projects;
