import BookIcon from "../../assets/images/ic_excel.svg";
import Modal from "react-bootstrap/Modal";
import Pagination from "../../sharedComponent/Pagination";
import React, { useContext, useEffect, useState } from "react";
import deleteicon from "../../assets/images/delete_icon_gray.svg";
import edit from "../../assets/images/edit_officeCaller.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const WhatsappSetting = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };

  const dataArrayLocation = [
    {
      id: 1,
      WHATSAPP: "273903359143669",
      Token:
        "EAA0LrQxvdQABOyzCyT4MhZAFcnrdZBZCOJiIbo1HuzwOIRWS1o8dSWeO9cb1hK092o5vTAzzhoGTo2z6NpdvQBjZBsknbbna3pCI0oEkaUtTu3f8yr6NdExzyI6IxFbFZA1MnlYxHLomv9VzutcdQdNRkjnJhwrzhoQtxehK9TiZBJOy0dzxu4xC6CCytMs855",
      Sources: "Joint Views",
      Action: "Delete",
    },

    {
      id: 2,
      WHATSAPP: "273903359143669",
      Token:
        "EAA0LrQxvdQABOyzCyT4MhZAFcnrdZBZCOJiIbo1HuzwOIRWS1o8dSWeO9cb1hK092o5vTAzzhoGTo2z6NpdvQBjZBsknbbna3pCI0oEkaUtTu3f8yr6NdExzyI6IxFbFZA1MnlYxHLomv9VzutcdQdNRkjnJhwrzhoQtxehK9TiZBJOy0dzxu4xC6CCytMs855",
      Sources: "Joint Views",
      Action: "Delete",
    },
    {
      id: 3,
      WHATSAPP: "273903359143669",
      Token:
        "EAA0LrQxvdQABOyzCyT4MhZAFcnrdZBZCOJiIbo1HuzwOIRWS1o8dSWeO9cb1hK092o5vTAzzhoGTo2z6NpdvQBjZBsknbbna3pCI0oEkaUtTu3f8yr6NdExzyI6IxFbFZA1MnlYxHLomv9VzutcdQdNRkjnJhwrzhoQtxehK9TiZBJOy0dzxu4xC6CCytMs855",
      Sources: "Joint Views",
      Action: "Delete",
    },
    {
      id: 4,
      WHATSAPP: "273903359143669",
      Token:
        "EAA0LrQxvdQABOyzCyT4MhZAFcnrdZBZCOJiIbo1HuzwOIRWS1o8dSWeO9cb1hK092o5vTAzzhoGTo2z6NpdvQBjZBsknbbna3pCI0oEkaUtTu3f8yr6NdExzyI6IxFbFZA1MnlYxHLomv9VzutcdQdNRkjnJhwrzhoQtxehK9TiZBJOy0dzxu4xC6CCytMs855",
      Sources: "Joint Views",
      Action: "Delete",
    },
    {
      id: 5,
      WHATSAPP: "273903359143669",
      Token:
        "EAA0LrQxvdQABOyzCyT4MhZAFcnrdZBZCOJiIbo1HuzwOIRWS1o8dSWeO9cb1hK092o5vTAzzhoGTo2z6NpdvQBjZBsknbbna3pCI0oEkaUtTu3f8yr6NdExzyI6IxFbFZA1MnlYxHLomv9VzutcdQdNRkjnJhwrzhoQtxehK9TiZBJOy0dzxu4xC6CCytMs855",
      Sources: "Joint Views",
      Action: "Delete",
    },

    {
      id: 6,
      WHATSAPP: "273903359143669",
      Token:
        "EAA0LrQxvdQABOyzCyT4MhZAFcnrdZBZCOJiIbo1HuzwOIRWS1o8dSWeO9cb1hK092o5vTAzzhoGTo2z6NpdvQBjZBsknbbna3pCI0oEkaUtTu3f8yr6NdExzyI6IxFbFZA1MnlYxHLomv9VzutcdQdNRkjnJhwrzhoQtxehK9TiZBJOy0dzxu4xC6CCytMs855",
      Sources: "Joint Views",
      Action: "Delete",
    },
  ];

  const splitToken = (token, chunkSize) => {
    const chunks = [];
    for (let i = 0, len = token.length; i < len; i += chunkSize) {
      chunks.push(token.substring(i, i + chunkSize));
    }
    return chunks.join("<br/>");
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">WhatsApp Settings</h6>

            <button className="custoBtnForCrmCall">
              <a className=" textcolor" href="CreateToken">
                Add WhatsApp Source
              </a>
            </button>
          </div>
          <div className="d-flex justify-content-between mainHeader custom-mb mt-4">
            <div className="role-table-header-main">
              <div className="search-input-wrapper mt-0 cus-mb">
                <input type="text" placeholder="Search here" />

                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper mt-0 cus-mb">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  value={selectedRows}
                  onChange={handleSelectChange}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
            <div className="d-flex justify-content-between mainIcons">
              <div className="booksIcon" style={{ textAlign: "center" }}>
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
            </div>
          </div>

          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            <table className="">
              <thead className="firstTabelRow">
                <tr classname="row-hover modal-footer-table-body-tr">
                  <th>WHATSAPP PHONE NUMBER ID</th>
                  <th>TOKEN</th>
                  <th>SOURCE</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {dataArrayLocation.map((item) => (
                  <tr key={item.id} className="table-row-custom">
                    <td>{item.WHATSAPP}</td>
                    <td
                      className="token-column"
                      dangerouslySetInnerHTML={{
                        __html: splitToken(item.Token, 40),
                      }}
                    ></td>
                    <td className="Source">{item.Sources}</td>
                    <td>
                      <div className="d-flex">
                        <div onClick={handleShow3}>
                          <img
                            src={deleteicon}
                            alt="Image 2"
                            className="cell-image "
                          />
                        </div>

                        <Link to="/Updatewhatsapp">
                          <img
                            src={edit}
                            alt="Image 1"
                            className="cell-image ml-2"
                          />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination />
          </div>
        </div>
      </div>

      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to delete this WhatsApp ?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              handleClose3();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default WhatsappSetting;
