import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import { PostCallWithErrorResponse, simpleGetCallWithToken ,simpleGetCallWithErrorResponse} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";
const TaskNotAddedAgainstProject = () => {
    const { sidebar, setSidebar, Dark, setDark, userData,permissions } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [taskListNotAdded, setTaskListNotAdded] = useState([]);
    const [projectId, setProjectId] = useState(0)
    const [empName, setEmpName] = useState([]);

    const [searchValue, setSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [usersPerPage, setUsersPerPage] = useState(5);

    const startIndex = currentPage * usersPerPage;
    const endIndex = startIndex + parseInt(usersPerPage);

    const pageChangeHandler = ({ selected }) => {
        setCurrentPage(selected);
    };

    const searchedDataPageCounts =
        taskListNotAdded &&
        taskListNotAdded.length &&
        taskListNotAdded.filter((item) =>
            item.ProjectName?.toLowerCase().includes(searchValue)
        );

    const fetchtaskNotAdded = () => {
        setLoading(true);
        simpleGetCallWithToken(ApiConfig.GET_PROJECTNOTADDED_LIST)
            .then((res) => {
                if (res.Success === true) {
                    setLoading(false);
                    setTaskListNotAdded(res.Data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const fetchEmpName = () => {
        PostCallWithErrorResponse(ApiConfig.EMPLOEE_NAME_LIST)
            .then((res) => {
                console.log('ATTENDANCE_PATH==>', res)
                if (res.json.Success === true) {
                    setEmpName(res.json.Data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    };
    const fetchQueryData = () => {
        const projId = projectId ? projectId : 0;
        simpleGetCallWithToken(ApiConfig.GET_PROJECTNOTADDED_LIST + `?employeeid=${projId}`)
            .then((res) => {
                if (res.Success === true) {
                    setTaskListNotAdded(res.Data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const handleChange = () => {
        if (projectId !== 0) {
            fetchQueryData();
        }
        else {
            fetchtaskNotAdded();
        }
    }
 const [excelLoading, setExcelLoading] = useState(false);

    const downloadExcelFile = (e) => {
      setExcelLoading(true);
      e.preventDefault();
      simpleGetCallWithErrorResponse(ApiConfig.GET_PROJECTNOTADDED_EXPORT)
        .then((res) => {
          console.log("Download File Response", res);
          if (res.error) {
            console.log("Error response", res);
          } else {
            if (res.json.Success === true) {
              setExcelLoading(false);
              let data = res.json.Data;
              FileSaver.saveAs(
                "data:" + data.ContentType + ";base64," + data.FileContents,
                data.FileDownloadName
              );
            } else {
              console.log("res", res);
              setExcelLoading(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    useEffect(() => {
        fetchEmpName();
        fetchtaskNotAdded();
    }, [])
    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="role-content">
                    <div className="sub-header-role ">
                        <h6 className="mt-2">List of Employee - Task Not Added Against Project</h6>
                    </div>
                    <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">

                    <div className="role-table-header">
                        <pre>{JSON.stringify(projectId)}</pre>
                        <div className="w-25 my-3">
                            <label htmlFor="" className="enter-role-text mt-2">
                                Employee Name
                            </label>
                            <select
                                class="form-select tasKCategory1 select-drop-icon"
                                aria-label="Default select example"
                                onChange={(e) => {
                                    setProjectId(e.target.value);
                                }}
                                onClick={handleChange}
                                name="projectId"
                                value={projectId}
                            >
                                <option selected value="0">Select Employee Name</option>
                                {
                                    empName.map((name, index) => {
                                        return (
                                            <option value={name.Id}>{name.FullName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="entries-wrapper">
                            <select
                                className="form-select select-drop-icon"
                                aria-label="Default select example"
                                onChange={(e) => {
                                    setUsersPerPage(e.target.value);
                                    setCurrentPage(0);
                                }}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="import-export-btn-wrapper mt-4">
                            {
                                permissions&&permissions['Task Not Added Export']&&
                            <button style={{marginLeft:"600px"}}> 
                            
                                {excelLoading ? (
                                    <ExcelLoader />
                                ) : (
                                    <img
                                        src={ic_excel}
                                        onClick={(e) => downloadExcelFile(e)}
                                        alt=""
                                    />
                                )}
                            </button>
                            }
                       
                    </div>
                    </div>
                    </div>


                    <div className="table-wrapper">
                        {loading ? (
                            <Loader />
                        ) : (
                            taskListNotAdded && taskListNotAdded.length > 0 ?
                                <table>
                                    <thead>
                                        <tr className="firstTabelRow">
                                            <th>Employee Name</th>
                                            <th>Project Name</th>
                                            <th>Project Lead</th>
                                            <th>Location</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            taskListNotAdded.slice(startIndex, endIndex).map((data) => {
                                                return (
                                                    <tr className="table-row-custom">
                                                        <td>{data.EmployeeName}</td>
                                                        <td>{data.ProjectName}</td>
                                                        <td>{data.ProjectLead}</td>
                                                        <td>{data.Location}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table> :
                                <NoData />
                        )
                        }
                    </div>
                </div>
                <Pagination
                    data={taskListNotAdded}
                    pageChangeHandler={pageChangeHandler}
                    usersPerPage={usersPerPage}
                    currentPage={currentPage}
                    searchValue={searchValue}
                    searchedDataPageCounts={searchedDataPageCounts}
                />
            </div>
        </>
    )
}

export default TaskNotAddedAgainstProject;
