import React, { useContext } from 'react'
import { AppContext } from '../context/AppContext';
import MensionUsers from './MensionUsers';
export default function RepliesTo({sernderName, msg, repliedTo, msgRef, mensioned, index }) {
    const { conversations, newMessages } = useContext(AppContext)
    let msgIndex = conversations.indexOf(repliedTo)
    let reference = msgIndex !== -1 ? msgRef.current[msgIndex] : msgRef[conversations.length + newMessages.indexOf(repliedTo)]
    return (
        <div className={"repliesTo "}>
            <div className='receive-msg'>
                <div className="right-receive-msg" onClick={() => reference.scrollIntoView()} >
                <span className="senderName">{sernderName}</span>
                    {repliedTo && (repliedTo.Team_Message_Text || repliedTo.message) ?
                        <div className="receive-msg-main">
                            <p className="msg-text">{
                                (repliedTo && repliedTo.message && repliedTo.message.includes("@")) || (repliedTo && repliedTo.Team_Message_Text && repliedTo.Team_Message_Text.includes("@")) ?
                                    <MensionUsers text={repliedTo.Team_Message_Text || repliedTo.message} users={mensioned} index={index} />
                                    :
                                    repliedTo.Team_Message_Text || repliedTo.message}</p>
                        </div>
                        : null
                    }
                </div>
            </div>
            <div className="send-msg-main" >
                <p className="msg-text ">{msg.Team_Message_Text || msg.message}</p>
            </div>
        </div>
    )
}
