import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import Logo from "../../assets/images/wifi-attendance.svg";
import { ToastMsg } from "../TostMsg";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const forgetApi = () => {
    const regex = /^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    if (email.length > 0 === "") {
      setErrMsg("Enter your  Email");
      return;
    }
    if (!regex.test(email)) {
      setErrMsg("Enter valid email id");
      return;
    } else {
      setIsLoading(true);
      PostCallWithErrorResponse(
        ApiConfig.FORGET_PASSWORD,
        JSON.stringify({ email: email })
      )
        .then((res) => {
          setIsLoading(false);
          if (res.json && res.json?.Success === true) {
            ToastMsg("success", res.json.Message);
            navigate("/ChangePassword",{state:{email:email, }});
          } else {
            ToastMsg("error", res.json.Message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <main>
      <div className="main-auth createAccount" id="forget-main">
        <div className="top-logo ms-5">
          <img src={Logo} alt="Logo" height={40} width={240} />
        </div>
        <div className="create-ac-form mt-3">
          <form action="">
            <div className="top-heading">
              <div>
                <label className="heading">Find Password ?</label>
              </div>
            </div>
            <div className="form-control-div">
              <label for="exampleFormControlInput1" className="form-label">
                Enter your registered email address or contact no. for code
                verification.
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter your Email ID or Contact no."
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrMsg("");
                }}
              />
              {errMsg && (
                <span className="text-center text-danger">{errMsg}</span>
              )}
            </div>

            <div className="ac-button">
              <Link to="">
                {" "}
                <button type="submit" onClick={forgetApi}>
                  {isLoading ? (
                    <div
                      className="spinner-border mx-auto mt-2"
                      style={{
                        color: "#fff",
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  ) : (
                    <span>Send Verification Code</span>
                  )}
                </button>
              </Link>
            </div>
            <div className="need">
              <p>
                <label htmlFor="">Do you need help?</label>
              </p>
              <p>
                <Link to="#">Customer support</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default ForgotPassword;
