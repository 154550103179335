import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Warning from "../../assets/images/warningalert.svg";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../toaster/notify";
import { json } from "react-router-dom";
import { useToast } from "react-toastify";

function CalendarSettings() {
  const OffDays = [
    {
      OffDay: "Monday",
      WorkDayType: 0,
      Week1: false,
      Week2: false,
      Week3: false,
      Week4: false,
      Week5: false,
    },
    {
      OffDay: "Tuesday",
      WorkDayType: 0,
      Week1: false,
      Week2: false,
      Week3: false,
      Week4: false,
      Week5: false,
    },
    {
      OffDay: "Wednesday",
      WorkDayType: 0,
      Week1: false,
      Week2: false,
      Week3: false,
      Week4: false,
      Week5: false,
    },
    {
      OffDay: "Thursday",
      WorkDayType: 0,
      Week1: false,
      Week2: false,
      Week3: false,
      Week4: false,
      Week5: false,
    },
    {
      OffDay: "Friday",
      WorkDayType: 0,
      Week1: false,
      Week2: false,
      Week3: false,
      Week4: false,
      Week5: false,
    },
    {
      OffDay: "Saturday",
      WorkDayType: 0,
      Week1: false,
      Week2: false,
      Week3: false,
      Week4: false,
      Week5: false,
    },
    {
      OffDay: "Sunday",
      WorkDayType: 0,
      Week1: false,
      Week2: false,
      Week3: false,
      Week4: false,
      Week5: false,
    },
  ];

  const [roles, setRoles] = useState([]);

  const [update, setUpdate] = useState(true);

  const [days, setDays] = useState({
    RoleId: 0,
    OffDays: OffDays,
  });

  const [clearData, setClearData] = useState(false);

  const getRoles = () => {
    simpleGetCallWithErrorResponse(ApiConfig.ROLES_LIST)
      .then((res) => {
        if (res?.json?.Success) {
          setRoles(res?.json?.Data);
        } else {
          notifyError(res?.json.Message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRoles();
  }, []);

  const getSettingById = (roleId) => {
    simpleGetCallWithErrorResponse(
      ApiConfig.GET_CALENDER_SETTING_BY_ROLE + roleId
    )
      .then((res) => {
        if (res?.json?.Success) {
          if (res?.json?.Data?.length > 0) {
            setDays({
              RoleId: Number(roleId),
              OffDays: res?.json?.Data,
            });
          } else {
            setDays({
              RoleId: roleId,
              OffDays: OffDays,
            });
          }
        } else {
          notifyError(res?.json?.Message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeWeekStatus = (index, week, checked) => {
    days.OffDays[index][week] = checked;

    setDays((prev) => ({
      ...prev,
      OffDays: days.OffDays,
    }));
  };

  const handleWorkingTime = (index, shift) => {
    days.OffDays[index].WorkDayType = shift;

    setDays((prev) => ({
      ...prev,
      OffDays: days.OffDays,
    }));
  };

  const submitSetting = () => {
    if (days.RoleId === 0) {
      notifyError("Please Elect Role");
      return;
    }

    PostCallWithErrorResponse(
      ApiConfig?.CALENDAR_SETTING_ADD,
      JSON.stringify(days)
    ).then((res) => {
      if (res?.json?.Success) {
        notifySuccess(res?.json?.Message);
      } else {
        notifyError(res?.json?.Message);
      }
    });
  };

  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Calendar Settings</h6>
          </div>
          <div className="innerRoleSec">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Application For
                  </label>
                  <select
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    value={days?.RoleId}
                    onChange={(e) => {
                      days.RoleId = Number(e.target.value);
                      setDays(days);
                      setUpdate(!update);
                      getSettingById(e.target.value);
                    }}
                  >
                    <option disabled value="0">
                      Select Role
                    </option>
                    {roles.map((role, index) => {
                      return <option value={role?.RoleId}>{role?.Name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div>
                <label
                  htmlFor=""
                  className="enter-role-text"
                  style={{ fontSize: "18px" }}
                >
                  Work Week
                </label>
              </div>
              <br />
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Monday
                  </label>
                  <select
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    // value={days?.OffDays[0].WorkDayType}
                    value={days?.OffDays[0]?.WorkDayType}
                    onChange={(e) => {
                      handleWorkingTime(0, Number(e.target.value));
                      setUpdate(!update);
                    }}
                  >
                    <option disabled value={"0"}>
                      Select
                    </option>
                    <option value="1">Full Day</option>

                    <option value="2">Half Day</option>
                    <option value="3">Non-working Day</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Tuesday
                  </label>
                  <select
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    value={days?.OffDays[1]?.WorkDayType}
                    onChange={(e) => {
                      handleWorkingTime(1, Number(e.target.value));
                      setUpdate(!update);
                    }}
                  >
                    <option disabled value={"0"}>
                      Select
                    </option>
                    <option value="1">Full Day</option>

                    <option value="2">Half Day</option>
                    <option value="3">Non-working Day</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Wednesday
                  </label>
                  <select
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    value={days?.OffDays[2]?.WorkDayType}
                    onChange={(e) => {
                      handleWorkingTime(2, Number(e.target.value));
                      setUpdate(!update);
                    }}
                  >
                    <option disabled value={"0"}>
                      Select
                    </option>
                    <option value="1">Full Day</option>

                    <option value="2">Half Day</option>
                    <option value="3">Non-working Day</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Thursday
                  </label>
                  <select
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    value={days?.OffDays[3]?.WorkDayType}
                    onChange={(e) => {
                      handleWorkingTime(3, Number(e.target.value));
                      setUpdate(!update);
                    }}
                  >
                    <option disabled value={"0"}>
                      Select
                    </option>
                    <option value="1">Full Day</option>

                    <option value="2">Half Day</option>
                    <option value="3">Non-working Day</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Friday
                  </label>
                  <select
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    value={days?.OffDays[4]?.WorkDayType}
                    onChange={(e) => {
                      handleWorkingTime(4, Number(e.target.value));
                      setUpdate(!update);
                    }}
                  >
                    <option disabled value={"0"}>
                      Select
                    </option>
                    <option value="1">Full Day</option>

                    <option value="2">Half Day</option>
                    <option value="3">Non-working Day</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Saturday
                  </label>
                  <select
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    value={days?.OffDays[5]?.WorkDayType}
                    onChange={(e) => {
                      handleWorkingTime(5, Number(e.target.value));
                      setUpdate(!update);
                    }}
                  >
                    <option disabled value={"0"}>
                      Select
                    </option>
                    <option value="1">Full Day</option>

                    <option value="2">Half Day</option>
                    <option value="3">Non-working Day</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="mb-4">
                  <label htmlFor="" className="enter-role-text">
                    Sunday
                  </label>
                  <select
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    value={days?.OffDays[6]?.WorkDayType}
                    onChange={(e) => {
                      handleWorkingTime(6, Number(e.target.value));
                      setUpdate(!update);
                    }}
                  >
                    <option disabled value={"0"}>
                      Select
                    </option>
                    <option value="1">Full Day</option>

                    <option value="2">Half Day</option>
                    <option value="3">Non-working Day</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor=""
                className="enter-role-text"
                style={{ fontSize: "18px" }}
              >
                Week Definition
              </label>
            </div>
            <br />
            <div className=" d-flex justify-content-center">
              <div className="container">
                {days.OffDays.map((day, index) => (
                  <div className="form-group d-flex row" key={index}>
                    <div className="col-sm-2 control-label align-items-center">
                      <label htmlFor="" className="enter-role-text">
                        {day?.OffDay}:
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <p id={`wd${day.OffDay.toLowerCase()}`}>
                        {[1, 2, 3, 4, 5].map((week) => (
                          <label
                            htmlFor=""
                            className="enter-role-text"
                            key={week}
                          >
                            &nbsp;
                            <input
                              className={`chk${day.OffDay.toLowerCase()}`}
                              type="checkbox"
                              name={`${day}Week${week}`}
                              checked={day["Week" + week]}
                              //defaultChecked={day["Week" + week]}
                              onChange={(e) =>
                                changeWeekStatus(
                                  index,
                                  "Week" + week,
                                  e.target.checked
                                )
                              }
                            />
                            &nbsp;
                            {`${week}st Week`}
                          </label>
                        ))}
                      </p>
                      &nbsp;
                    </div>
                  </div>
                ))}
                {/* <pre>{JSON.stringify(checkedItems, null, 2)}</pre> */}
              </div>
            </div>
            <div>
              <img src={Warning} className="mx-2 " alt="" />
              <label
                htmlFor=""
                className="enter-role-text"
                style={{
                  fontSize: "14px",
                  color: "var(--blue_53B7E8_LightMode)",
                }}
              >
                Selected weeks will be considered off days/non-working days.
              </label>
            </div>
            <div className="col-md-12 text-end mt-2">
              <button className="impButton" onClick={submitSetting}>
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CalendarSettings;
