import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import { Button, Modal } from "react-bootstrap";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";

const ServiceRequest = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const [modal, setModal] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});

  const handleClose = () => setModal(false);
  const handleShow = (details) => {
    setSelectedDetails(details);
    setModal(true);
  };

  const crmServiceRequestData = [
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "10-Jun-2024",
      message: "Do you have any openings sir",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "9-Jun-2024",
      message: "Hi, Redbytes UK Team",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "8-Jun-2024",
      message: "Hi",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "7-Jun-2024",
      message: "Hi, Edsys Team",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "6-Jun-2024",
      message: "Hello",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "5-Jun-2024",
      message: "Hi, Probytes Team",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "4-Jun-2024",
      message: "Hi, Probytes Team",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "3-Jun-2024",
      message: "Hi",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "2-Jun-2024",
      message: "Hi, Redbytes UK Team",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "1-Jun-2024",
      message: "Hello",
    },
    {
      mobileNumber: "whatsapp:+919545064830",
      date: "31-May-2024",
      message: "Hello",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Service Request</h6>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text" placeholder="Search here" />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                value={selectedRows}
                onChange={handleSelectChange}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            <div className="booksIcon" style={{ textAlign: "center" }}>
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">MOBILE NUMBER</th>
                <th scope="col">DATE</th>
                <th scope="col">MESSAGE</th>
                <th scope="col">VIEW DETAILS</th>
              </tr>
            </thead>
            <tbody>
              {crmServiceRequestData.map((data, index) => (
                <tr key={index} className="table-row-custom">
                  <td>{data.mobileNumber}</td>
                  <td>{data.date}</td>
                  <td>{data.message}</td>
                  <td>
                    <button
                      className="custoBtnForCrmCall"
                      onClick={() => handleShow(data)}
                    >
                      Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination />

          <Modal show={modal} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title className="SerReqModalTitle">
                Service Request Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="SerReqModalBody">
              <p className="mb-2">
                Phone Number: {selectedDetails.mobileNumber}
              </p>
              <p className="mb-2">Contacted on: {selectedDetails.date}</p>
              <p className="mb-2">Message: {selectedDetails.message}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ServiceRequest;
