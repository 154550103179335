import React, { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";

const OrgDetails = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">Organization Info</h6>
        </div>
      </div>
      <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
        <div className="role-table-header accoridance">
          <div className="search-input-wrapper allAcordiance">
            {permissions && permissions["Create Announcement"] && (
              <Link to="/OrgDetails">
                <button className="all-anc-btn-sub-header active ">
                  Organization Details
                </button>
              </Link>
            )}
          </div>
          <div className="entries-wrapper myAccordiance">
            {permissions && permissions["Create Announcement"] && (
              <Link to="/ManageDept">
                <button className="my-anc-btn-sub-header">
                  Manage Department
                </button>
              </Link>
            )}
          </div>
          <div className="entries-wrapper myAccordiance">
            {permissions && permissions["Create Announcement"] && (
              <Link to="/ManageDesig">
                <button className="my-anc-btn-sub-header">
                  Manage Designation
                </button>
              </Link>
            )}
          </div>
          <div className="entries-wrapper myAccordiance">
            {permissions && permissions["Create Announcement"] && (
              <Link to="/ManageLoc">
                <button className="my-anc-btn-sub-header">
                  Manage Locations
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="bg-white rounded-3 p-2">
        <h4 className="text-start">Organizatin Profile</h4>
        <hr />
        <form>
          <div className="row justify-content-center ">
            <div className="col-7 ">
              <p>File Size Limit 4MB</p>
              <input type="file" />
            </div>
            <div className="col-7 d-flex">
              <div className="col">
                <label className="form-label text-nowrap me-3">
                  Organization Name :
                </label>
              </div>
              <input
                type="text"
                className="form-control bg-white  css-13cymwt-control"
                value='"Redbytes Softwares Company"'
              ></input>
            </div>

            <div className="col-7 d-flex">
              <div className="col-3">
                <label className="form-label text-nowrap me-3">Website :</label>
              </div>
              <input type="text" className="form-control bg-white css-13cymwt-control"></input>
            </div>

            <div className="col-7 d-flex">
              <div className="col-3">
                <label className="form-label text-nowrap me-3 ">
                  Contact No :
                </label>
              </div>

              <input type="number" className="form-control bg-white css-13cymwt-control"></input>
            </div>
            <div className="col-7 d-flex">
              <div className="col-3">
                <label className="form-label text-nowrap me-3">
                  Contact Person :
                </label>
              </div>

              <input type="number" className="form-control bg-white css-13cymwt-control"></input>
            </div>
            <div className="col-7 d-flex">
              <div className="col-3">
                <label className="form-label text-nowrap me-3">Email :</label>
              </div>

              <input type="email" className="form-control bg-white css-13cymwt-control"></input>
            </div>
            <div className="col-7 d-flex">
              <div className="col-3">
                <label className="form-label text-nowrap me-3">Fax :</label>
              </div>

              <input type="number" className="form-control bg-white css-13cymwt-control"></input>
            </div>
          </div>

          <h4 className="text-start">Address Details</h4>
          <hr />

          <div className="row justify-content-center ">
            <div className="col-7 d-flex">
              <div className="col">
                <label className="form-label text-nowrap me-3">
                  Organization Name :
                </label>
              </div>

              <input
                type="text"
                className="form-control bg-white css-13cymwt-control"
                value='"Redbytes Softwares Company"'
              ></input>
            </div>

            <div className="col-7 d-flex">
              <div className="col-3">
                <label className="form-label text-nowrap me-3">
                  Address line1 :
                </label>
              </div>

              <input type="text" className="form-control bg-white css-13cymwt-control"></input>
            </div>

            <div className="col-7 d-flex">
              <div className="col-3">
                <label className="form-label text-nowrap me-3">
                  Address line2 :
                </label>
              </div>

              <input type="text" className="form-control bg-white css-13cymwt-control"></input>
            </div>
            <div className="col-7 d-flex">
              <div className="col-3">
                <label className="form-label text-nowrap me-3">City :</label>
              </div>

              <input type="text" className="form-control bg-white css-13cymwt-control"></input>
            </div>
            <div className="col-7 d-flex">
              <div className="col-3">
                <label className="form-label text-nowrap me-3">State :</label>
              </div>

              <input type="text" className="form-control bg-white css-13cymwt-control"></input>
            </div>
            <div className="col-7 d-flex">
              <div className="col-3">
                <label className="form-label text-nowrap me-3">Country :</label>
              </div>

              <input type="text" className="form-control bg-white css-13cymwt-control"></input>
            </div>
            <div className="col-7 d-flex">
              <div className="col-3">
                <label className="form-label text-nowrap me-3">Pincode :</label>
              </div>

              <input type="number" className="form-control bg-white css-13cymwt-control"></input>
            </div>
            <div className="col-7 d-flex">
              <div className="col-3">
                <label className="form-label text-nowrap me-3">
                  Po Box No :
                </label>
              </div>

              <input type="number" className="form-control bg-white css-13cymwt-control"></input>
            </div>
            <div className="col-2 w-100 text-center">
              <button className="allocateionBtn">Update</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default OrgDetails;
