import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Editac from "../../assets/images/Editac.svg";
import DelectAc from "../../assets/images/DelectAc.svg";
import Modal from "react-bootstrap/Modal";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import {
    DeleteCallWithErrorResponseWithTokenQuery,
    PostCallWithErrorResponse,
    putMultipartWithAuthCall,
    simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import moment from "moment";
import { notifySuccess } from "../../toaster/notify";
import { Form } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import { sendAsFormData } from "../../common";

const OrganizationDetails = () => {
    const { sidebar } = useContext(AppContext);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [location, setLocation] = useState([]);
    const [editOrganization, setEditOrganization] = useState({
        OrgId: 0,
        OrgName: "",
        PhoneNo: "",
        AddressLine1: "",
        AddressLine2: "",
        CountryName : "",
        CityName : "",
        PinCode : "",
        Email : "",
        PoBoxNo : "",
        Website : "",
        LogoUrl : "",
        PhotoPath : "",
        ContactPerson : "",
        State : "",
        EntryDate : "",
        EntryBy : 0
    });
    console.log("editOrganization,", editOrganization);
    const [organizationList, setOrganizationList] = useState({});

console.log("organizationList,", organizationList)


    const fetchOrganizationList = () => {
        setLoading(true);
        simpleGetCallWithToken(ApiConfig.GET_ORGANIZATION_LIST)
            .then((res) => {
                console.log("Organization List", res);
                // if (res.Success === true) {
                setLoading(false);
                setOrganizationList(res);
                // }
                console.log(res.Data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchOrganizationList();
    }, []);
    const [btnLoader, setbtnLoader] = useState(false);


    const updateOrganization = (e) => {
        e.preventDefault();
        let formData = sendAsFormData(editOrganization)
        putMultipartWithAuthCall(ApiConfig.UPDATE_ORGANIZATION, 
            formData)
            .then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="role-content-main">
                    <div className="sub-header-role mb-4">
                        <h6 className="mt-2">Organization Profile</h6>
                        {/* <button
              className="create-btn-sub-header"
              variant="primary"
              onClick={() => {
                setShow(true);
             
              }}
            >
              + Add New Record{" "}
            </button> */}
                    </div>

                    <div className="table-wrapper">
                        {loading ? (
                            <>
                                <Loader />
                            </>
                        ) : (
                            <>
                                {organizationList && organizationList ? (
                                    <>
                                        <table>
                                            <thead>
                                                <tr className="firstTabelRow">
                                                    <th>Logo</th>
                                                    <th>Organization Name</th>
                                                    <th>Website</th>
                                                    <th>Contact No</th>
                                                    <th>Contact Person</th>
                                                    <th>Email</th>
                                                    {/* <th>Address Line1</th> */}
                                                    <th>Address Line2</th>
                                                    <th>City</th>
                                                    <th>State</th>
                                                    <th>Country</th>
                                                    <th>Pincode</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    <tr className="table-row-custom">
                                                        <td>
                                                            <img
                                                                src={organizationList.PhotoPath}
                                                                alt=""
                                                                width="25"
                                                                height="25"
                                                            />
                                                        </td>
                                                        <td>{organizationList.OrgName}</td>
                                                        <td>{organizationList.Website}</td>
                                                        <td>{organizationList.PhoneNo}</td>
                                                        <td>{organizationList.ContactPerson}</td>
                                                        <td>{organizationList.Email}</td>
                                                        {/* <td>
                                                            <div class="col-2 text-truncate">
                                                            {organizationList.AddressLine1}

                                                            </div>
                                                            
                                                        </td> */}
                                                        <td>{organizationList.AddressLine2}</td>
                                                        <td>{organizationList.CityName}</td>
                                                        <td>{organizationList.State}</td>
                                                        <td>{organizationList.CountryName}</td>
                                                        <td>{organizationList.PinCode}</td>
                                                        <td>
                                                            <img src={Editac} className="mx-2 editBtn"
                                                                onClick={() => {
                                                                    setShowEdit(true)
                                                                    setEditOrganization({
                                                                        // OrgId: organizationList.OrgId,
                                                                        OrgId: 1,
                                                                        OrgName: organizationList.OrgName,
                                                                        PhoneNo: organizationList.PhoneNo,
                                                                        AddressLine1: organizationList.AddressLine1,
                                                                        AddressLine2: organizationList.AddressLine2,
                                                                        CountryName : organizationList.CountryName,
                                                                        CityName : organizationList.CityName,
                                                                        PinCode : organizationList.PinCode,
                                                                        Email : organizationList.Email,
                                                                        Fax : "15464",
                                                                        // PoBoxNo : organizationList.PoBoxNo,
                                                                        PoBoxNo : 0,
                                                                        Website : organizationList.Website,
                                                                        LogoUrl : organizationList.LogoUrl,
                                                                        ContactPerson : organizationList.ContactPerson,
                                                                        State : organizationList.State,
                                                                        EntryDate : new Date(),
                                                                        EntryBy : organizationList.EntryBy
                                                                    })
                                                                }}
                                                                alt="" />
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </>
                                ) : (
                                    <NoData />
                                )}
                            </>
                        )}
                    </div>
                </div>
                {/* <Pagination
                data={holidayData}
                pageChangeHandler={pageChangeHandler}
                usersPerPage={usersPerPage}
                currentPage={currentPage}
                searchValue={searchValue}
                searchedDataPageCounts={searchedDataPageCounts}
            /> */}
            </div>

            {/* Add Modal Task */}
         

            {/* Edit Modal Task */}
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showEdit}
                onHide={() => setShowEdit(false)}
            >
                <Form noValidate validated={validated} onSubmit={updateOrganization}>
                    <Modal.Header>
                        <Modal.Title>
                            <label htmlFor="" className="create-role-text">
                                Update Organization
                            </label>
                        </Modal.Title>
                        <div className="rightAxe">
                            <img src={Axe} alt="" onClick={() => setShowEdit(false)} />
                        </div>
                    </Modal.Header>
                    <div className="hr-line-modal">
                        <hr style={{ margin: "0" }} />
                    </div>
                    <Modal.Body>
                        <div  className="modalProp mb-2">
                        <input
                        type="file"
                        className="custom-file-input mt-2"
                        onChange={(e) => {
                          setEditOrganization({
                            ...editOrganization,
                            PhotoPath: e.target.files[0],
                          });
                        }}
                        required={!organizationList.LogoUrl ? true : false}
                      />
                      {/* <div className="invalid-feedback">Upload Profile Picture</div> */}
                      {/* {!organizationList.LogoUrl && (
                        <div className="invalid-feedback">
                          Upload Profile Picture
                        </div>
                      )} */}
                        </div>
                        <div className="modalProp mb-2">
                            <label
                                htmlFor=""
                                className="enter-role-text mt-2"
                                style={{ fontSize: "14px" }}
                            >
                                Organization Name
                            </label>
                            <input
                                // onChange={(e) => {
                                //     changeData(e)
                                // }}
                                name="HolidayName"
                                // value={adminPostData.budgethours}
                                value={editOrganization?.OrgName}
                                onChange={(e) =>
                                    setEditOrganization({
                                        ...editOrganization,
                                        OrgName: e.target.value,
                                    })
                                }
                                type="text"
                                className="date-label-input tasKCategory1"
                                style={{
                                    fontSize: "14px",
                                    height: "38px",
                                    border: "1px solid #ced4da",
                                }}
                            />
                        </div>
                        <div className="modalProp mb-2">
                            <label
                                htmlFor=""
                                className="enter-role-text mt-2"
                                style={{ fontSize: "14px" }}
                            >
                                Website
                            </label>
                            <input
                                // onChange={(e) => {
                                //     changeData(e)
                                // }}
                                name="HolidayName"
                                // value={adminPostData.budgethours}
                                value={editOrganization?.Website}
                                onChange={(e) =>
                                    setEditOrganization({
                                        ...editOrganization,
                                        Website: e.target.value,
                                    })
                                }
                                type="text"
                                className="date-label-input tasKCategory1"
                                style={{
                                    fontSize: "14px",
                                    height: "38px",
                                    border: "1px solid #ced4da",
                                }}
                            />
                        </div>
                        <div className="modalProp mb-2">
                            <label
                                htmlFor=""
                                className="enter-role-text mt-2"
                                style={{ fontSize: "14px" }}
                            >
                                Contact
                            </label>
                            <input
                                // onChange={(e) => {
                                //     changeData(e)
                                // }}
                                name="HolidayName"
                                // value={adminPostData.budgethours}
                                value={editOrganization?.PhoneNo}
                                onChange={(e) =>
                                    setEditOrganization({
                                        ...editOrganization,
                                        PhoneNo: e.target.value,
                                    })
                                }
                                type="text"
                                className="date-label-input tasKCategory1"
                                style={{
                                    fontSize: "14px",
                                    height: "38px",
                                    border: "1px solid #ced4da",
                                }}
                            />
                        </div>
                        <div className="modalProp mb-2">
                            <label
                                htmlFor=""
                                className="enter-role-text mt-2"
                                style={{ fontSize: "14px" }}
                            >
                                Contact Person
                            </label>
                            <input
                                // onChange={(e) => {
                                //     changeData(e)
                                // }}
                                name="HolidayName"
                                // value={adminPostData.budgethours}
                                value={editOrganization?.ContactPerson}
                                onChange={(e) =>
                                    setEditOrganization({
                                        ...editOrganization,
                                        ContactPerson: e.target.value,
                                    })
                                }
                                type="text"
                                className="date-label-input tasKCategory1"
                                style={{
                                    fontSize: "14px",
                                    height: "38px",
                                    border: "1px solid #ced4da",
                                }}
                            />
                        </div>
                        <div className="modalProp mb-2">
                            <label
                                htmlFor=""
                                className="enter-role-text mt-2"
                                style={{ fontSize: "14px" }}
                            >
                                Email
                            </label>
                            <input
                                // onChange={(e) => {
                                //     changeData(e)
                                // }}
                                name="HolidayName"
                                // value={adminPostData.budgethours}
                                value={editOrganization?.Email}
                                onChange={(e) =>
                                    setEditOrganization({
                                        ...editOrganization,
                                        Email: e.target.value,
                                    })
                                }
                                type="text"
                                className="date-label-input tasKCategory1"
                                style={{
                                    fontSize: "14px",
                                    height: "38px",
                                    border: "1px solid #ced4da",
                                }}
                            />
                        </div>
                        <div className="modalProp mb-2">
                            <label
                                htmlFor=""
                                className="enter-role-text mt-2"
                                style={{ fontSize: "14px" }}
                            >
                                Address Line1
                            </label>
                            <input
                                // onChange={(e) => {
                                //     changeData(e)
                                // }}
                                name="HolidayName"
                                // value={adminPostData.budgethours}
                                value={editOrganization?.AddressLine1}
                                onChange={(e) =>
                                    setEditOrganization({
                                        ...editOrganization,
                                        AddressLine1: e.target.value,
                                    })
                                }
                                type="text"
                                className="date-label-input tasKCategory1"
                                style={{
                                    fontSize: "14px",
                                    height: "38px",
                                    border: "1px solid #ced4da",
                                }}
                            />
                        </div>
                        <div className="modalProp mb-2">
                            <label
                                htmlFor=""
                                className="enter-role-text mt-2"
                                style={{ fontSize: "14px" }}
                            >
                                Address Line2
                            </label>
                            <input
                                // onChange={(e) => {
                                //     changeData(e)
                                // }}
                                name="HolidayName"
                                // value={adminPostData.budgethours}
                                value={editOrganization?.AddressLine2}
                                onChange={(e) =>
                                    setEditOrganization({
                                        ...editOrganization,
                                        AddressLine2: e.target.value,
                                    })
                                }
                                type="text"
                                className="date-label-input tasKCategory1"
                                style={{
                                    fontSize: "14px",
                                    height: "38px",
                                    border: "1px solid #ced4da",
                                }}
                            />
                        </div>
                        <div className="modalProp mb-2">
                            <label
                                htmlFor=""
                                className="enter-role-text mt-2"
                                style={{ fontSize: "14px" }}
                            >
                                City
                            </label>
                            <input
                                // onChange={(e) => {
                                //     changeData(e)
                                // }}
                                name="HolidayName"
                                // value={adminPostData.budgethours}
                                value={editOrganization?.CityName}
                                onChange={(e) =>
                                    setEditOrganization({
                                        ...editOrganization,
                                        CityName: e.target.value,
                                    })
                                }
                                type="text"
                                className="date-label-input tasKCategory1"
                                style={{
                                    fontSize: "14px",
                                    height: "38px",
                                    border: "1px solid #ced4da",
                                }}
                            />
                        </div>
                        <div className="modalProp mb-2">
                            <label
                                htmlFor=""
                                className="enter-role-text mt-2"
                                style={{ fontSize: "14px" }}
                            >
                                State
                            </label>
                            <input
                                // onChange={(e) => {
                                //     changeData(e)
                                // }}
                                name="HolidayName"
                                // value={adminPostData.budgethours}
                                value={editOrganization?.State}
                                onChange={(e) =>
                                    setEditOrganization({
                                        ...editOrganization,
                                        State: e.target.value,
                                    })
                                }
                                type="text"
                                className="date-label-input tasKCategory1"
                                style={{
                                    fontSize: "14px",
                                    height: "38px",
                                    border: "1px solid #ced4da",
                                }}
                            />
                        </div>
                        <div className="modalProp mb-2">
                            <label
                                htmlFor=""
                                className="enter-role-text mt-2"
                                style={{ fontSize: "14px" }}
                            >
                                Country
                            </label>
                            <input
                                // onChange={(e) => {
                                //     changeData(e)
                                // }}
                                name="HolidayName"
                                // value={adminPostData.budgethours}
                                value={editOrganization?.CountryName}
                                onChange={(e) =>
                                    setEditOrganization({
                                        ...editOrganization,
                                        CountryName: e.target.value,
                                    })
                                }
                                type="text"
                                className="date-label-input tasKCategory1"
                                style={{
                                    fontSize: "14px",
                                    height: "38px",
                                    border: "1px solid #ced4da",
                                }}
                            />
                        </div>
                        <div className="modalProp mb-2">
                            <label
                                htmlFor=""
                                className="enter-role-text mt-2"
                                style={{ fontSize: "14px" }}
                            >
                                Pincode
                            </label>
                            <input
                                // onChange={(e) => {
                                //     changeData(e)
                                // }}
                                name="HolidayName"
                                // value={adminPostData.budgethours}
                                value={editOrganization?.PinCode}
                                onChange={(e) =>
                                    setEditOrganization({
                                        ...editOrganization,
                                        PinCode: e.target.value,
                                    })
                                }
                                type="text"
                                className="date-label-input tasKCategory1"
                                style={{
                                    fontSize: "14px",
                                    height: "38px",
                                    border: "1px solid #ced4da",
                                }}
                            />
                        </div>
                    </Modal.Body>

                    <div className="hr-line-modal-1">
                        <hr style={{ margin: "0" }} />
                    </div>
                    <Modal.Footer className="model-footer-arbaz-responsive">
                        <div className="Bottom-btn-arbaz-responsive">
                            <button
                                type="button"
                                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                            onClick={() => {
                                setShowEdit(false)
                            }}
                            >
                                Cancel
                            </button>
                            <button
                                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                                // onClick={addTaskByAdmin}
                                type="submit"
                            >
                                Update
                            </button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default OrganizationDetails;
