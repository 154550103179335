import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Form, Tab, Tabs } from "react-bootstrap";

const BulkUpload = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Bulk Upload</h6>
        </div>
        <div className="settingsCard">
          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  App *
                </label>
                <input
                  name="firstName"
                  type="text"
                  className="date-label-input tasKCategory1 ps-2"
                  placeholder="Enter the app"
                  style={{
                    fontSize: "0.8rem",
                    borderRadius: "10px",
                    height: "40px",
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  App Type *
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                >
                  <option selected value="0">
                    Select App Type
                  </option>
                  <option value="1">Primary App</option>
                  <option value="2">Secondary App</option>
                  <option value="3">Admin Panel</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Domain *
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                >
                  <option selected value="0">
                    Select Domain
                  </option>
                  <option value="1">Education</option>
                  <option value="2">Food Delivery</option>
                  <option value="3">Life Style</option>
                  <option value="1">Real Estate</option>
                  <option value="2">Social Media</option>
                  <option value="3">Transport & Logistics</option>
                  <option value="1">Travel</option>
                  <option value="2">Food Booking</option>
                  <option value="3">Accounting</option>
                  <option value="1">Ecommerce</option>
                  <option value="2">Services</option>
                  <option value="3">Non-governmental Oranganization</option>
                  <option value="2">Chatbot App</option>
                  <option value="3">CRM</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Category *
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                >
                  <option selected value="0">
                    Select Category
                  </option>
                  <option value="1">travel Guide</option>
                  <option value="2">Travel Itinerary</option>
                  <option value="3">General</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Upload File *
                </label>
                <input
                  name="fileupload"
                  type="file"
                  className="ps-2"
                  style={{
                    fontSize: "0.8rem",
                    borderRadius: "10px",
                    height: "40px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 text-end mt-2">
            <button className="impButton">Upload</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkUpload;
