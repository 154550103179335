import React, { useContext, useEffect, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { AppContext } from "../../context/AppContext";

export default function Mesnsion({
  setEmogi,
  replyTo,
  uploadedFiles,
  setUploadedFiles,
  message,
  setMessage,
  setMenstionedUsers,
  menstionedUsers,
  inputRef,
  recentChats,
  setRecentChats,
  pinnedChats,
  setPinnedChats,
  activeChat,
}) {
  const {
    userTyping,
    membersArray,
    setMembersArray,
    sendMessage,
    currentSChannel,
    socket,
    userData,
  } = useContext(AppContext);

  const mentionMembers = membersArray
    .filter((user) => Number(userData.EmpId) !== user.User_Id)
    .map((user) => {
      return {
        id: user?.User_Id,
        display: user?.FirstName + " " + user?.LastName,
      };
    });
  // menstionedUsers

  const typing = () => {
    if (currentSChannel)
      socket &&
        socket.emit("typing", {
          schannel_id: currentSChannel,
          userName: localStorage.getItem("Username"),
        });
  };
  const stopTyping = () => {
    if (currentSChannel)
      socket &&
        socket.emit("stoptyping", {
          schannel_id: currentSChannel,
        });
  };
  useEffect(() => {
    let timout = setTimeout(() => {
      stopTyping();
    }, 2000);
    return () => {
      clearTimeout(timout);
    };
  }, [message, socket]);

  return (
    <>
      <MentionsInput
        inputRef={inputRef}
        id="mentionInput"
        className="form-data"
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
        placeholder={"Type a message here Or Mention people using '@'"}
        a11ySuggestionsListLabel={"Suggested mentions"}
        allowSpaceInQuery={true}
        onBlur={stopTyping}
        style={{
          border: "none",
          height: "100%",
          maxHeight: "100px",
          marginLeft: "15px",
        }}
        spellCheck={true}
        onKeyUp={(e) => {
          typing();
          setEmogi(false);
          if (e.key === 13 && !e.shiftKey) {
            e.preventDefault();
          }
          if (e.key === "Enter" && !e.shiftKey) {
            if (message.trim().length || uploadedFiles.length) {
              setEmogi(false);
              sendMessage(
                message,
                [...uploadedFiles],
                replyTo,
                menstionedUsers
              );
              setUploadedFiles([]);
              setMessage("");
              setMenstionedUsers([]);
            }
          }
        }}
        appendSpaceOnAdd={true}
        allowSuggestionsAboveCursor={true}
        forceSuggestionsAboveCursor={true}
      >
        <Mention
          appendSpaceOnAdd={true}
          onAdd={(selectedUser) => {
            let filterObj =
              mentionMembers &&
              mentionMembers.find((item) => item.id === selectedUser);

            let filterMembersWithoutEveryone =
              mentionMembers &&
              mentionMembers.filter((ele) => ele.id !== "everyone");
            selectedUser === "everyone"
              ? setMenstionedUsers(filterMembersWithoutEveryone)
              : setMenstionedUsers([...menstionedUsers, filterObj]);
          }}
          data={mentionMembers && mentionMembers}
        />
      </MentionsInput>
    </>
  );
}
