import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";

import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import edit from "../../assets/images/edit.svg";
import eye from "../../assets/images/ic_show_eyes.svg";
import plus from "../../assets/images/plus.svg";
import { Link, Navigate } from "react-router-dom";
import BookIcon from "../../assets/images/ic_excel.svg";

import { useNavigate } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination";

const categories = ["", "", "", "", "", "", "", "", "", ""];

const months = ["Sr No", "Name", "Email", "Phone No", "Action"];
const data = [
  ["1", "Mark", "Otto@xyz.com", "+917247290681", ""], // IOT
  ["2", "Mark", "Otto@xyz.com", "+917247290681", ""], // Services
  ["3", "Mark", "Otto@xyz.com", "+917247290681", ""], // Booking
  ["4", "Mark", "Otto@xyz.com", "+917247290681", ""], // Social
  ["5", "Mark", "Otto@xyz.com", "+917247290681", ""], // Commerce
  ["6", "Mark", "Otto@xyz.com", "+917247290681", ""], // Health
  ["7", "Mark", "Otto@xyz.com", "+917247290681", ""], // Education
  ["8", "Mark", "Otto@xyz.com", "+917247290681", ""], // Donation
  ["9", "Mark", "Otto@xyz.com", "+917247290681", ""], // Game
  ["10", "Mark", "Otto@xyz.com", "+917247290681", ""], // Transport
  ["11", "Mark", "Otto@xyz.com", "+917247290681", ""], // Food Delivery
  ["12", "Mark", "Otto@xyz.com", "+917247290681", ""], // Real Estate
  ["13", "Mark", "Otto@xyz.com", "+917247290681", ""], // Productivity
  ["14", "Mark", "Otto@xyz.com", "+917247290681", ""], // Suggestion
];

const Campaign = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const [appCategory, setAppCategory] = useState("");
  const [campaignType, setCampaignType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const handleAppCategoryChange = (event) => {
    setAppCategory(event.target.value);
  };

  const handleCampaignTypeChange = (event) => {
    setCampaignType(event.target.value);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleButtonClick = () => {
    alert(
      `App Category: ${appCategory}\nCampaign Type: ${campaignType}\nSearch Term: ${searchTerm}`
    );
  };

  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [key, setKey] = useState("personalDetails");
  console.log("Key data >>>>>>>>>>", key);

  const navigate = useNavigate();

  return (
    <div>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Campaign</h6>
          </div>

          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search "
                  // onChange={(e) => {
                  //   setSearchValue(e.target.value);
                  //   setCurrentPage(0);
                  // }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //   setUsersPerPage(e.target.value);
                  //   setCurrentPage(0);
                  // }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
            </div>

            <div className="import-export-btn-wrapper mt-4">
              <div className="reset mr-2">
                <p>Reset</p>
              </div>
              <div className="booksIcon">
                <img src={BookIcon} className="innerIconsBtn" alt="" />
              </div>
            </div>
          </div>

          <h6 className="mt-2 align-self-end d-flex justify-content-end">
            Invalid Phone Numbers Count: 0
          </h6>

          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <div className="CampaignRow">
                  <select
                    value={appCategory}
                    onChange={handleAppCategoryChange}
                    className="CampaignDropdown"
                    style={{
                      border: "none",
                      borderBottomLeftRadius: "10px",
                      borderTopLeftRadius: "10px",
                      width: "190px",
                      height: "40px",
                    }}
                  >
                    <option value="">App Category</option>
                    <option value="IOT">IOT</option>
                    <option value="Services">Services</option>
                    <option value="Booking">Booking</option>
                    <option value="Social">Social</option>
                  </select>
                  <button
                    className="CampaignButton p-2"
                    style={{
                      borderBottomRightRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
              <div className="search-input-wrapper">
                <div className="CampaignRow">
                  <select
                    value={campaignType}
                    onChange={handleCampaignTypeChange}
                    className="CampaignDropdown"
                    style={{
                      border: "none",
                      borderBottomLeftRadius: "10px",
                      borderTopLeftRadius: "10px",
                      width: "190px",
                      height: "40px",
                    }}
                  >
                    <option value="">Campaign Type</option>
                    <option value="Email">Email</option>
                    <option value="Whatsapp">Whatsapp</option>
                    <option value="Both">Both</option>
                  </select>
                  <button
                    className="CampaignButton p-2"
                    style={{
                      borderBottomRightRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
              <div className="search-input-wrapper">
                <div className="CampaignRow">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    className="CampaignInput"
                    placeholder="Search"
                    style={{
                      borderBottomRightRadius: "0px",
                      borderTopRightRadius: "0px",
                      width: "190px",
                      height: "40px",
                    }}
                  />
                  <button
                    onClick={handleButtonClick}
                    className="CampaignButton p-2"
                    style={{
                      borderBottomRightRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            <table>
              <thead>
                <tr className="firstTabelRow">
                  <th>Select</th>
                  {months.map((month, index) => (
                    <th key={index} className="header">
                      {month}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {categories.map((category, catIndex) => (
                  <tr key={catIndex} className="table-row-custom">
                    <td>{category}</td>
                    {data[catIndex].map((value, monthIndex) => (
                      <td key={monthIndex}>
                        {monthIndex === data[catIndex].length - 1 ? (
                          <div>
                            <div className="CampaignRow">
                              <button
                                onClick={handleButtonClick}
                                className="CampaignButton"
                              >
                                Email
                              </button>
                              <button
                                onClick={handleButtonClick}
                                className="CampaignButton"
                              >
                                Whatsapp
                              </button>
                            </div>
                          </div>
                        ) : (
                          value
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
      // data={EmployeeList}
      // pageChangeHandler={pageChangeHandler}
      // usersPerPage={usersPerPage}
      // currentPage={currentPage}
      // searchValue={searchValue}
      // searchedDataPageCounts={searchedDataPageCounts}
      />
    </div>
  );
};

export default Campaign;
