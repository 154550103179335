import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";

import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import edit from "../../assets/images/edit.svg";
import eye from "../../assets/images/ic_show_eyes.svg";
import plus from "../../assets/images/plus.svg";
import { Link, Navigate } from "react-router-dom";

import { useNavigate } from "react-router-dom";

const categories = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const months = [
  "Title",
  "First Name",
  "Email",
  "Phone",
  "City",
  "State",
  "Country",
  "CreatedOn",
  "Action",
];
const data = [
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total // Transport
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total// Food Delivery
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total // Real Estate
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total // Productivity
  [
    "Tejas Vaidya",
    "Tejas Vaidya",
    "tejas@denven.com",
    "+447435376883",
    "None",
    "India",
    "State",
    "Country",
    "Action",
  ], // Total // Suggestion
];

const ProposalAttachement = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [key, setKey] = useState("personalDetails");
  console.log("Key data >>>>>>>>>>", key);

  const navigate = useNavigate();

  return (
    <div>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2"> Proposal Attachment</h6>
          </div>

          {/* <div className="LeadSearchrow">
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search"
                //   onChange={(e) => {
                //     setSearchValue(e.target.value);
                //     setCurrentPage(0);
                //   }}
              />
              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="reset">
              <p>Reset</p>
            </div>
          </div> */}
          <h6 className="mt-2 align-self-end d-flex justify-content-end">
            Proposal Not There In Attachment: 9447
          </h6>

          <div className="table-container">
            <table className="custom-table">
              <thead>
                <tr>
                  <th className="header">Sr.No</th>
                  {months.map((month, index) => (
                    <th key={index} className="header">
                      {month}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {categories.map((category, catIndex) => (
                  <tr key={catIndex}>
                    <td className="cell-text">{category}</td>
                    {data[catIndex].map((value, monthIndex) => (
                      <td key={monthIndex} className="cell-text">
                        {monthIndex === data[catIndex].length - 1 ? (
                          <div>
                            <Link to="/LeadManagementListEdit">
                              <img
                                src={edit}
                                alt="Image 1"
                                className="cell-image"
                              />
                            </Link>
                            <Link to="/LeadListCount">
                              <img
                                src={eye}
                                alt="Image 2"
                                className="cell-image"
                              />
                            </Link>

                            <img
                              src={plus}
                              alt="Image 2"
                              className="cell-image"
                            />
                          </div>
                        ) : (
                          value
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProposalAttachement;
