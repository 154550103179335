import React, { useContext, useEffect, useState } from "react";
import importicon from "../../assets/images/import.svg";
import { Dropdown, Nav, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const EmpoloyeeWallet = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="employee_wallet_main">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Employee Wallet </h6>
          </div>
          <div className="subHeaderFlex">
            <div className="detailBox">
              <h1 className="headtxt">Employee Name</h1>
              <div className="amntflex">
                <label>&#8377;</label>
                <label>5000</label>
              </div>
            </div>
            <div className="withDrawBtn">
              <button className="reachargeWal">
                <Link to="/RechargeWallet">Recharge Wallet</Link>
              </button>
            </div>
          </div>
          <div className="transactionBox">
            <h1 className="headTxt">Transaction History</h1>
          </div>
          <div className="row">
            <div className="col-md-3 mb-4">
              <div className="search-input-wrapper">
                <input
                  type="date"
                  data-date=""
                  data-date-format="DD MMMM YYYY"
                  placeholder="Search"
                />
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="search-input-wrapper-main marginTOp">
                <select
                  className="form-select input-select-option"
                  aria-label="Default select example"
                >
                  <option value="">Notification Type</option>
                  <option value="One">One</option>
                  <option value="Two">Two</option>
                </select>
              </div>
            </div>
            <div className="col-md-3 mb-4">
              <div className="search-input-wrapper-main marginTOp">
                <select
                  className="form-select input-select-option"
                  aria-label="Default select example"
                >
                  <option value="">Notification Like</option>
                  <option value="One">One</option>
                  <option value="Two">Two</option>
                </select>
              </div>
            </div>
            <div className="col-md-3 text-end mb-4">
              <button className="exportBtn">
                <img src={importicon} alt="" />
              </button>
            </div>
            <div className="col-md-12">
              <div id="walletTabs">
                <Tab.Container
                  id="left-tabs-example"
                  className="td-tab-wrapper"
                  defaultActiveKey="1"
                >
                  <Nav
                    variant="pills"
                    className="td-nav"
                    id="InnerTabNew_Three"
                  >
                    <Nav.Item className="td-tab">
                      <Nav.Link className="td-link" eventKey="1">
                        All
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="td-tab">
                      <Nav.Link className="td-link" eventKey="2">
                        Successfull
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="td-tab">
                      <Nav.Link className="td-link" eventKey="3">
                        Pending
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="td-tab">
                      <Nav.Link className="td-link" eventKey="4">
                        Failed
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="1">
                      <div
                        className="table-wrapper"
                        id="scrol-tblNtable-wrapper-af"
                      >
                        <table className="">
                          <thead className="firstTabelRow">
                            <tr classname="row-hover modal-footer-table-body-tr">
                              <th>Sr.No.</th>
                              <th>Recharge ID</th>
                              <th>Traqnscation No.</th>
                              <th>Reference No.</th>
                              <th>Amount</th>
                              <th>Date & Time</th>
                              <th>Mode</th>
                              <th>Paid By</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="2">
                      <div
                        className="table-wrapper"
                        id="scrol-tblNtable-wrapper-af"
                      >
                        <table className="">
                          <thead className="firstTabelRow">
                            <tr classname="row-hover modal-footer-table-body-tr">
                              <th>Sr.No.</th>
                              <th>Recharge ID</th>
                              <th>Traqnscation No.</th>
                              <th>Reference No.</th>
                              <th>Amount</th>
                              <th>Date & Time</th>
                              <th>Mode</th>
                              <th>Paid By</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="3">
                      <div
                        className="table-wrapper"
                        id="scrol-tblNtable-wrapper-af"
                      >
                        <table className="">
                          <thead className="firstTabelRow">
                            <tr classname="row-hover modal-footer-table-body-tr">
                              <th>Sr.No.</th>
                              <th>Recharge ID</th>
                              <th>Traqnscation No.</th>
                              <th>Reference No.</th>
                              <th>Amount</th>
                              <th>Date & Time</th>
                              <th>Mode</th>
                              <th>Paid By</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="4">
                      <div
                        className="table-wrapper"
                        id="scrol-tblNtable-wrapper-af"
                      >
                        <table className="">
                          <thead className="firstTabelRow">
                            <tr classname="row-hover modal-footer-table-body-tr">
                              <th>Sr.No.</th>
                              <th>Recharge ID</th>
                              <th>Traqnscation No.</th>
                              <th>Reference No.</th>
                              <th>Amount</th>
                              <th>Date & Time</th>
                              <th>Mode</th>
                              <th>Paid By</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                            <tr className="table-row-custom">
                              <td>1</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                              <td>1234</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpoloyeeWallet;
