import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import verticle from "../../assets/images/verticle_line.svg";
import searchicon from "../../assets/images/search_icon.svg";
import importicon from "../../assets/images/import.svg";
import plus from "../../assets/images/Union_10.svg";
import Edit from "../../assets/images/edit.svg";
import { Form, Modal } from "react-bootstrap";
import close from "../../assets/images/close.svg";
import Select from "react-select";
import ApiConfig from "../../api/ApiConfig";
import { PostCallWithErrorResponse, simpleGetCallWithToken, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import { notifyError, notifySuccess } from "../../toaster/notify";
import optionIcon from "../../assets/images/bx_edit.svg";
import ReactPaginate from "react-paginate";
import Loader from "../Loader";
import { ClipLoader } from "react-spinners";
import { useDebounce } from "../../common";
import NoData from "../../sharedComponent/NoData";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";


const AssignRole = () => {

    const { sidebar,permissions } = useContext(AppContext);
    const [userDetail, setUserDetail] = useState({
        EmployeeId: 0,
        AssignedRoles: [],
    });
    console.log("userDetail,", userDetail);
    const [errMessage, setErrorMessage] = useState({
        userName: "",
        role: "",
    });
    const [userNames, setUserNames] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [selectedRoleList, setSelectedRoleList] = useState([]);
    const [show, setShow] = useState(false);
    const [edit, setEdit] = useState(false);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const Route = useLocation().pathname;
    const navigate = useNavigate();
    const handleClosed = () => setEdit(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [excelLoading, setExcelLoading] = useState(false);

    const [searchValue, setSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [usersPerPage, setUsersPerPage] = useState(5);

    const startIndex = currentPage * usersPerPage;
    const endIndex = startIndex + parseInt(usersPerPage);

    const pageChangeHandler = ({ selected }) => {
        setCurrentPage(selected);
    };

    const downloadExcelFile = (e) => {
        setExcelLoading(true);
        e.preventDefault();
        simpleGetCallWithErrorResponse(ApiConfig.ASSIGNROLE_DOWNLOAD_EXCEL)
          .then((res) => {
            console.log("Download File Response", res);
            if (res.error) {
              console.log("Error response", res);
            } else {
              if (res.json.Success === true) {
                setExcelLoading(false);
                let data = res.json.Data;
                FileSaver.saveAs(
                  "data:" + data.ContentType + ";base64," + data.FileContents,
                  data.FileDownloadName
                );
              } else {
                console.log("res", res);
                setExcelLoading(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };

    const debouncedValue = useDebounce(searchValue, 500)

    useEffect(() => {
        getUserList();
        getUserRoleList();
    }, []);

    useEffect(() => {
        getAssignedUsers();
    }, [debouncedValue]);

    const getAssignedUsers = async () => {
        setLoading(true);
        let payLoad = {
            Name: ""
        }
        try {
            const res = await PostCallWithErrorResponse(
                ApiConfig.GET_ALL_ASSIGN_USERS,
                JSON.stringify(payLoad)
            )
            if (res.json.Success === true) {
                console.log(res.json.Data);
                if (searchValue) {
                    let filteredData = res.json.Data.filter((item) => {
                        return item.Name.toLowerCase().includes(searchValue.toLowerCase())||item.Roles.toLowerCase().includes(searchValue.toLowerCase())
                    })
                    setAssignedUsers(filteredData)
                } else {
                    setAssignedUsers(res.json.Data);
                }
            } else {
                console.log("error response,", res);
            }
            if (searchValue) {
                return setCurrentPage(0)
            }
        } catch (error) {
            console.log("error response,", error);
        } finally {
            setLoading(false);
        }
    };

    const getUserList = async () => {
        try {
            const res = await PostCallWithErrorResponse(ApiConfig.GET_EMPLOYEE_NAMES);
            if (res.json.Success === true) {
                let userOptions = res.json.Data.map((item) => {
                    return {
                        label: item.FullName,
                        value: item.Id,
                    }
                })
                setUserNames(userOptions)
            } else {
                console.log("getUserList error response,", res);
            }
        } catch (error) {
            console.log("error response", error);
        }
    };

    const getUserRoleList = async () => {
        try {
            const res = await simpleGetCallWithToken(ApiConfig.GET_ALL_ROLE_NAMES);
            if (res.Success === true) {
                const roleDropdown = res.Data.map((item) => {
                    return {
                        label: item.Name,
                        value: item.RoleId,
                    }
                })
                setRoleList(roleDropdown);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const assignRoleHandler = async (event) => {
        event.preventDefault();
        if (userDetail.EmployeeId === 0) {
            setErrorMessage({
                ...errMessage,
                userName: "Please select a username",
            });
            return;
        }
        if (userDetail.AssignedRoles.length === 0) {
            setErrorMessage({
                ...errMessage,
                role: "Please select roles",
            });
            return;
        }
        try {
            setBtnLoader(true)
            const responce = await PostCallWithErrorResponse(
                ApiConfig.ASSIGN_ROLE,
                JSON.stringify(userDetail)
            );
            if (responce.json.Success) {
                handleClose();
                notifySuccess("Role added successfully");
                getAssignedUsers();
            } else {
                notifyError("Role name is already exist");
            }
        } catch (error) {
            console.log(error);
        } finally {
            setBtnLoader(false)
        }
        setValidated(true);
    };

    const getEditData = async (userDetail) => {
        try {
            const res = await simpleGetCallWithToken(
                ApiConfig.GET_USER_ROLE_EDIT_DATA + `/${userDetail.EmployeeId}`
            );
            if (res.Success === true) {
                const userRoleIdArray = res.Data.UserRoles.map((item) => {
                    return {
                        label: item.Name,
                        value: item.RoleId,
                    }
                })
                setSelectedRoleList(userRoleIdArray);
                let userIds = userRoleIdArray.map((id) => id.value);
                setUserDetail({
                    EmployeeId: userDetail.EmployeeId,
                    AssignedRoles: userIds,
                    userName: userDetail.Name,
                });
                getUserRoleList();
            } else {
                console.log("getEditData response,", res)
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateRoleHandler = async (event) => {
        event.preventDefault();
        if (userDetail.EmployeeId === 0) {
            setErrorMessage({
                ...errMessage,
                userName: "Please select a username",
            });
            return;
        }
        if (userDetail.AssignedRoles.length === 0) {
            setErrorMessage({
                ...errMessage,
                role: "Please select roles",
            });
            return;
        }
        try {
            setBtnLoader(true)
            const responce = await PostCallWithErrorResponse(
                ApiConfig.EDIT_ASIGN_ROLE,
                JSON.stringify(userDetail)
            );
            if (responce.json.Success) {
                handleClosed();
                notifySuccess("Role update successfully");
                getAssignedUsers();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setBtnLoader(false)
        }
        setValidated(true);
    };

    return (
        <div
            className={sidebar ? "taskMain " : "cx-active taskMain"}
            id="cx-main"
        >
            <div className="role-content">
                <div className="sub-header-role sub-header-role-arbaz-responsive ">
                    <span
                        onClick={() => {
                            navigate("/Role");
                        }}
                    >
                        <h6 className="mt-2 role-text">Role</h6>
                    </span>
                    <div className="span-wrapper-sub-header">
                        <span
                            onClick={() => {
                                navigate("/Role");
                            }}
                            className={
                                Route === "/Role"
                                    ? "sub-header-span-active"
                                    : "sub-header-span"
                            }
                        >
                            Create
                        </span>
                        <img src={verticle} alt="" style={{ marginRight: "20px" }} />
                        <span
                            className={
                                Route === "/AssignRole"
                                    ? "sub-header-span-active"
                                    : "sub-header-span"
                            }
                            style={{ marginRight: "20px" }}
                            onClick={() => {
                                navigate("/AssignRole");
                            }}
                        >
                            Assign
                        </span>
                        <img src={verticle} alt="" style={{ marginRight: "20px" }} />
                        <span
                            className="sub-header-span"
                            onClick={() => {
                                navigate("/Permission");
                            }}
                        >
                            Permissions
                        </span>
                        {/* <img src={verticle} alt="" /> */}
                    </div>
                </div>
                <div className="d-flex justify-content-between role-searchbar-and-btns resposiveheadder role-searchbar-and-btns-responsive mb-4">
                    <div className="role-table-header reponsivesrc">
                        <div className="search-input-wrapper">
                            <input
                                type="text"
                                placeholder="Search Name / Role"
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                    // getAssignedUsers(e.target.value)
                                }}
                            />
                            <img src={searchicon} alt="" />
                        </div>
                        <div className="entries-wrapper">
                            <p>Entries per page</p>
                            <select
                                class="form-select select-drop-icon remove-border"
                                aria-label="Default select example"
                                onChange={(e) => {
                                    setUsersPerPage(e.target.value);
                                    setCurrentPage(0);
                                }}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                            </select>
                        </div>
                    </div>
                    <div className="role-btns-wrapper ">
                        {
                            permissions&&permissions['Role Assign Export']&&
                        <button>
                            {excelLoading ? (
                                <ExcelLoader />
                            ) : (
                                <img
                                    src={ic_excel}
                                    onClick={(e) => downloadExcelFile(e)}
                                    alt=""
                                />
                            )}
                        </button> 
                        }
                        <button>
                            <img src={importicon} alt="" />
                        </button>
                        {/* <button onClick={onDownload}>
                            {" "}
                            <img src={exporticon} alt="" />
                        </button> */}
                        <button onClick={handleShow}>
                            {" "}
                            <img
                                src={plus}
                                onClick={() => {
                                    setValidated(false);
                                }}
                                alt=""
                            />
                        </button>
                    </div>
                </div>
                <div className="table-wrapper table-wrapper-2">
                    {
                        loading ? <Loader /> :
                            (assignedUsers?.length > 0 ? (
                                <table>
                                    <tr className="tbl-row">
                                        <th id="sr-no-padding">Sr. No</th>
                                        <th>Name</th>
                                        <th>Assigned Role</th>
                                        <th>Action</th>
                                    </tr>
                                    {
                                        assignedUsers &&
                                        assignedUsers?.length > 0 &&
                                        assignedUsers?.slice(startIndex, endIndex).map((item, index) => {
                                            return (
                                                <tr class="table-row-custom">
                                                    <td class="paddingFirstRow">{startIndex + index + 1}</td>
                                                    <td>{item?.Name}</td>
                                                    <td>{item?.Roles}</td>
                                                    <td>
                                                        {
                                                            permissions&&permissions['Edit Role Assign']&&
                                                        <img
                                                            src={optionIcon}
                                                            onClick={() => {
                                                                setUserDetail({
                                                                    EmployeeId: 0,
                                                                    AssignedRoles: [],
                                                                });
                                                                setEdit(true)
                                                                getEditData(item);
                                                                setErrorMessage({
                                                                    userName: "",
                                                                    role: "",
                                                                });
                                                            }}
                                                            alt=""
                                                        />
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            ) : (
                                <NoData />
                            )
                            )
                    }

                    {/* // )} */}
                </div>
            </div>

            {
                !loading && (
                    <div className="row mt-4">
                        <div className="col-lg-12">
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={Math.ceil(assignedUsers?.length / usersPerPage)}
                                onPageChange={pageChangeHandler}
                                containerClassName={"paginationBttns"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                                forcePage={currentPage}
                            />
                        </div>
                    </div>
                )
            }

            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={handleClose}
            >
                <Modal.Header>
                    <Modal.Title>
                        <label htmlFor="" className="create-role-text">
                            Assign Role
                        </label>
                    </Modal.Title>
                    <img src={close} alt="" onClick={handleClose} className="cr-class" />
                </Modal.Header>
                <hr style={{ margin: "0" }} />
                <Form noValidate validated={validated} onSubmit={assignRoleHandler}>
                    <Modal.Body>
                        <div className="mb-3">
                            <label htmlFor="" className="enter-role-text">
                                User Name
                            </label>

                            <Select
                                onChange={(items) => {
                                    setUserDetail({
                                        ...userDetail,
                                        EmployeeId: items.value,
                                    });
                                    setErrorMessage({
                                        ...errMessage,
                                        userName: "",
                                    });
                                }}
                                options={userNames}
                                // className="multi-select-input-field"
                                // isMulti
                                isClearable
                                required
                            />
                            {errMessage.userName && (
                                <div className="text-danger error-msg">{errMessage.userName}</div>
                            )}
                        </div>
                        <div className="">
                            <label htmlFor="" className="enter-role-text">
                                Role
                            </label>
                            <Select
                                onChange={(items) => {
                                    console.log(items);
                                    setUserDetail({
                                        ...userDetail,
                                        AssignedRoles: Array.isArray(items)
                                            ? items.map((x) => x.value)
                                            : [],
                                    });
                                    setErrorMessage({
                                        ...errMessage,
                                        role: "",
                                    });
                                }}
                                options={roleList}
                                // className="multi-select-input-field"
                                isMulti
                                isClearable
                                required
                            />
                            {errMessage.role && (
                                <div className="text-danger error-msg">{errMessage.role}</div>
                            )}
                            <div className="invalid-feedback">Please select role</div>
                        </div>
                    </Modal.Body>
                    <div className="hr-line-modal-1">
                        <hr style={{ margin: "0" }} />
                    </div>
                    <Modal.Footer className="model-footer-arbaz-responsive">
                        <div >
                            <button
                                type="button"
                                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={btnLoader ? true : false}
                                type="submit"
                                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                            >
                                {
                                    btnLoader ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <ClipLoader color="#fff"
                                                size={18}
                                            />
                                        </div>
                                    ) : "Save"
                                }
                            </button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={edit}
                onHide={handleClosed}
            >
                <Modal.Header>
                    <Modal.Title>
                        <label htmlFor="" className="create-role-text">
                            Edit Assign Role
                        </label>
                    </Modal.Title>
                    <img src={close} alt="" onClick={handleClosed} className="cr-class" />
                </Modal.Header>
                <div className="hr-line-modal">
                    <hr style={{ margin: "0" }} />
                </div>
                <Form noValidate validated={validated} onSubmit={updateRoleHandler}>
                    <Modal.Body>
                        <div>
                            <label htmlFor="" className="enter-role-text">
                                User Name
                            </label>
                            <Select
                                value={{ label: userDetail.userName, value: userDetail.EmployeeId }}
                                options={userNames}
                                onChange={(e) => {
                                    console.log("e,", e);
                                    setUserDetail({
                                        ...userDetail,
                                        EmployeeId: e.value,
                                    });
                                    setUserNames([...userNames, { label: e.label, value: e.value }])
                                }}
                            />
                            {errMessage.userName && (
                                <div className="text-danger error-msg">
                                    {errMessage.userName}
                                </div>
                            )}
                        </div>
                        <div className="mt-3">
                            <label htmlFor="" className="enter-role-text">
                                Role
                            </label>
                            <Select
                                value={roleList.filter((obj) => {
                                    return userDetail.AssignedRoles.includes(obj.value)
                                }
                                )}

                                onChange={(e) => {
                                    setUserDetail({
                                        ...userDetail,
                                        AssignedRoles: Array.isArray(e)
                                            ? e.map((x) => x.value)
                                            : [],
                                    });
                                    setErrorMessage({
                                        role: "",
                                    });
                                }}
                                options={roleList}
                                className="multi-select-input-field"
                                isMulti
                                isClearable
                            />
                            {errMessage.role && (
                                <div className="text-danger error-msg">{errMessage.role}</div>
                            )}
                        </div>
                    </Modal.Body>
                    <div className="hr-line-modal-1">
                        <hr style={{ margin: "0" }} />
                    </div>
                    <Modal.Footer className="model-footer-arbaz-responsive">
                        <div >
                            <button
                                type="button"
                                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                                onClick={handleClosed}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={btnLoader ? true : false}
                                type="submit"
                                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                            >
                                {
                                    btnLoader ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <ClipLoader color="#fff"
                                                size={18}
                                            />
                                        </div>
                                    ) : "Update"
                                }
                            </button>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>

        </div>

    )
}

export default AssignRole
