import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import NoData from "../../sharedComponent/NoData";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import "../../assets/css/crmphonebook/Agent.scss";
import Pagination from "../../sharedComponent/Pagination";
import ic_excel from "../../assets/images/ic_excel.svg";
export default function AgentAssignedCalls() {
  const [usersPerPage, setUsersPerPage] = useState(5);
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const {} = useContext(AppContext);

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="main-chat">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Agent List's</h6>
          </div>

          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search "
                  // onChange={(e) => {
                  //   setSearchValue(e.target.value);
                  //   setCurrentPage(0);
                  // }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //   setUsersPerPage(e.target.value);
                  //   setCurrentPage(0);
                  // }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
            </div>

            <div className="import-export-btn-wrapper mt-4">
              <div className="reset mr-2">
                <p>Delete</p>
              </div>
              <button>
                {/* {excelLoading ? (
                  <ExcelLoader />
                ) : ( */}
                <img
                  src={ic_excel}
                  // onClick={(e) => downloadExcelFile(e)}
                  alt=""
                />
                {/* )} */}
              </button>
            </div>
          </div>

          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            <table>
              <thead>
                <tr className="firstTabelRow">
                  <th scope="col">Delete</th>
                  <th scope="col">Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Email</th>
                  <th scope="col">Assign Date</th>
                  <th scope="col">Assign For</th>
                  <th scope="col">View Details</th>
                </tr>
              </thead>
              <tbody>
                <tr className="table-row-custom">
                  <td colSpan={8}>
                    <NoData />
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination />
          </div>
        </div>
      </div>
    </>
  );
}
