import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Pagination from "../../sharedComponent/Pagination";
import React, { useContext, useEffect, useState } from "react";
import deleteicon from "../../assets/images/delete_icon_gray.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import edit from "../../assets/images/edit_officeCaller.svg";
import eye from "../../assets/images/ic_show_eyes_officecaller.svg";
import ic_excel from "../../assets/images/ic_excel.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import plus from "../../assets/images/plus_officecaller.svg";
import redbytes from "../../assets/images/custom-rb-logo.png";
import searchicon from "../../assets/images/ic_search.svg";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const Adminpanel = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  const agentdata = [
    {
      id: 1,
      App: "Dashboard Mangement",
      Type: "Reports and analysis Management",
      Domain: "EI",
      Category: "Average",
      Action: "Basic",
    },
    {
      id: 2,
      App: "Dashboard Mangement",
      Type: "Reports and analysis Management",
      Domain: "EI",
      Category: "Average",
      Action: "Basic",
    },
    {
      id: 3,
      App: "Dashboard Mangement",
      Type: "Reports and analysis Management",
      Domain: "EI",
      Category: "Average",
      Action: "Basic",
    },
    {
      id: 4,
      App: "Dashboard Mangement",
      Type: "Reports and analysis Management",
      Domain: "EI",
      Category: "Average",
      Action: "Basic",
    },
    {
      id: 5,
      App: "Dashboard Mangement",
      Type: "Reports and analysis Management",
      Domain: "EI",
      Category: "Average",
      Action: "Basic",
    },
    {
      id: 6,
      App: "Dashboard Mangement",
      Type: "Reports and analysis Management",
      Domain: "EI",
      Category: "Average",
      Action: "Basic",
    },
    {
      id: 7,
      App: "Dashboard Mangement",
      Type: "Reports and analysis Management",
      Domain: "EI",
      Category: "Average",
      Action: "Basic",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <div className="textHeader">
            <h6 className="mt-2">Admin panel</h6>
          </div>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
          <div className="role-table-header">
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search "
                // onChange={(e) => {
                //   setSearchValue(e.target.value);
                //   setCurrentPage(0);
                // }}
              />
              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                // onChange={(e) => {
                //   setUsersPerPage(e.target.value);
                //   setCurrentPage(0);
                // }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
              </select>
            </div>
          </div>

          <div className="import-export-btn-wrapper mt-4">
            <button>
              {/* {excelLoading ? (
                  <ExcelLoader />
                ) : ( */}
              <img
                src={ic_excel}
                // onClick={(e) => downloadExcelFile(e)}
                alt=""
              />
              {/* )} */}
            </button>
          </div>
        </div>
        <div className="registerbutton">
          <div></div>
          <div>
            <button className="sml-btn-sub-header">
              <Link to="/Modules" className="details-link">
                MODULES
              </Link>
            </button>
            <button className="sml-btn-sub-header">
              <Link to="/Features" className="details-link">
                FEATURES
              </Link>
            </button>
          </div>
        </div>

        <div id="crm-call-form">
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            <table>
              <thead>
                <tr className="firstTabelRow">
                  <th scope="col"> Sr.No.</th>
                  <th scope="col">Module</th>

                  <th scope="col">Feature</th>
                  <th scope="col">Functional Unit</th>
                  <th scope="col">Weighting Factor</th>
                  <th scope="col">Basic/Advanced</th>
                </tr>
              </thead>
              <tbody>
                {agentdata.map((item, index) => (
                  <tr key={index} className="table-row-custom">
                    <td>{item.id}</td>
                    <td>{item.App}</td>

                    <td> {item.Type}</td>
                    <td> {item.Domain}</td>
                    <td> {item.Category}</td>

                    <td>{item.Action}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
          // data={EmployeeList}
          // pageChangeHandler={pageChangeHandler}
          // usersPerPage={usersPerPage}
          // currentPage={currentPage}
          // searchValue={searchValue}
          // searchedDataPageCounts={searchedDataPageCounts}
          />
        </div>
      </div>
    </div>
  );
};

export default Adminpanel;
