import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Loader from "../Loader";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken, simpleGetCallWithErrorResponse
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const ProjectAssigned = () => {
  const { sidebar, setSidebar, Dark, setDark, userData,permissions } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [assignedData, setAssignedData] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(5);
  const [projName, setProjName] = useState([]);
  const [projectId, setProjectId] = useState("");

  const [queryData, setQueryData] = useState([]);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const [excelLoading, setExcelLoading] = useState(false);

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    simpleGetCallWithErrorResponse(ApiConfig.GET_PROJECTASSIGNED_EXPORT)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchedDataPageCounts =
    assignedData &&
    assignedData.length &&
    assignedData.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  const fetchAssignedData = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_PROJECTASSIGNED_LIST)
      .then((res) => {
        if (res.Success === true) {
          setLoading(false);
          setAssignedData(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchProjName = () => {
    simpleGetCallWithToken(ApiConfig.GET_PROJECT_LIST)
      .then((res) => {
        if (res.Success === true) {
          setProjName(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = () => {
    if (projectId !== 0) {
      fetchQueryData();
    } else {
      fetchAssignedData();
    }
  };

  const fetchQueryData = () => {
    let projId = projectId ? projectId : 0;
    simpleGetCallWithToken(
      ApiConfig.GET_PROJECTASSIGNED_LIST + `?projectid=${projId}`
    )
      .then((res) => {
        if (res.Success === true) {
          setAssignedData(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchProjName();
    fetchAssignedData();
  }, []);

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">List of Employee - Project Assigned</h6>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
             <div className="role-table-header">
                <div className="search-input-wrapper">
              <label htmlFor="" className="enter-role-text mt-2">
                Projects Name
              </label>
              <select
                class="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setProjectId(e.target.value);
                }}
                onClick={handleChange}
                name="projectId"
                value={projectId}
              >
                <option selected value="0">
                  Select Project Name
                </option>
                {projName.map((name, index) => {
                  return (
                    <option value={name.ProjectId}>{name.ProjectName}</option>
                  );
                })}
              </select>
            </div>
            <div className="entries-wrapper">
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                onChange={(e) => {
                  setUsersPerPage(e.target.value);
                  setCurrentPage(0);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
              </select>
            </div>
          </div>
          <div className="import-export-btn-wrapper mt-4">
            {
              permissions&&permissions['Project Assigned Export']&&
            <button>
              {excelLoading ? (
                <ExcelLoader />
              ) : (
                <img
                  src={ic_excel}
                  onClick={(e) => downloadExcelFile(e)}
                  alt=""
                />
              )}
            </button>
           }
            </div>
          </div>
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            {loading ? (
              <Loader />
            ) : assignedData && assignedData.length > 0 ? (
              <table>
                <thead>
                  <tr className="firstTabelRow">
                    <th>Sr.no</th>
                    <th>Employee Name</th>
                    <th>Project Name</th>
                    <th>Project Lead</th>
                    <th>Resource Start Date</th>
                    <th>Resource To Date</th>
                  </tr>
                </thead>
                <tbody>
                  {assignedData.slice(startIndex, endIndex).map((data, index) => {
                    return (
                      <tr className="table-row-custom">
                        <td>{startIndex + index + 1}</td>
                        <td>{data.EmployeeName}</td>
                        <td>{data.ProjectName}</td>
                        <td>{data.ProjectLead}</td>
                        <td>{data.ResourceFromDate}</td>
                        <td>{data.ResourceToDate}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        <Pagination
          data={assignedData}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
      </div>
    </>
  );
};

export default ProjectAssigned;
