import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import verticle from "../../assets/images/verticle_line.svg";
import Select from "react-select";
import { PostCallWithErrorResponse, simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import { notifyError, notifySuccess } from "../../toaster/notify";
import { ClipLoader } from "react-spinners";
import "../../assets/css/MasterData.scss"

const Permission = () => {
    const { sidebar, socket } = useContext(AppContext);
    const Route = useLocation().pathname;
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(false)
    const [permissions, setPermissions] = useState([]);
    const [permissionData, setPermissionData] = useState({
        role: 0,
        permissionsList: [],
        RoleName:""
    });
    console.log("permissionData", permissionData);

    const [roleDropdownList, setRoleDropdownList] = useState([]);
    const [RoleNameEmit, setRoleName] = useState("");


    const sortedOptions = roleDropdownList.slice().sort((a, b) => a.label.localeCompare(b.label));
    console.log("sortedOptions", sortedOptions);

    useEffect(() => {
        getAllPermissionList();
        getUserRoleList();
    }, []);

    const getAllPermissionList = async () => {
        try {
            setLoading(true);
            const res = await simpleGetCallWithToken(ApiConfig.GET_ALL_PERMISSIONS);
            console.log("first permission,", res);
            if (res.Success === true) { 
                setPermissions(res.Data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.log("catch error")
        } finally {
            setLoading(false);
        }
       
    };

    const getPermissionsByRoleID = async (roleId) => {

        try {
            setLoading(true);
            const res = await simpleGetCallWithToken(
                ApiConfig.GER_PERMISSIONS_BY_ROLEID + `/${roleId}`
            );
            if (res.Success) {
                setPermissions(res.Data);
                console.log(res.Data);
                setLoading(false);
                let roles = [];
                res.Data.map((category) => {
                    category.Permissions.map((role) => {
                        if (role.IsSelected === true) {
                            roles.push(role.PermissionId);
                        }

                        if(role?.SubPermissions?.length>0){

                            role?.SubPermissions?.map((perm)=>{

                                if(perm?.IsSelected){

                                    roles.push(perm?.PermissionId)

                                }

                            })

                        }
                    })
                }
                );
                setPermissionData({
                    ...permissionData,
                    role: res.Data[0].RoleId,
                    permissionsList: roles,
                    role:roleId
                    
                });
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.log("error respinse,", error);
        }
    };

    const getUserRoleList = async () => {
        try {
            const res = await simpleGetCallWithToken(ApiConfig.GET_ALL_ROLE_NAMES);
            console.log("res=", res);
            if (res.Success === true) {
                let roleDropdownArr = res.Data && res.Data.map((item) => {
                    return {
                        label: item.Name,
                        value: item.RoleId,
                
                    }
                })
                setRoleDropdownList(roleDropdownArr);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const [loader, setLoader] = useState(false)
    const updatePermissions = () => {

        console.log(permissionData);

        if (permissionData.role === 0) {
            notifyError("Please select role name");
        } else {
            setLoader(true);
            PostCallWithErrorResponse(
                ApiConfig.UPDATE_PERMISSIONS,
                JSON.stringify(permissionData)
            )
                .then((res) => {
                    notifySuccess("Permissions assigned successfully");
                    setLoader(false);
                    socket.emit("roleAccess-update", {
                        RoleId: permissionData.role,
                        RoleName: RoleNameEmit,
                    
                      });
                })
                .catch((err) => console.log(err));
        }
    };
    
    const IsSelected=(module)=>{


        let selected=[]

        module?.Permissions?.forEach((per)=>{

        

            if(per?.SubPermissions.length>0){


                per?.SubPermissions.forEach((i)=>{
                    selected.push(i?.PermissionId)
                })

            }else selected.push(per?.PermissionId)

        })


        return selected?.every((i)=>permissionData?.permissionsList.includes(i))


    }

   
    return (
        <div
            className={sidebar ? "taskMain " : "cx-active taskMain"}
            id="cx-main"
        >
            <div className="role-content mb-4">
                <div className="sub-header-role sub-header-role-arbaz-responsive ">
                    <span
                        onClick={() => {
                            navigate("/Role");
                        }}
                    >
                        <h6 className="mt-2 role-text">Role</h6>
                    </span>
                    <div className="span-wrapper-sub-header">
                        <span
                            onClick={() => {
                                navigate("/Role");
                            }}
                            className={
                                Route === "/Role"
                                    ? "sub-header-span-active"
                                    : "sub-header-span"
                            }
                        >
                            Create
                        </span>
                        <img src={verticle} alt="" style={{ marginRight: "20px" }} />
                        <span
                            className={
                                Route === "/AssignRole"
                                    ? "sub-header-span-active"
                                    : "sub-header-span"
                            }
                            style={{ marginRight: "20px" }}
                            onClick={() => {
                                navigate("/AssignRole");
                            }}
                        >
                            Assign
                        </span>
                        <img src={verticle} alt="" style={{ marginRight: "20px" }} />
                        <span
                            className={
                                Route === "/Permission"
                                    ? "sub-header-span-active"
                                    : "sub-header-span"
                            }
                            onClick={() => {
                                navigate("/Permission");
                            }}
                        >
                            Permissions
                        </span>
                        {/* <img src={verticle} alt="" /> */}
                    </div>
                </div>
            </div>
            <div className="permission-content-wrapper">
                <div className="row mb-4">
                    <div className="col-lg-6">
                        <label htmlFor="" className="label-text">
                            Role Names
                        </label>
                        <Select
                            options={sortedOptions}
                            onChange={(roleData) => {
                                setPermissionData({
                                    role: roleData.value,
                                    permissionsList: [],
                                    RoleName:roleData.label
                                });
                                setRoleName(roleData.label)
                                setPermissions([]);
                                getPermissionsByRoleID(roleData.value);
                            }}
                        />
                        {console.log("Role data on change --------", setPermissionData)}
                        {/* <select
                            class="form-select"
                            // select-drop-icon (this is commented becouse it is removing the drop down icon of the select box)
                            aria-label="Default select example"
                        >
                            <option selected>Select Role</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select> */}

                    </div>
                </div>

                 {
                    loading ? <Loader /> : (
                        permissions && permissions.length > 0 && permissions.map((item, index) => {
                            
                            return (
                                <>
                                    <div className="permission-heading mt-3">
                                        <label htmlFor="User" >{item?.CategoryName}</label>
                                        <input
                                            checked={IsSelected(item)}
                                            type="checkbox"
                                            id="Users" className="form-check-input ms-4"
                                            onChange={(e) => {

                                                let selected = []

                                                item?.Permissions?.forEach((i)=>{

                                                    if(i?.SubPermissions){

                                                        selected.push(i?.PermissionId)

                                                        i.SubPermissions.forEach((sub)=>{

                                                            selected.push(sub?.PermissionId)

                                                        })

                                                    }

                                                })



                                                setPermissionData({
                                                    ...permissionData,
                                                    permissionsList: e.target.checked
                                                         ? [
                                                             ...permissionData?.permissionsList,
                                                             ...selected
                                                         ]
                                                         : permissionData.permissionsList.filter(
                                                                (per) => selected.indexOf(per)===-1
                                                         ),
                                                })

                                             }}
                                        />
                                    </div>
                                    <div style={{ marginTop: "0px" }}>
                                        <hr style={{ margin: "0px",border:'2px solid grey' }} />
                                    </div>
                                    <div className="row ">
                                        {
                                            item.Permissions &&
                                            item.Permissions.length > 0 &&
                                            item.Permissions.map((innerItem) => {
                                                return (
                                                    <>
                                                   
                                                    <div className="col-lg-4 checkbox-wrapper">
                                                        <input
                                                            className="form-check-input "
                                                            type="checkbox"
                                                            name="checkbox1"
                                                            id="checkbox1"
                                                            checked={
                                                                innerItem?.SubPermissions?.length===0?permissionData?.permissionsList?.includes(innerItem?.PermissionId):
                                                                innerItem?.SubPermissions?.every((i)=>permissionData?.permissionsList.includes(i.PermissionId))}
                                                            // checked={innerItem.IsSelected}
                                                            onChange={(e) => {

                                                                let select =[]

                                                                if( innerItem?.SubPermissions?.length===0){
                                                                     select.push(innerItem?.PermissionId)
                                                                }else{

                                                                select =innerItem?.SubPermissions?.map((i)=>i.PermissionId)   
                                                                }    
                                                                
                                                                
                                                                setPermissionData({
                                                                    ...permissionData,
                                                                    permissionsList: e.target.checked
                                                                        ? [
                                                                            ...permissionData.permissionsList,
                                                                            ...select
                                                                        ]
                                                                        : permissionData.permissionsList.filter(
                                                                            (per) => select.indexOf(per)===-1
                                                                        ),
                                                                });
                                                            }}
                                                        />
                                                        <label for="checkbox" className="terms-text-1 ms-2 "
                                                        style={innerItem?.SubPermissions?.length>0?{fontWeight:"bold"}:null}
                                                        >
                                                            {innerItem?.PermissionName}{" "}
                                                        </label>
                                                    </div>
                                                    
                                                    {
                                                        innerItem?.SubPermissions?.length>0&&
                                                    <div className="col-lg-12 row mb-2 ml-4 ">
                                                            {
                                                                innerItem?.SubPermissions?.length>0&&innerItem.SubPermissions.map((per)=>{
                                                                    return <div className="col-lg-4 checkbox-wrapper">
                                                                    <input
                                                                        className="form-check-input "
                                                                        type="checkbox"
                                                                        name="checkbox1"
                                                                        id="checkbox1"
                                                                        checked={permissionData?.permissionsList
                                                                            ?.includes(
                                                                                per.PermissionId  
                                                                            )}

                                                                            onChange={(e)=>{

                                                                                

                                                                                setPermissionData({
                                                                                    ...permissionData,
                                                                                    permissionsList: e.target.checked
                                                                                        ? [
                                                                                            ...permissionData.permissionsList,
                                                                                            per?.PermissionId
                                                                                        ]
                                                                                        : permissionData.permissionsList.filter(
                                                                                            (perm) => perm != per.PermissionId
                                                                                        ),
                                                                                });
                                                                            }}
                                                                        
                                                                    />
                                                                    <label for="checkbox" className="terms-text-1 ms-2">
                                                                        {per?.PermissionName}{" "}
                                                                    </label>
                                                                    </div>
                                                                })
                                                            }
                                                            <div style={{ marginTop: "5px" }}>
                                                               <hr style={{ margin: "0px" }} />
                                                            </div>
                                                        </div>
                                                    }
                                                    
                                                    </>
                                                )
                                            })
                                        }

                                        {/* <div className="col-lg-4 checkbox-wrapper">
                                            <input
                                                className="form-check-input "
                                                type="checkbox"
                                                name="checkbox2"
                                                id="checkbox2"
                                            />
                                            <label for="checkbox2" className="terms-text-1 ms-2">
                                                Edit Contact Details{" "}
                                            </label>
                                        </div>
                                        <div className="col-lg-4 checkbox-wrapper">
                                            <input
                                                className="form-check-input "
                                                type="checkbox"
                                                name="checkbox3"
                                                id="checkbox3"
                                            />
                                            <label for="checkbox3" className="terms-text-1 ms-2">
                                                Edit Professional Details{" "}
                                            </label>
                                        </div> */}
                                    </div>
                                </>
                            )
                        })
                    ) 
                }
                


                {/* <div className="row mb-2">
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            className="form-check-input "
                            type="checkbox"
                            name="checkbox4"
                            id="checkbox4"
                        />
                        <label for="checkbox4" className="terms-text-1 ms-2">
                            Manage Designation{" "}
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            className="form-check-input "
                            type="checkbox"
                            name="checkbox5"
                            id="checkbox5"
                        />
                        <label for="checkbox5" className="terms-text-1 ms-2">
                            Activate / Deactive User{" "}
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            className="form-check-input "
                            type="checkbox"
                            name="checkbox6"
                            id="checkbox6"
                        />
                        <label for="checkbox6" className="terms-text-1 ms-2">
                            View User List{" "}
                        </label>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            className="form-check-input "
                            type="checkbox"
                            name="checkbox7"
                            id="checkbox7"
                        />
                        <label for="checkbox7" className="terms-text-1 ms-2">
                            Active User Stats{" "}
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox8"
                            id="checkbox8"
                            className="form-check-input "
                        />
                        <label for="checkbox8" className="terms-text-1  ms-2">
                            {slideOpen
                                ? "User/ Guest / Consultant"
                                : "User/ Guest / Consultant/ Enrollment"}
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox9"
                            id="checkbox9"
                            className="form-check-input "
                        />
                        <label for="checkbox9" className="terms-text-1  ms-2">
                            Enrollment Approval
                        </label>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox10"
                            id="checkbox10"
                            className="form-check-input "
                        />
                        <label for="checkbox10" className="terms-text-1  ms-2">
                            Organization Chart
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox11"
                            id="checkbox11"
                            className="form-check-input "
                        />
                        <label for="checkbox11" className="terms-text-1  ms-2">
                            Add User
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox12"
                            id="checkbox12"
                            className="form-check-input "
                        />
                        <label for="checkbox12" className="terms-text-1  ms-2">
                            View User Profile
                        </label>
                    </div>
                </div> */}


                {/* 
                <div className="permission-heading mb-3">
                    <label htmlFor="Attendance">Attendance</label>
                    <input type="checkbox" id="Attendance" className="ms-4 form-check-input" />
                </div>
                <div style={{ marginTop: "10px" }}>
                    <hr style={{ margin: "0px" }} />
                </div>
                <div className="row mb-2">
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox13"
                            id="checkbox13"
                            className="form-check-input "
                        />
                        <label for="checkbox13" className="terms-text-1  ms-2">
                            My Attendance
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox14"
                            id="checkbox14"
                            className="form-check-input "
                        />
                        <label for="checkbox14" className="terms-text-1  ms-2">
                            Attendance Register
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox15"
                            id="checkbox15"
                            className="form-check-input "
                        />
                        <label for="checkbox15" className="terms-text-1  ms-2">
                            Attendance Report
                        </label>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox16"
                            id="checkbox16"
                            className="form-check-input "
                        />
                        <label for="checkbox16" className="terms-text-1  ms-2">
                            Device Punch History
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox17"
                            id="checkbox17"
                            className="form-check-input "
                        />
                        <label for="checkbox17" className="terms-text-1  ms-2">
                            View Subordinates Attendance
                        </label>
                    </div>
                </div>

                <div className="permission-heading mb-3">
                    <label htmlFor="Calendar">Calendar & Holiday</label>
                    <input type="checkbox" id="Calendar" className="ms-4 form-check-input" />
                </div>
                <hr style={{ margin: "0px" }} />

                <div className="row mb-2">
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox18"
                            id="checkbox18"
                            className="form-check-input "
                        />
                        <label for="checkbox18" className="terms-text-1  ms-2">
                            View Calendar Setting
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox19"
                            id="checkbox19"
                            className="form-check-input "
                        />
                        <label for="checkbox19" className="terms-text-1  ms-2">
                            Update Calendar Setting
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox20"
                            id="checkbox20"
                            className="form-check-input "
                        />
                        <label for="checkbox20" className="terms-text-1  ms-2">
                            View Holiday
                        </label>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox21"
                            id="checkbox21"
                            className="form-check-input "
                        />
                        <label for="checkbox21" className="terms-text-1  ms-2">
                            Add Holiday
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox22"
                            id="checkbox22"
                            className="form-check-input "
                        />
                        <label for="checkbox22" className="terms-text-1  ms-2">
                            Update Holiday
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox23"
                            id="checkbox23"
                            className="form-check-input "
                        />
                        <label for="checkbox23" className="terms-text-1  ms-2">
                            Delete Holiday
                        </label>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox24"
                            id="checkbox24"
                            className="form-check-input "
                        />
                        <label for="checkbox24" className="terms-text-1  ms-2">
                            View Shift
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox25"
                            id="checkbox25"
                            className="form-check-input "
                        />
                        <label for="checkbox25" className="terms-text-1  ms-2">
                            Add Shift
                        </label>
                    </div>
                    <div className="col-lg-4 checkbox-wrapper">
                        <input
                            type="checkbox"
                            name="checkbox26"
                            id="checkbox26"
                            className="form-check-input "
                        />
                        <label for="checkbox26" className="terms-text-1  ms-2">
                            Update Shift
                        </label>
                    </div>
                </div> */}

                <hr style={{ margin: "0px" }} className="mb-3" />
                <div className="text-end">
                    {/* <button type="button" className="cancel-btn me-3">Cancel</button> */}

                    <button
                        onClick={updatePermissions}
                        className="save-btn-permission save-btn-permission-arbaz-responsive punchInButton"
                        disabled={loader ? true : false}
                    >
                        {loader ? (<div className="d-flex justify-content-center align-items-center">
                            <ClipLoader color="#fff"
                                size={18}
                            />
                        </div>) : "Save"}
                    </button>
                </div>


            </div>

        </div>
    )
}

export default Permission;
