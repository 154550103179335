import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import placeholder_img from "../../assets/images/placeholder_img.svg";
import user_icon_1 from "../../assets/images/user_icon_1.svg";
import user_icon_2 from "../../assets/images/user_icon_2.svg";
import user_icon_3 from "../../assets/images/user_icon_3.svg";
import user_icon_4 from "../../assets/images/user_icon_4.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import { getDateDashDDMMYYYY } from "../../common";
import Beatloader from "../../sharedComponent/Beatloader";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";

const MyTask = ({ taskList, pendingTask, taskLoader, ProgressData, completedData }) => {

  const uniqueLabels = [...new Set(taskList.map(task => task.label))];
  const labels = ["Project Name", "Task Name", "Start Date", "End Date"];
  const labels2 = ["Project Name", "Task Name", "Start Date", "End Date", "Budget Hours", "Effort Hours"];
  const labels3 = ["Project Name", "Task Name", "Start Date", "End Date", "Status"];


  return (
    <>


      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <Tab.Content
          style={{
            height: "calc(150vh - 400px)",
            overflowY: "auto"
          }}
        >
          <Tab.Pane eventKey="1" style={{ marginRight: "10px" }}>
            <div className="project-list-wrapper">
              <div className="project-list mt-2">
                {/* <div className="d-flex"> */}
                {/* Render table headers for each unique label */}
                {labels.map((label, index) => (
                  <div key={index} className="label d-flex mx-5 fw-bold innerLab"
                    style={{ fontSize: "14px" }}
                  >
                    {label}
                  </div>
                ))}
                {/* </div> */}
              </div>
              {
                taskLoader ? (
                  <Beatloader />
                ) : (taskList && taskList.length > 0 ? (taskList.map((task, i) => {
                  return (
                    <div className="project-list mt-2"
                    >
                      <div className="project-title-wrapper">
                        {uniqueLabels.map((label, index) => (
                          <div key={index}></div>
                        ))}
                      </div>
                      <div className="project-start-date">
                        {/* <div className="label">Project Name</div> */}
                        <div className="date">{task.ProjectName}</div>
                      </div>
                      <div className="project-start-date">
                        {/* <div className="label">Task Name</div> */}
                        <div className="date">{task.TaskName}</div>
                      </div>
                      <div className="project-start-date">
                        {/* <div className="label">Start Date</div> */}
                        <div className="date">{getDateDashDDMMYYYY(task.StartDate)}</div>
                      </div>
                      <div className="project-start-date">
                        {/* <div className="label">End Date</div> */}
                        <div className="date">{getDateDashDDMMYYYY(task.EndDate)}</div>
                      </div>
                    </div>
                  )
                })) : (
                  <div
                    style={{
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    No Task Assigned
                  </div>
                ))
              }

              {/* <div className="project-list mt-2">
                <div className="project-img">
                  <img src={placeholder_img} alt="" />
                </div>
                <div className="project-title-wrapper">
                  <div className="project-title">
                    <h5>Project Name</h5>
                    <p>Client Name</p>
                  </div>
                </div>
                 <div className="project-team-img">
                  <img src={user_icon_4} alt="" />
                  <img src={user_icon_3} alt="" />
                  <img src={user_icon_2} alt="" />
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_4} alt="" />
                </div>
                <div className="project-start-date">
                  <div className="date">22-11-2022</div>
                  <div className="label">Start Date</div>
                </div>
              </div>
              <div className="project-list mt-2">
                <div className="project-img">
                  <img src={placeholder_img} alt="" />
                </div>
                <div className="project-title-wrapper">
                  <div className="project-title">
                    <h5>Project Name</h5>
                    <p>Client Name</p>
                  </div>
                </div>
                 <div className="project-team-img">
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_2} alt="" />
                  <img src={user_icon_3} alt="" />
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_4} alt="" />
                </div>
                <div className="project-start-date">
                  <div className="date">22-11-2022</div>
                  <div className="label">Start Date</div>
                </div>
              </div>
              <div className="project-list mt-2">
                <div className="project-img">
                  <img src={placeholder_img} alt="" />
                </div>
                <div className="project-title-wrapper">
                  <div className="project-title">
                    <h5>Project Name</h5>
                    <p>Client Name</p>
                  </div>
                </div>
                 <div className="project-team-img">
                  <img src={user_icon_3} alt="" />
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_2} alt="" />
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_4} alt="" />
                </div>
                <div className="project-start-date">
                  <div className="date">22-11-2022</div>
                  <div className="label">Start Date</div>
                </div>
              </div> */}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="2">
            <div className="project-list-wrapper">
              <div className="project-list diffProjectList">
                {labels2.map((label, index) => (
                  <div key={index} className="label d-flex mx-5 fw-bold innerLab"
                    style={{ fontSize: "14px" }}
                  >
                    {label}
                  </div>
                ))}
              </div>
              {
                ProgressData && ProgressData.length > 0 ?
                  ProgressData.map((data, index) => {
                    return (
                      <>
                        <div className="project-list mt-2 diffProjectList text-center" key={index}>
                          {/* <div className="project-title-wrapper">
                        </div> */}
                          <div className="project-start-date">
                            <div className="date">{data.ProjectName}</div>
                          </div>
                          <div className="project-start-date">
                            <div className="date">{data.TaskName}</div>
                          </div>
                          <div className="project-start-date">
                            <div className="date">{getDateDashDDMMYYYY(data.StartDate)}</div>
                          </div>
                          <div className="project-start-date">
                            <div className="date">{getDateDashDDMMYYYY(data.EndDate)}</div>
                          </div>
                          <div className="project-start-date">
                            <div className="date">{data.BudgetHours}</div>
                          </div>
                          <div className="project-start-date">
                            <div className="date">{data.TotalWorkHour}</div>
                          </div>
                        </div>
                      </>
                    )
                  }) :
                  <div
                    style={{
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    No Task In Progress
                  </div>
              }
              {/* <div className="project-list mt-2">
                <div className="project-img">
                  <img src={placeholder_img} alt="" />
                </div>
                <div className="project-title-wrapper">
                  <div className="project-title">
                    <h5>Project Name</h5>
                    <p>Client Name</p>
                  </div>
                </div>
                <div className="project-team-img">
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_2} alt="" />
                  <img src={user_icon_3} alt="" />
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_4} alt="" />
                </div>
                <div className="project-progress-bar">
                  <div className="label">Progress</div>
                  <ProgressBar now={30} />
                </div>
              </div>
              <div className="project-list mt-2">
                <div className="project-img">
                  <img src={placeholder_img} alt="" />
                </div>
                <div className="project-title-wrapper">
                  <div className="project-title">
                    <h5>Project Name</h5>
                    <p>Client Name</p>
                  </div>
                </div>
                <div className="project-team-img">
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_2} alt="" />
                  <img src={user_icon_3} alt="" />
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_4} alt="" />
                </div>
                <div className="project-progress-bar">
                  <div className="label">Progress</div>
                  <ProgressBar now={80} />
                </div>
              </div>
              <div className="project-list mt-2">
                <div className="project-img">
                  <img src={placeholder_img} alt="" />
                </div>
                <div className="project-title-wrapper">
                  <div className="project-title">
                    <h5>Project Name</h5>
                    <p>Client Name</p>
                  </div>
                </div>
                <div className="project-team-img">
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_2} alt="" />
                  <img src={user_icon_3} alt="" />
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_4} alt="" />
                </div>
                <div className="project-progress-bar">
                  <div className="label">Progress</div>
                  <ProgressBar now={50} />
                </div>
              </div> */}
            </div>
          </Tab.Pane >
          <Tab.Pane eventKey="3">
            <div className="project-list-wrapper">
              <div className="project-list ">
                {labels3.map((label, index) => (
                  <div key={index} className="label d-flex mx-5 fw-bold innerLab"
                    style={{ fontSize: "14px" }}
                  >
                    {label}
                  </div>
                ))}
              </div>
              {
                pendingTask && pendingTask.length > 0 ? (
                  pendingTask.map((task, i) => {
                    return (
                      <div className="project-list mt-2 text-center">
                        {/* <div className="project-img">
                    <img src={placeholder_img} alt="" />
                  </div> */}
                        <div className="project-title-wrapper">
                          <div className="project-title">
                            <p>{task.ProjectName}</p>
                          </div>
                        </div>
                        <div className="project-start-date">
                          <div className="date">{task.TaskName}</div>
                        </div>
                        <div className="project-title-wrapper">
                          <div className="project-title">
                            <p>{moment(task.StartDate).format("DD-MM-YYYY")}</p>
                          </div>
                        </div>
                        <div className="project-title-wrapper">
                          <div className="project-title">
                            <p>{moment(task.EndDate).format("DD-MM-YYYY")}</p>
                          </div>
                        </div>
                        <div className="project-status">
                          <div className="label status-yellow">Waiting for Approval</div>
                        </div>
                      </div>
                    )

                  })
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    No Task Pending
                  </div>
                )
              }


              {/* <div className="project-list mt-2">
                <div className="project-img">
                  <img src={placeholder_img} alt="" />
                </div>
                <div className="project-title-wrapper">
                  <div className="project-title">
                    <h5>Project Name</h5>
                    <p>Client Name</p>
                  </div>
                </div>
                <div className="project-team-img">
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_2} alt="" />
                  <img src={user_icon_3} alt="" />
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_4} alt="" />
                </div>
                <div className="project-status">
                  <div className="label status-green">Approved</div>
                </div>
              </div>
              <div className="project-list mt-2">
                <div className="project-img">
                  <img src={placeholder_img} alt="" />
                </div>
                <div className="project-title-wrapper">
                  <div className="project-title">
                    <h5>Project Name</h5>
                    <p>Client Name</p>
                  </div>
                </div>
                <div className="project-team-img">
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_2} alt="" />
                  <img src={user_icon_3} alt="" />
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_4} alt="" />
                </div>
                <div className="project-status">
                  <div className="label status-red">Declined</div>
                </div>
              </div>
              <div className="project-list mt-2">
                <div className="project-img">
                  <img src={placeholder_img} alt="" />
                </div>
                <div className="project-title-wrapper">
                  <div className="project-title">
                    <h5>Project Name</h5>
                    <p>Client Name</p>
                  </div>
                </div>
                <div className="project-team-img">
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_2} alt="" />
                  <img src={user_icon_3} alt="" />
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_4} alt="" />
                </div>
                <div className="project-status">
                  <div className="label status-yellow">Waiting for Approval</div>
                </div>
              </div> */}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="4">
            <div className="project-list diffProjectList">
              {labels2.map((label, index) => (
                <div key={index} className="label d-flex mx-5 fw-bold innerLab"
                  style={{ fontSize: "14px" }}
                >
                  {label}
                </div>
              ))}
            </div>
            {
              completedData && completedData.length > 0 ?
                <>
                  {
                    completedData.map((data) => {
                      return (
                        <div className="project-list mt-2 text-center diffProjectList">
                          {/* <div className="project-img">
                            <img src={placeholder_img} alt="" />
                          </div> */}
                          {/* <div className="project-title-wrapper">
                            <div className="project-title">
                              <h5>{data.ProjectName}</h5>
                              <p>{data.TaskName}</p>
                            </div>
                          </div>
                          <div className="project-team-img">
                            <img src={user_icon_1} alt="" />
                            <img src={user_icon_2} alt="" />
                            <img src={user_icon_3} alt="" />
                            <img src={user_icon_1} alt="" />
                            <img src={user_icon_4} alt="" />
                          </div>
                          <div className="project-status">
                            <div className="label status-yellow">On Hold</div>
                          </div> */}
                          <div className="project-start-date">
                            <div className="date">{data.ProjectName}</div>
                          </div>
                          <div className="project-start-date">
                            <div className="date">{data.TaskName}</div>
                          </div>
                          <div className="project-start-date">
                            <div className="date">{getDateDashDDMMYYYY(data.StartDate)}</div>
                          </div>
                          <div className="project-start-date">
                            <div className="date">{getDateDashDDMMYYYY(data.EndDate)}</div>
                          </div>
                          <div className="project-start-date">
                            <div className="date">{data.BudgetHours}</div>
                          </div>
                          <div className="project-start-date">
                            <div className="date">{data.TotalWorkHour}</div>
                          </div>
                        </div>
                      )
                    })
                  }
                </> :
                <div
                  style={{
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  No Task In Completed List
                </div>
            }
            <div className="project-list-wrapper">
              {/* <div className="project-list mt-2">
                <div className="project-img">
                  <img src={placeholder_img} alt="" />
                </div>
                <div className="project-title-wrapper">
                  <div className="project-title">
                    <h5>Project Name</h5>
                    <p>Client Name</p>
                  </div>
                </div>
                <div className="project-team-img">
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_2} alt="" />
                  <img src={user_icon_3} alt="" />
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_4} alt="" />
                </div>
                <div className="project-status">
                  <div className="label status-green">On Time</div>
                </div>
              </div>
              <div className="project-list mt-2">
                <div className="project-img">
                  <img src={placeholder_img} alt="" />
                </div>
                <div className="project-title-wrapper">
                  <div className="project-title">
                    <h5>Project Name</h5>
                    <p>Client Name</p>
                  </div>
                </div>
                <div className="project-team-img">
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_2} alt="" />
                  <img src={user_icon_3} alt="" />
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_4} alt="" />
                </div>
                <div className="project-status">
                  <div className="label status-red">Declined</div>
                </div>
              </div>
              <div className="project-list mt-2">
                <div className="project-img">
                  <img src={placeholder_img} alt="" />
                </div>
                <div className="project-title-wrapper">
                  <div className="project-title">
                    <h5>Project Name</h5>
                    <p>Client Name</p>
                  </div>
                </div>
                <div className="project-team-img">
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_2} alt="" />
                  <img src={user_icon_3} alt="" />
                  <img src={user_icon_1} alt="" />
                  <img src={user_icon_4} alt="" />
                </div>
                <div className="project-status">
                  <div className="label status-red">Delayed</div>
                </div>
              </div> */}
            </div>
          </Tab.Pane>
        </Tab.Content >
      </div >
    </>
  );
};

export default MyTask;
