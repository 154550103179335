import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import Modal from "react-bootstrap/Modal";
//import DelectAc from "../../assets/images/DelectAc.svg";
import DelectAc from "../../assets/images/DelectAcBlc.svg";
//import Editac from "../../assets/images/Editac.svg";
import Editac from "../../assets/images/bx_edit.svg";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Pagination from "../../sharedComponent/Pagination";
import Multiselect from "multiselect-react-dropdown";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const ClientManagement = () => {
  const { sidebar, setSidebar, Dark, setDark, userData,permissions } =
    useContext(AppContext);
  //Confirmation popup
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [useDeleteTaskList, setDeleteTaskList] = useState("");

  const [excelLoading, setExcelLoading] = useState(false);

  const [clientList, setClientList] = useState([]);
  console.log("clientList", clientList);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseEdit = () => setEdit(false);
  const handleShow = () => setShow(true);
  const [projectlist, setProjectlist] = useState([]);
  const [clientData, setClientData] = useState({
    ClientID: 0,
    CustomerName: "",
    ClientLocation: "",
    ClientNo: "",
    ClientEmail: "",
    EntryDate: "",
    EntryBy: 0,
    OrgId: 0,
  });

  console.log("clientData===>>>>,", clientData);

  const [clientErr, setClientErr] = useState({
    CustomerNameErr: "",
    ClientLocationErr: "",
    ClientNoErr: "",
    ClientEmailErr: "",
    ClientNameSelectErr: "",
  });
  const [ClientId, setClientId] = useState(0);
  const changeData = (e) => {
    const { name, value } = e.target;
    setClientData({
      ...clientData,
      [name]: value,
    });
  };

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    simpleGetCallWithErrorResponse(ApiConfig.CLIENTMANG_DOWNLOAD_EXCEL)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchClientData = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_CLIENT_MANAG_LIST)
      .then((res) => {
        if (res) {
          setLoading(false);
          setClientList(res?.Data);
        }
      })
      .catch((err) => {
        console.log("catch error,", err);
      });
  };

  const addClientData = () => {
    if (clientData.CustomerName === "") {
      setClientErr({
        ...clientErr,
        CustomerNameErr: "Please Enter Client Name",
      });
      return;
    }
    if (clientData.ClientLocation === "") {
      setClientErr({
        ...clientErr,
        ClientLocationErr: "Please Enter Client Location",
      });
      return;
    }
    if (clientData.ClientNo === "") {
      setClientErr({
        ...clientErr,
        ClientNoErr: "Please Enter Client Number",
      });
      return;
    }
    if (clientData.ClientEmail === "") {
      setClientErr({
        ...clientErr,
        ClientEmailErr: "Please Enter Client Email",
      });
      return;
    }
    let payload = {
      ClientID: 0,
      CustomerName: clientData?.CustomerName,
      ClientLocation: clientData?.ClientLocation,
      ClientNo: clientData?.ClientNo,
      ClientEmail: clientData?.ClientEmail,
    };
    PostCallWithErrorResponse(
      ApiConfig.ADD_CLIENT_DATA,
      JSON.stringify(payload)
    ).then((res) => {
      let Status = res?.response?.status;
      if (Status === 200) {
        setShow(false);
        ToastMsg("success", res.json.Message);
        fetchClientData();
      }
    });
  };

  const getClientData = (id) => {
    simpleGetCallWithToken(ApiConfig.GET_UPDATE_CLIENT_DATA + `?id=${id}`)
      .then((res) => {
        let data = res?.Data;
        setClientData({
          ...clientData,
          CustomerName: data?.CustomerName,
          ClientLocation: data?.ClientLocation,
          ClientEmail: data?.ClientEmail,
          ClientNo: data?.ClientNo,
          ClientSelectName: data?.Projects,
        });
      })
      .catch((err) => {
        console.error("Catch error,", err);
      });
  };

  const editClientData = () => {
    if (clientData.CustomerName === "" || clientData.CustomerName == null) {
      setClientErr({
        ...clientErr,
        CustomerNameErr: "Please Enter Client Name",
      });
      return;
    }
    if (clientData.ClientLocation === "" || clientData.ClientLocation == null) {
      setClientErr({
        ...clientErr,
        ClientLocationErr: "Please Enter Client Location",
      });
      return;
    }
    if (clientData.ClientNo === "" || clientData.ClientNo == null) {
      setClientErr({
        ...clientErr,
        ClientNoErr: "Please Enter Client Number",
      });
      return;
    }
    if (clientData.ClientEmail === "" || clientData.ClientEmail == null) {
      setClientErr({
        ...clientErr,
        ClientEmailErr: "Please Enter Client Email",
      });
      return;
    }
    let editPayload = {
      ClientID: ClientId,
      CustomerName: clientData?.CustomerName,
      ClientLocation: clientData?.ClientLocation,
      ClientNo: clientData?.ClientNo,
      ClientEmail: clientData?.ClientEmail,
    };
    PostCallWithErrorResponse(
      ApiConfig.EDIT_CLIENT_DATA,
      JSON.stringify(editPayload)
    ).then((res) => {
      if (res.response.status === 200) {
        ToastMsg("success", res.json.Message);
        setEdit(false);
        fetchClientData();
        setClientData({});
      }
    });
  };
  const deleteWithId = (id) => {
    let deleteId = {
      ClientId: id,
    };
    PostCallWithErrorResponse(
      ApiConfig.DELETE_CLIENT_BY_ID,
      JSON.stringify(deleteId)
    ).then((res) => {
      if (res.response.status === 200) {
        ToastMsg("success", res.json.Message);
        fetchClientData();
      }
    });
  };
  const getProjectListData = () => {
    simpleGetCallWithToken(ApiConfig.GET_PROJECT_LIST_DD)
      .then((res) => {
        if (res.result === true) {
          setProjectlist(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchClientData();
  }, []);

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(5);
  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    clientList &&
    clientList.length &&
    clientList.filter((item) =>
      item.ProjectName?.toLowerCase.includes(searchValue)
    );

  const displayAllocationList = (
    <table>
      <thead>
        <tr className="firstTabelRow">
          <th>Sr.No</th>
          <th>Client Name</th>
          <th>Location</th>
          <th>Contact No</th>
          <th>Email</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {clientList &&
          clientList.length > 0 &&
          clientList.slice(startIndex, endIndex).map((data, index) => {
            return (
              <tr className="table-row-custom" key={index}>
                <td>{startIndex + index + 1}</td>
                <td>{data.CustomerName ? data.CustomerName : "-"}</td>
                <td>{data.ClientLocation ? data.ClientLocation : "-"}</td>
                <td>{data.ClientNo ? data.ClientNo : "-"}</td>
                <td>{data.ClientEmail ? data.ClientEmail : "-"}</td>
                <td>
                  {
                    permissions&&permissions['Edit Client']&&
                  <img
                    src={Editac}
                    onClick={() => {
                      setEdit(true);
                      getClientData(data.ClientID);
                      setClientId(data.ClientID);
                      getProjectListData();
                    }}
                    className="mx-2 editBtn"
                    alt=""
                  />
                  }
                  <img
                    src={DelectAc}
                    //onClick={() => deleteWithId(data.ClientID)}
                    onClick={() => {
                      handleShow3();
                      setDeleteTaskList(data.ClientID);
                    }}
                    className="deleteBtn"
                    alt=""
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
      {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
    </table>
  );

  const displaySearchAllocationList = (
    <table>
      <thead>
        <tr className="firstTabelRow">
          <th>Sr.No</th>
          <th>Client Name</th>
          <th>Location</th>
          <th>Contact No</th>
          <th>Email</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {clientList &&
          clientList.length > 0 &&
          clientList
            .filter((item) =>
              item?.CustomerName?.toLowerCase().includes(
                searchValue.toLocaleLowerCase()
              )
            )
            .slice(startIndex, endIndex)
            .map((data, index) => {
              return (
                <tr className="table-row-custom" key={index}>
                  <td>{startIndex + index + 1}</td>
                  <td>{data.CustomerName ? data.CustomerName : "-"}</td>
                  <td>{data.ClientLocation ? data.ClientLocation : "-"}</td>
                  <td>{data.ClientNo ? data.ClientNo : "-"}</td>
                  <td>{data.ClientEmail ? data.ClientEmail : "-"}</td>
                  <td>
                    {
                      permissions&&permissions['Edit Client']&&
                    <img
                      src={Editac}
                      onClick={() => {
                        setEdit(true);
                        getClientData(data.ClientID);
                        setClientId(data.ClientID);
                        getProjectListData();
                      }}
                      className="mx-2 editBtn"
                      alt=""
                    />
                      }
                      {
                        permissions&&permissions['Delete Client']&&
                    <img
                      src={DelectAc}
                      // onClick={() => deleteWithId(data.ClientID)}
                      onClick={() => {
                        handleShow3();
                        setDeleteTaskList(data.ClientID);
                      }}
                      className="deleteBtn"
                      alt=""
                    />
                    }
                  </td>
                </tr>
              );
            })}
      </tbody>
      {/* { {searchValue === "" ? displayTaskList : displaySearchedTaskList} } */}
    </table>
  );

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          {
            permissions&&permissions['Create Client']&&<div className="sub-header-role">
            <h6 className="my-2">Client Management</h6>
            {/* <input type="text" placeholder="enter name" onChange={(e)=> setTest(e.target.value)} />
            <p>Form name:{test} </p> */}
            <button
              className="create-btn-sub-header"
              variant="primary"
              onClick={() => {
                handleShow();
                getProjectListData();
              }}
            >
              + Add Client
            </button>
          </div>
          }
          
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search Client Name"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
            </div>
            {
              permissions&&permissions['Client Management Excel']&&
            <div className="import-export-btn-wrapper">
              <button className="booksIcon">
                {excelLoading ? (
                  <ExcelLoader />
                ) : (
                  <img
                    src={ic_excel}
                    onClick={(e) => downloadExcelFile(e)}
                    alt=""
                  />
                )}
              </button>
            </div>
             }
          </div>
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            {loading ? (
              <div>
                <Loader />
              </div>
            ) : clientList && clientList.length > 0 ? (
              <>
                {searchValue === null
                  ? displayAllocationList
                  : displaySearchAllocationList}
              </>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        <Pagination
          data={clientList && clientList.length > 0 && clientList}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
      </div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Add New Client
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="modalProp mb-3">
            <label htmlFor="" className="enter-role-text my-2">
              Client Name
            </label>
            <input
              type="text"
              name="CustomerName"
              onChange={(e) => {
                changeData(e);
                setClientErr({
                  ...clientErr,
                  CustomerNameErr: "",
                });
              }}
              className="form-control tasKCategory1"
              required
            />
            {clientErr.CustomerNameErr && (
              <label className="validationTxt">
                {clientErr.CustomerNameErr}
              </label>
            )}
          </div>
          <div className="modalProp mb-3">
            <label htmlFor="" className="enter-role-text my-2">
              Location
            </label>
            <input
              type="text"
              name="ClientLocation"
              onChange={(e) => {
                changeData(e);
                setClientErr({
                  ...clientErr,
                  ClientLocationErr: "",
                });
              }}
              className="form-control tasKCategory1"
              required
            />
            {clientErr.ClientLocationErr && (
              <label className="validationTxt">
                {clientErr.ClientLocationErr}
              </label>
            )}
          </div>
          <div className="modalProp mb-3">
            <label htmlFor="" className="enter-role-text my-2">
              Contact No
            </label>
            <input
              type="number"
              name="ClientNo"
              onChange={(e) => {
                changeData(e);
                setClientErr({
                  ...clientErr,
                  ClientNoErr: "",
                });
              }}
              className="form-control tasKCategory1"
              required
            />
            {clientErr.ClientNoErr && (
              <label className="validationTxt">{clientErr.ClientNoErr}</label>
            )}
          </div>
          <div className="modalProp  mb-3">
            <label htmlFor="" className="enter-role-text my-2">
              Email
            </label>
            <input
              type="email"
              pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
              name="ClientEmail"
              onChange={(e) => {
                changeData(e);
                setClientErr({
                  ...clientErr,
                  ClientEmailErr: "",
                });
              }}
              className="form-control tasKCategory1"
              required
            />
            {clientErr.ClientEmailErr && (
              <label className="validationTxt">
                {clientErr.ClientEmailErr}
              </label>
            )}
          </div>
        </Modal.Body>

        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div>
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={addClientData}
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={edit}
        onHide={handleCloseEdit}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Edit Client
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setEdit(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Body>
          <div className="mb-4">
            <label htmlFor="" className="enter-role-text">
              Client Name
            </label>
            <input
              type="text"
              name="CustomerName"
              onChange={(e) => {
                changeData(e);
                setClientErr({
                  ...clientErr,
                  CustomerNameErr: "",
                });
              }}
              value={clientData.CustomerName}
              className="form-control tasKCategory1"
            />
            {clientErr.CustomerNameErr && (
              <label className="validationTxt">
                {clientErr.CustomerNameErr}
              </label>
            )}
          </div>
          <div className=" mb-4">
            <label htmlFor="" className="enter-role-text my-2">
              Location
            </label>
            <input
              type="text"
              name="ClientLocation"
              onChange={(e) => {
                changeData(e);
                setClientErr({
                  ...clientErr,
                  ClientLocationErr: "",
                });
              }}
              value={clientData.ClientLocation}
              className="form-control tasKCategory1"
            />
            {clientErr.ClientLocationErr && (
              <label className="validationTxt">
                {clientErr.ClientLocationErr}
              </label>
            )}
          </div>
          <div className="modalProp mb-4">
            <label htmlFor="" className="enter-role-text my-2">
              Contact No
            </label>
            <input
              type="number"
              name="ClientNo"
              onChange={(e) => {
                changeData(e);
                setClientErr({
                  ...clientErr,
                  ClientNoErr: "",
                });
              }}
              value={clientData.ClientNo}
              className="form-control tasKCategory1"
            />
            {clientErr.ClientNoErr && (
              <label className="validationTxt">{clientErr.ClientNoErr}</label>
            )}
          </div>
          <div className="modalProp  mb-4">
            <label htmlFor="" className="enter-role-text my-2">
              Email
            </label>
            <input
              type="text"
              name="ClientEmail"
              onChange={(e) => {
                changeData(e);
                setClientErr({
                  ...clientErr,
                  ClientEmailErr: "",
                });
              }}
              value={clientData.ClientEmail}
              className="form-control tasKCategory1"
            />
            {clientErr.ClientEmailErr && (
              <label className="validationTxt">
                {clientErr.ClientEmailErr}
              </label>
            )}
          </div>
        </Modal.Body>

        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div>
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={handleCloseEdit}
            >
              Cancel
            </button>
            <button
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={editClientData}
            >
              Update
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Delete News and Blogs Modal Start */}
      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to delete this client ?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              deleteWithId(useDeleteTaskList);
              handleClose3();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
      {/* Delete News and Blogs Modal End */}
    </>
  );
};

export default ClientManagement;
