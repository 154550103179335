import React, { useState, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import deleteicon from "../../../assets/images/DelectAcBlc.svg";
import editicon from "../../../assets/images/EditacBlc.svg";

const ManageLoc = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [showed, setShowed] = useState(false);
  const handleClosed = () => {
    setShowed(false);
  };
  const handleShowed = () => setShowed(true);

  const [showdel, setShowdel] = useState(false);
  const handleClosedel = () => {
    setShowdel(false);
  };
  const handleShowdel = () => setShowdel(true);

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">Organization Info</h6>
        </div>
      </div>
      <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
        <div className="role-table-header accoridance">
          <div className="search-input-wrapper allAcordiance">
            {
              <Link to="/OrgDetails">
                <button className="all-anc-btn-sub-header">
                  Organization Details
                </button>
              </Link>
            }
          </div>
          <div className="entries-wrapper myAccordiance">
            {
              <Link to="/ManageDept">
                <button className="my-anc-btn-sub-header">
                  Manage Department
                </button>
              </Link>
            }
          </div>
          <div className="entries-wrapper myAccordiance">
            {
              <Link to="/ManageDesig">
                <button className="my-anc-btn-sub-header">
                  Manage Designation
                </button>
              </Link>
            }
          </div>
          <div className="entries-wrapper myAccordiance">
            {
              <Link to="/ManageLoc">
                <button className="my-anc-btn-sub-header active">
                  Manage Locations
                </button>
              </Link>
            }
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-end my-3">
          <button className="allocateionBtn" onClick={handleShow}>
            Add New Record
          </button>
        </div>
        <div
          className="table-wrapper custom-table-width"
          id="scrol-tblNtable-wrapper-af"
        >
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th>Sr. no.</th>
                <th>Location Name</th>
                <th>Country</th>
                <th>Email Alias</th>
                <th>Latitude</th>
                <th>Logitude</th>
                <th>Time Zone</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr className="table-row-custom">
                <td>1</td>
                <td>location name</td>
                <td>Country head</td>
                <td>Email head</td>
                <td>Latitude</td>
                <td>Logitude</td>
                <td>time</td>
                <td>
                  <img onClick={handleShowed} src={editicon}></img>
                  <img onClick={handleShowdel} src={deleteicon}></img>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          //dialogClassName="custom-modal"
          size="lg"
        >
          <Modal.Header closeButton className="custom-close-btn">
            <Modal.Title className="custom-modal-title">
              Add New Record
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Location Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Location Name"
                  // value={details?.company}
                  //  onChange={(e) => handleChage("company", e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Location Name!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Country"
                  required
                  // value={details?.company}
                  //  onChange={(e) => handleChage("company", e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Country Name!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Email Alias</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email Alias"
                  required
                  // value={details?.company}
                  //  onChange={(e) => handleChage("company", e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Email Name!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Latitude"
                  required
                  // value={details?.company}
                  //  onChange={(e) => handleChage("company", e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Latitude!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Longitude"
                  required
                  // value={details?.company}
                  //  onChange={(e) => handleChage("company", e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Longitude!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Time Zone</Form.Label>
                <Form.Select>
                  <option>Delhi</option>
                  <option>Karachi</option>
                  <option>Moscow</option>
                  <option>Dhaka</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please Enter Time Zone!
                </Form.Control.Feedback>
              </Form.Group>
              <hr />
              <div className="d-flex justify-content-end">
                <button className="mx-2 allocateionBtnwhite" onClick={handleClose}>
                  cancel
                </button>
                <button className="btn active-tab">save</button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={showed}
          onHide={handleClosed}
          //dialogClassName="custom-modal"
          size="lg"
        >
          <Modal.Header closeButton className="custom-close-btn">
            <Modal.Title className="custom-modal-title">
              Edit Record
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Location Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Location Name"
                  // value={details?.company}
                  //  onChange={(e) => handleChage("company", e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Location Name!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Country"
                  // value={details?.company}
                  //  onChange={(e) => handleChage("company", e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Country Name!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Email Alias</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email Alias"
                  required
                  // value={details?.company}
                  //  onChange={(e) => handleChage("company", e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Email Name!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Latitude"
                  required
                  // value={details?.company}
                  //  onChange={(e) => handleChage("company", e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Latitude!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Longitude"
                  required
                  // value={details?.company}
                  //  onChange={(e) => handleChage("company", e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Longitude!
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="company" className="mb-3">
                <Form.Label>Time Zone</Form.Label>
                <Form.Select>
                  <option>Delhi</option>
                  <option>Karachi</option>
                  <option>Moscow</option>
                  <option>Dhaka</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please Enter Time Zone!
                </Form.Control.Feedback>
              </Form.Group>
              <hr />
              <div className="d-flex justify-content-end">
                <button className="mx-2 allocateionBtnwhite" onClick={handleClosed}>
                  cancel
                </button>
                <button className="btn active-tab">save</button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={showdel}
          onHide={handleClosedel}
          //dialogClassName="custom-modal"
          size="lg"
        >
          <Modal.Body>
            <h5 className="text-center">Be sure to delete it !</h5>
            <Form>
              <div className="d-flex justify-content-center">
                <button className="allocateionBtnwhite mx-2" onClick={handleClosedel}>
                  cancel
                </button>
                <button className="btn active-tab">confirm</button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
export default ManageLoc;
