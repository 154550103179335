import React from "react";

const ExcelLoader = () => {
  return (
    <div
      class="spinner-border"
      role="status"
      style={{
        width: "1rem",
        height: "1rem",
        marginTop: "10px",
        marginLeft: "10px",
        color: "#5ac8fa",
      }}
    ></div>
  );
};

export default ExcelLoader;
