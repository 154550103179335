import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import importicon from "../../assets/images/import.svg";
import Eyes from "../../assets/images/ic_show_eyes.svg";
import { Link } from "react-router-dom";


const WalletSales = () => {

    const { sidebar, setSidebar, Dark, setDark, userData } = useContext(AppContext);

    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="employee_wallet_sales">
                    <div className="sub-header-role mb-4">
                        <h6 className="mt-2">Employee Wallet </h6>
                    </div>
                    <div className="mainTableBox">
                        <div className="upperFlex">
                            <button className="exportBtn">
                                <img src={importicon} alt="" />
                            </button>
                        </div>
                        <div className="insideTable">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Image</th>
                                        <th>Employee</th>
                                        <th>Amount</th>
                                        <th>Description</th>
                                        <th>Date & Time</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                            >
                                                <option value="">All</option>
                                                <option value="One">One</option>
                                                <option value="Two">Two</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type="text" className="form-control" />
                                        </td>
                                        <td>
                                            <input type="text" className="form-control" />
                                        </td>
                                        <td>
                                            <input type="text" className="form-control" />
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Image</td>
                                        <td>Alex</td>
                                        <td>500</td>
                                        <td>Information</td>
                                        <td>29-02-2024:10:00:pm</td>
                                        <td>
                                            <button className="actionBtn">
                                                <Link to="/TransactionReceipt">
                                                    <img src={Eyes} alt="" />
                                                </Link>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WalletSales;
