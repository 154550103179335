import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import { Button, Dropdown } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import { Link } from "react-router-dom";
import profile_img from "../../assets/images/profile_img.svg";
import user_pic2 from "../../assets/images/profile_img.svg";
import "../../assets/css/main.scss";
import { SpeedDial } from "primereact/speeddial";
import ic_online from "../../assets/images/chat/ic_online.svg";
import ic_video_call from "../../assets/images/chat/ic_video_call.svg";
import bx_phone_call from "../../assets/images/chat/bx_phone_call.svg";
import more_vertical from "../../assets/images/chat/more_vertical.svg";
import double_tick from "../../assets/images/chat/double_tick.svg";
import hr_three_dot from "../../assets/images/chat/hr_three_dot.svg";
import send_btn from "../../assets/images/chat/send_btn.svg";

const ChatHistory = () => {
  const { slideOpen, setSlideOpen } = useContext(AppContext);
  const toast = useRef(null);

  const items = [
    {
      label: "Add",
      icon: "pi pi-pencil",
      command: () => {
        toast.current.show({
          severity: "info",
          summary: "Add",
          detail: "Data Added",
        });
      },
    },
    {
      label: "Update",
      icon: "pi pi-refresh",
      command: () => {
        toast.current.show({
          severity: "success",
          summary: "Update",
          detail: "Data Updated",
        });
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => {
        toast.current.show({
          severity: "error",
          summary: "Delete",
          detail: "Data Deleted",
        });
      },
    },
    {
      label: "Upload",
      icon: "pi pi-upload",
      command: () => {
        window.location.hash = "/fileupload";
      },
    },
    {
      label: "React Website",
      icon: "pi pi-external-link",
      command: () => {
        window.location.href = "https://facebook.github.io/react/";
      },
    },
  ];
  return (
    <div className="col-lg-9 right-chat-section chat-history-margin custom-height">
      <div eventKey="1">
        <div className="heading-chat-section">
          <div className="left">
            <div className="left-profile-pic">
              <Link>
                <img src={profile_img} alt="" />
              </Link>
              <span className="indication-img">
                <img src={ic_online} alt="" />
              </span>
            </div>
            <div className="name">
              <label htmlFor="">General</label>
            </div>
          </div>
          <div className="right">
            <Link to="#" className="right-icon-chat">
              <img src={bx_phone_call} alt="" />
            </Link>
            <Link to="#" className="right-icon-chat">
              <img src={ic_video_call} alt="" />
            </Link>
            <Dropdown className="ActionDropdown right-icon-chat">
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="ActionToggle"
              >
                <img src={more_vertical} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="ActionMenu">
                <ul className="actionnmenulist">
                  <li>About</li>
                  <li>Pin</li>
                  <li>Hide</li>
                  <li>Manage Channel</li>
                  <li>Get link to channel</li>
                  <li>Edit this channel</li>
                  <li>Delete This Channel</li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="chatting-section chatting-section-custom-height">
          <div className="message-main">
            <div className="send-msg">
              <Link>
                <img src={double_tick} alt="double_tick" className="ms-3" />
              </Link>
              <div className="send-msg-main">
                <p className="msg-text">
                  Message goes here Message goes here Message goes here
                </p>
              </div>
              {/* sadik */}
              <Dropdown className="ActionDropdown">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="ActionToggle"
                >
                  <img src={hr_three_dot} alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="ActionMenu">
                  <ul className="actionnmenulist">
                    <li>Reply</li>
                    <li>Edit</li>
                    <li>Save this message</li>
                    <li>Mark as unread</li>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="time-date-sms-send">4 days ago</div>

            <div className="receive-msg">
              <div className="msg-with-img">
                <img src={user_pic2} alt="" />
              </div>
              <div className="right-receive-msg">
                <div className="receive-msg-main">
                  <p className="msg-text">
                    Message goes here Message goes here Message goes Lorem ipsum
                    dolor sit amet consectetur adipisicing elit. Autem
                    aspernatur libero suscipit magnam tempore architecto, omnis
                    nihil? Cum dolorem nulla amet ullam, iusto, nam culpa
                    impedit odit sit aspernatur illo! here
                  </p>
                </div>

                <div>
                  <Dropdown className="ActionDropdown">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="ActionToggle"
                    >
                      <img src={hr_three_dot} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="ActionMenu">
                      <ul className="actionnmenulist">
                        <li>Reply</li>
                        <li>Edit</li>
                        <li>Save this message</li>
                        <li>Mark as unread</li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="time-date-sms-receive">4 days ago</div>
          </div>
        </div>
        <div className="send_section_btn_inputs">
          <div className="speed-dial">
          <SpeedDial model={items} direction="up" />
          </div>
          <div className="text-input">
            <input
              type="text"
              className="form-control"
              placeholder="Type a message here"
            />
          </div>
          <div className="send-btn">
            <Link to="#">
              <img src={send_btn} alt="" />
            </Link>
          </div>
        </div>        
      </div>
    </div>
  );
};

export default ChatHistory;
