import { AppContext } from "../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import Loader from "../Loader";
import Editac from "../../assets/images/bx_edit.svg";
import DelectAc from "../../assets/images/DelectAcBlc.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import NoData from "../../sharedComponent/NoData";
import { FormControl, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import {
  DeleteCallWithErrorResponseWithToken,
  PostCallWithErrorResponse,
  putMultipartWithAuthCall,
  putWithAuthCall,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Pagination from "../../sharedComponent/Pagination";

function ShiftTime() {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  const [allShifts, setAllShifts] = useState([]);

  const [loading, setLoading] = useState(false);

  // Modal usestate
  const [addEditOpen, setAddEditOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOption, setDeleteOption] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(8);
  const startIndex = currentPage * usersPerPage;
    const endIndex = startIndex + parseInt(usersPerPage);

  const [deleteId, setDeleteId] = useState();
  const [editId, setEditId] = useState(0);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
    
};

const searchedDataPageCounts =
        allShifts &&
        allShifts.length &&
        allShifts.filter((item) =>
            item.ProjectName?.toLowerCase().includes(searchValue)
        );

  const [storeValues, setStoreValues] = useState({
    ShiftName: "",
    InTime: "",
    OutTime: "",
    GraceTime: 0,
    breakTimeIn: "",
    breakTimeOut: "",
    Id: 0
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const shiftMasterStore = () => {
    if (
      !storeValues.ShiftName ||
      !storeValues.InTime ||
      !storeValues.OutTime ||
      !storeValues.GraceTime ||
      !storeValues.breakTimeIn ||
      !storeValues.breakTimeOut
    ) {
      ToastMsg("error", "Please Provide All Details");
      return
    }

    let data = {
      ShiftName: storeValues.ShiftName,
      InTime: storeValues.InTime,
      OutTime: storeValues.OutTime,
      GraceTime: parseInt(storeValues.GraceTime),
      BreakStartTime: storeValues.breakTimeIn,
      BreakEndTime: storeValues.breakTimeOut
    };

    PostCallWithErrorResponse(
      ApiConfig?.ADD_SHIFT_MASTER,
      JSON.stringify(data)
    )
      .then((res) => {
        if (res.json && res?.json?.Success) {
          ToastMsg("success", res?.json?.Message);
          setStoreValues({
            ShiftName: "",
            InTime: "",
            OutTime: "",
            GraceTime: 0,
            breakTimeIn: 0,
            breakTimeOut: 0,
            Id: 0
          });
          setAddEditOpen(false);
          getAllShift();
        } else {
          ToastMsg("error", res?.json?.Message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const shiftMasterUpdate = (id) => {
    if (
      !storeValues.ShiftName ||
      !storeValues.InTime ||
      !storeValues.OutTime ||
      !storeValues.GraceTime ||
      !storeValues.breakTimeIn ||
      !storeValues.breakTimeOut
    ) {
      ToastMsg("error", "Please Provide All Details");
      return
    }

    let data = {
      ShiftName: storeValues.ShiftName,
      InTime: storeValues.InTime,
      OutTime: storeValues.OutTime,
      GraceTime: parseInt(storeValues.GraceTime),
      BreakStartTime: storeValues.breakTimeIn,
      BreakEndTime: storeValues.breakTimeOut
    };
    
    putWithAuthCall(
      ApiConfig?.UPDATE_SHIFT_MASTER + id,
      JSON.stringify(data)
    )
      .then((res) => {
        if ( res?.Success) {
          ToastMsg("success", res?.Message);
          setStoreValues({
            ShiftName: "",
            InTime: "",
            OutTime: "",
            GraceTime: 0,
            breakTimeIn: "",
            breakTimeOut: "",
            Id: 0
          });
          setAddEditOpen(false);
          getAllShift();
        } else {
          ToastMsg("error", res?.Message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editShiftMaster = (editId) => {
    simpleGetCallWithErrorResponse(ApiConfig?.EDIT_SHIFT_MASTER + editId)
      .then((res) => {
        if (res && res?.json?.Success) {
          storeValues.ShiftName = res?.json?.Data.ShiftName;
          storeValues.InTime = res?.json?.Data.InTime;
          storeValues.OutTime = res?.json?.Data.OutTime;
          storeValues.GraceTime = res?.json?.Data.GraceTime;
          storeValues.breakTimeIn = res?.json?.Data.BreakStartTime;
          storeValues.breakTimeOut = res?.json?.Data.BreakEndTime;
          storeValues.Id = res?.json?.Data.Id;
          console.log(" payload ", storeValues)
          setAddEditOpen(true)
        } else {
          ToastMsg('error', res?.json?.Message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const deleteShift = (deleteId) => {
    DeleteCallWithErrorResponseWithToken(ApiConfig?.DELETE_SHIFT_MASTER + deleteId)
      .then((res) => {
        if (res?.json?.Success) {
          ToastMsg("success", res?.json?.Message);
          setDeleteOption(false)
          getAllShift();
          return;
        } else {
          ToastMsg("error", res?.json?.Message);
          setDeleteOption(false)
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    getAllShift();
  }, []);

  // GET Shift Master Data
  const getAllShift = (search = '') => {
    setLoading(true);

    PostCallWithErrorResponse(ApiConfig?.GET_ALL_SHIFT, JSON.stringify({
      startIndex: 10,
      count: 0,
      sorting: search
    }))
      .then((res) => {
        if (res?.json?.Success) {
          setAllShifts(res?.json?.Data);
        } else {
          console.log("something went wrong");
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        
        <div className="role-content-main">
          <div className="sub-header-role mb-4 d-flex justify-content-between">
            <h6 className="mt-2">Shift Time </h6>
            <button
              className="create-btn-sub-header"
              onClick={() => setAddEditOpen(true)}
            >
              Add Shift
            </button>
          </div>
          <div className="d-flex justify-content-between mainHeader custom-mb">
            <div className="role-table-header-main">
            <div className="search-input-wrapper">
                <input
                  type="text"
                  data-date=""
                  data-date-format="DD MMMM YYYY"
                  placeholder="Search"
                  onChange={(e) => {
                    getAllShift(e.target.value)
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
            </div>
          </div>

          <div className="table-wrapper-main mb-4">
            {loading ? (
              <Loader />
            ) : (
              <table>
                <thead>
                  <tr className="firstTabelRow">
                    <th>Shift Name</th>
                    <th>In Time</th>
                    <th>Out Time</th>
                    <th>Grace Time {"(In min)"}</th>
                    <th>Break Time {"(In Min)"}</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {allShifts?.length > 0 ? (
                  <tbody>
                    { allShifts.slice(startIndex, endIndex).map((shift, indx) => {
                      return (
                        <tr className="table-row-main">
                          <td>{shift?.ShiftName}</td>
                          <td>{shift?.InTime}</td>
                          <td>{shift?.OutTime}</td>
                          <td>{shift?.GraceTime}</td>
                          <td>{shift?.BreakTime}</td>
                          <td>
                            <img onClick={() => {
                              setEditId(shift?.Id)
                              editShiftMaster(shift?.Id)
                              // setAddEditOpen(true)
                            }} src={Editac}
                              alt="" />
                            <img onClick={() => {
                              setDeleteOption(true);
                              setDeleteId(shift?.Id);
                            }} src={DelectAc}
                              alt="" />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <NoData />
                )}
              </table>
            )}
          </div>
        </div>
        <Pagination
                    data={allShifts}
                    pageChangeHandler={pageChangeHandler}
                    usersPerPage={usersPerPage}
                    currentPage={currentPage}
                    searchValue={searchValue}
                    searchedDataPageCounts={searchedDataPageCounts}
                />
      </div>

      {/* ADD MODAL */}
      <Modal
        className="delete-news-modal"
        show={addEditOpen}
        dialogClassName="modal-90w"
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className="delete-title"> {editId > 0 ? 'Update Record' : 'Add New Record'}</Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <Form className="d-flex flex-column gap-3">
            <Form.Group>
              <Form.Label>Shift Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Shift Name"
                name="ShiftName"
                value={storeValues.ShiftName}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback>
                Shift name Requered!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>In Time</Form.Label>
              <Form.Control
                required
                type="time"
                placeholder="InTime"
                name="InTime"
                value={storeValues.InTime}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback>In Time Required!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Out Time</Form.Label>
              <Form.Control
                required
                type="time"
                placeholder="Out Time"
                name="OutTime"
                value={storeValues.OutTime}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback>Out Time Required!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Grace Time</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Grace Time"
                name="GraceTime"
                value={storeValues.GraceTime}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback>
                Grace Time required!!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Break Time {"(In Min)"}</Form.Label>
              <Form.Control
                required
                type="time"
                placeholder="Break Time (In Min)"
                name="breakTimeIn"
                value={storeValues.breakTimeIn}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback>
                Break Time In Min Required!!
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Break Time {"(out Min)"}</Form.Label>
              <Form.Control
                required
                type="time"
                placeholder="Break Time (Out Min)"
                name="breakTimeOut"
                value={storeValues.breakTimeOut}
                onChange={handleInputChange}
              />
              <Form.Control.Feedback>
                Break Time Out Min Requered!
              </Form.Control.Feedback>
            </Form.Group>
            <div className="lineSeperate"></div>
            <div className="d-flex justify-content-end align-items-center mainBtnsSub">
              <button
                type="button"
                className="btn-width cancelBtn mx-3"
                onClick={() => {
                  setAddEditOpen(false)
                  setStoreValues({
                    ShiftName: "",
                    InTime: "",
                    OutTime: "",
                    GraceTime: 0,
                    breakTimeIn: 0,
                    breakTimeOut: 0,
                    Id: 0
                  });
                  setEditId(0);
                }
                }
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn-width saveBtn"
                onClick={() => {
                  if (editId>0) {
                    shiftMasterUpdate(editId);
                  }else{
                    shiftMasterStore();
                  }
                }}
              >
                Save
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* DELETE MODAL */}
      <Modal
        className="delete-news-modal"
        show={deleteOption}
        dialogClassName="modal-10w"
        centered
        size="lg"
      >
        <Modal.Body className="delete-body ">
          <div>
            <h6 className="text-center">Are You Sure To Shift !</h6>
            <div className="d-flex justify-content-center align-items-center mainBtnsSub">
              <button
                type="button"
                className="btn-width cancelBtn mx-3"
                onClick={() => setDeleteOption(false)}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn-width saveBtn"
                onClick={() => {
                  deleteShift(deleteId);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal.Body>


      </Modal>
    </>
  );
}

export default ShiftTime;
