import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { Accordion, Nav, Tab, Tabs } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import noDataImg from "../../assets/images/no_data.svg"
import Loader from '../Loader';

const OngoingCalls = () => {
  const { callsLoading, sidebar, callHystory, socket } = useContext(AppContext);
  const [onGoingcalls, setOngoingcalls] = useState([])

  useEffect(() => {
    if (socket) {
      socket.emit('get-ongoing-calls-all', {

      })
      console.log('get-ongoing-calls-all');
      socket.on('get-ongoing-calls-all', (data) => {
        console.log('get-ongoing-calls-all', data);
        setOngoingcalls(data)

      })
    }
  }, [socket])

  const navigate = useNavigate();
  const [search, setSearch] = useState("")
  return (
    <div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
    >
      <div className="main-chat" id='call_main_wrap'>
        <Tab.Container id="left-tabs-example">

          <div className="row justify-content-around">
            {
              callsLoading ? <Loader /> :
                <>

                  <div className="col-lg-12 right-chat-section3">
                    <div className="right-heading-chat">
                      <div className="right-head">
                        {/* <>
                          <tr className="ErrorDataNotFound">
                            <td colSpan={6}>
                              <div className="d-flex justify-content-center align-items-center h-100">
                                <img src={noDataImg} alt="" />
                              </div>
                              <span>Data Not Found</span>

                            </td>
                          </tr>
                        </> */}
                      </div>
                    </div>
                    {/* <div className="chat-lineseparater"></div> */}
                    <div className="table-wrapper-main mb-4">
                      <table>
                        <thead>
                          <tr className="firstTabelRow">
                            <th>Sr. no</th>
                            <th>Channel Name</th>
                            <th>Call From</th>
                            <th>Call start Time</th>
                            <th>Join</th>

                          </tr>
                        </thead>
                        <tbody>
                          {onGoingcalls && onGoingcalls.length > 0 ? (
                            onGoingcalls.map((single, index) => {
                              return (
                                <tr className="table-row-main">
                                  <td>{index + 1}</td>


                                  <td>{single.Team_Channel_Name}{single.Team_Channel_Description && "-" + single.Team_Channel_Description}</td>
                                  <td>{single.FirstName && (single.FirstName + " " + single.LastName)}</td>
                                  <td>{(new Date(single.call_start_time.substring(0, single.call_start_time.indexOf('.')) + ".209Z")).toLocaleString()}</td>
                                  <td>{
                                    !single.web_id ?
                                      <button
                                        onClick={() => navigate("/jitsi/" + single.call_id + "/" + single.room_id + "/" + localStorage.getItem("Username"), "_blank")}
                                        className='btn btn-info' >
                                        Join
                                      </button> : <b style={{ color: 'green' }}>Already In Call</b>}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <>
                              <tr className="ErrorDataNotFound">
                                <td colSpan={6}>
                                  <div className="d-flex justify-content-center align-items-center h-100">
                                    <img src={noDataImg} alt="" />
                                  </div>
                                  <span>Data Not Found</span>

                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>

                  </div>
                </>

            }

          </div>
        </Tab.Container>
      </div>
    </div>
  )
}

export default OngoingCalls