import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import {
  putMultipartWithAuthCallWithErrorResponse,
  putWithAuthCall,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";

const ViewNonFunctionalFeatures = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  const items = [
    { name: "Module", description: "Security" },
    { name: "Feature", description: "Sharing Between Apps" },
    { name: "Functional Unit", description: "SI" },
    { name: "Weighting Factor", description: "High" },
    { name: "Feature Type", description: "Advanced" },
  ];
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Non-Functional Features Details</h6>
          </div>
          <div className="EnquiryCard">
            <ul
              style={{
                listStyleType: "none",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              {items.map((item, index) => (
                <li key={index}>
                  <div className="row col-md-12 ml-2 mt-2">
                    <p className="col-md-4 mt-2">{item.name}</p>:
                    <p className="col-md-4"> {item.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewNonFunctionalFeatures;
