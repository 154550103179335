import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Pagination from "../../sharedComponent/Pagination";

const CallReport = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const [activeTab, setActiveTab] = useState("inbound");

  const inboundData = [
    {
      srNo: "1",
      type: "Agent",
      callCount: "19",
    },
    {
      srNo: "2",
      type: "App_User",
      callCount: "0",
    },
    {
      srNo: "3",
      type: "Candidate",
      callCount: "2412",
    },
    {
      srNo: "4",
      type: "Hire_Team_Lead",
      callCount: "1",
    },
    {
      srNo: "5",
      type: "Inquiry",
      callCount: "6572",
    },
    {
      srNo: "6",
      type: "Lead",
      callCount: "149",
    },
    {
      srNo: "7",
      type: "Prospect",
      callCount: "204",
    },
    {
      srNo: "8",
      type: "Spam",
      callCount: "3308",
    },
    {
      srNo: "9",
      type: "Un-classified",
      callCount: "497",
    },
  ];
  const outboundData = [
    {
      srNo: "1",
      type: "Candidate",
      callCount: "6112",
    },
    {
      srNo: "2",
      type: "User",
      callCount: "2057",
    },
    {
      srNo: "3",
      type: "Candidate",
      callCount: "4075",
    },
  ];

  const dataToDisplay = activeTab === "inbound" ? inboundData : outboundData;

  const totalCallCount = dataToDisplay.reduce(
    (total, item) => total + parseInt(item.callCount),
    0
  );

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role">
          <h6 className="mt-2">Call Report</h6>
        </div>
        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary cus-mt">
          <div className="role-table-header accoridance">
            <div className="search-input-wrapper allAcordiance">
              <button
                className={`inBound-btn-sub-header ${
                  activeTab === "inbound" ? "active" : ""
                }`}
                onClick={() => setActiveTab("inbound")}
              >
                Inbound
              </button>
            </div>
            <div className="entries-wrapper myAccordiance">
              <button
                className={`outBound-btn-sub-header ${
                  activeTab === "outbound" ? "active" : ""
                }`}
                onClick={() => setActiveTab("outbound")}
              >
                Outbound
              </button>
            </div>
          </div>
          <div className="entries-wrapper myAccordiance">
            <p>
              <b className="me-2">Total Call Count:</b> {totalCallCount}
            </p>
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">SR.NO</th>
                <th scope="col">TYPE</th>
                <th scope="col">CALL COUNT</th>
              </tr>
            </thead>
            <tbody>
              {dataToDisplay.map((data, index) => (
                <tr key={index} className="table-row-custom">
                  <td>{data.srNo}</td>
                  <td>{data.type}</td>
                  <td>{data.callCount}</td>
                </tr>
              ))}
              <tr className="table-row-custom">
                <td></td>
                <td></td>
                <td>Total: {totalCallCount}</td>
              </tr>
            </tbody>
          </table>
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default CallReport;
