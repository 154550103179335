import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { DeleteCallWithErrorResponseWithToken, PostCallWithErrorResponse, deleteWithAuthCall, simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Editac from "../../assets/images/Editac.svg";
import DelectAc from "../../assets/images/DelectAc.svg";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Modal from "react-bootstrap/Modal";
import { ToastMsg } from "../TostMsg";
import Pagination from "../../sharedComponent/Pagination";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";

const ProjectCost = () => {

    const { sidebar, setSidebar, Dark, setDark, userData } = useContext(AppContext);

    const [teamName, setTeamName] = useState([]);
    const [desigName, setDesigName] = useState([]);
    const [employeeattlist, setEmployeeattlist] = useState([]);
    const [loading, setLoading] = useState(false);
    const [Edit, setEdit] = useState(false);

    const [searchValue, setSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [usersPerPage, setUsersPerPage] = useState(5);

    const startIndex = currentPage * usersPerPage;
    const endIndex = startIndex + parseInt(usersPerPage);

    const pageChangeHandler = ({ selected }) => {
        setCurrentPage(selected);
    };

    const searchedDataPageCounts =
        employeeattlist &&
        employeeattlist.length &&
        employeeattlist.filter((item) =>
            item.ProjectName?.toLowerCase().includes(searchValue)
        );

    const [addCost, setAddCost] = useState({
        costDataId: 0,
        TeamId: 0,
        DesignationId: 0,
        cost: 0
    });

    const changeData = (e) => {
        const { name, value } = e.target
        setAddCost({
            ...addCost,
            [name]: value
        })
    }

    const fetchTeamName = () => {
        simpleGetCallWithToken(ApiConfig.GET_DROPDOWNTEAMNAME_LIST)
            .then((res) => {
                if (res.Success === true) {
                    setTeamName(res.Data);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    };
    const fetchDesigName = () => {
        simpleGetCallWithToken(ApiConfig.GET_DESIGNATIONDD_LIST)
            .then((res) => {
                if (res.Success === true) {
                    setDesigName(res.Data);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    };

    const submitHandle = (e) => {
        e.preventDefault();
        let payloadData = {
            TeamId: JSON.parse(addCost.TeamId),
            DesignationId: JSON.parse(addCost.DesignationId),
            cost: JSON.parse(addCost.cost)
        }
        PostCallWithErrorResponse(ApiConfig.POST_COST_DATA, JSON.stringify(payloadData))
            .then((res) => {
                if (res.json.Success === true) {
                    ToastMsg("success", res.json.Message);
                    setAddCost({
                        ...addCost,
                        TeamId: 0,
                        DesignationId: 0,
                        cost: 0
                    })
                    fetchEmpList();
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchEmpList = () => {
        setLoading(true);
        simpleGetCallWithToken(ApiConfig.GET_EMPLOYEE_LIST)
            .then((res) => {
                if (res.Success === true) {
                    setLoading(false);
                    setEmployeeattlist(res.Data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };
    const fetchDatabyId = (data) => {
        let payloadId = {
            costDataId: data?.EmployeecostId
        }
        PostCallWithErrorResponse(ApiConfig.GET_DATA_WITH_ID, JSON.stringify(payloadId))
            .then((res) => {
                if (res.json.Success === true) {
                    let mainData = res.json.Data;
                    setAddCost({
                        ...addCost,
                        costDataId: data?.EmployeecostId,
                        TeamId: mainData?.TeamId,
                        DesignationId: mainData?.TeamId,
                        cost: mainData?.Cost,
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const updateCost = () => {
        let payUpdate = {
            costDataId: addCost?.costDataId,
            TeamId: JSON.parse(addCost?.TeamId),
            DesignationId: JSON.parse(addCost?.DesignationId),
            cost: JSON.parse(addCost?.cost)
        }
        PostCallWithErrorResponse(ApiConfig.POST_UPDATE_COST, JSON.stringify(payUpdate))
            .then((res) => {
                if (res.json.Success === true) {
                    fetchEmpList();
                    ToastMsg("success", res.json.Message);
                    setEdit(false);
                    setAddCost({
                        costDataId: 0,
                        TeamId: 0,
                        DesignationId: 0,
                        cost: 0
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const deleteData = (id) => {
        let deletePay = {
            CostdataId: id
        }
        DeleteCallWithErrorResponseWithToken(ApiConfig.DELETE_SINGLE_DATA, JSON.stringify(deletePay))
            .then((res) => {
                if (res.json.Success === true) {
                    ToastMsg("success", res.json.Message);
                    fetchEmpList();
                }
            })
    }
    useEffect(() => {
        fetchTeamName();
        fetchDesigName();
        fetchEmpList();
    }, [])

    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="role-content">
                    <div className="sub-header-role ">
                        <h6 className="mt-2">Add Costing</h6>
                    </div>
                    <div className="innerRoleSec">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                <div className="mb-4">
                                    <label htmlFor="" className="enter-role-text">
                                        Team Name
                                    </label>
                                    <select
                                        onChange={(e) => {
                                            changeData(e)
                                        }}
                                        name="TeamId"
                                        value={addCost.TeamId}
                                        class="form-select tasKCategory1 select-drop-icon"
                                        aria-label="Default select example"
                                    >
                                        <option value="">Select Team Name</option>
                                        {
                                            teamName.map((name, index) => {
                                                return (
                                                    <option value={name.TeamId} key={index}>{name.TeamName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <div className="mb-4">
                                    <label htmlFor="" className="enter-role-text">
                                        Designation
                                    </label>
                                    <select
                                        onChange={(e) => {
                                            changeData(e)
                                        }}
                                        name="DesignationId"
                                        value={addCost.DesignationId}
                                        class="form-select tasKCategory1 select-drop-icon"
                                        aria-label="Default select example"
                                    >
                                        <option value="">Select Designation</option>
                                        {
                                            desigName.map((name, index) => {
                                                return (
                                                    <option value={name.DesignationId} key={index}>{name.Name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <div className="modalProp mb-4">
                                    <label
                                        htmlFor=""
                                        className="enter-role-text"
                                    >
                                        Cost
                                    </label>
                                    <input
                                        onChange={(e) => {
                                            changeData(e)
                                        }}
                                        name="cost"
                                        value={addCost.cost}
                                        type="number"
                                        className="date-label-input tasKCategory1"
                                        style={{
                                            fontSize: "14px",
                                            height: "38px",
                                            border: "1px solid #ced4da",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-8 text-end">
                                <button className="modal-create-btn" onClick={submitHandle}>
                                    <span style={{ fontWeight: "bold" }}>+ </span>
                                    Add Cost
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="sub-header-role">
                        <h6 className="mt-2">ENTERED COST DATA</h6>
                        <div className="">
                            <select
                                className="form-select select-drop-icon"
                                aria-label="Default select example"
                                onChange={(e) => {
                                    setUsersPerPage(e.target.value);
                                    setCurrentPage(0);
                                }}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                    </div>
                    <div className="table-wrapper">
                        {
                            loading ?
                                <div>
                                    <Loader />
                                </div> :
                                <>
                                    {
                                        employeeattlist && employeeattlist.length > 0 ?
                                            <table>
                                                <thead>
                                                    <tr className="firstTabelRow">
                                                        <th>ID</th>
                                                        <th>Designation Name</th>
                                                        <th>Team Name</th>
                                                        <th>Cost</th>
                                                        <th>Edit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        employeeattlist.slice(startIndex, endIndex).map((data, index) => {
                                                            return (
                                                                <tr className="table-row-custom" key={index}>
                                                                    <td>{data.EmployeecostId}</td>
                                                                    <td>{data.DesignationName}</td>
                                                                    <td>{data.TeamName}</td>
                                                                    <td>{data.Cost}</td>
                                                                    <td><img src={Editac} className="mx-2 editBtn" alt="" onClick={() => {
                                                                        setEdit(true)
                                                                        fetchDatabyId(data)
                                                                        // setAddCost({ ...addCost, costDataId: data?.EmployeecostId })
                                                                    }} />
                                                                        <img src={DelectAc} className="deleteBtn" onClick={() => deleteData(data?.EmployeecostId)} alt="" /></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <>
                                                <NoData />
                                            </>
                                    }
                                </>
                        }
                    </div>
                </div>
                <Pagination
                    data={employeeattlist}
                    pageChangeHandler={pageChangeHandler}
                    usersPerPage={usersPerPage}
                    currentPage={currentPage}
                    searchValue={searchValue}
                    searchedDataPageCounts={searchedDataPageCounts}
                />
            </div>

            {/* Edit Modal Task */}
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={Edit}
                onHide={() => setEdit(false)}
            >
                <Modal.Header>
                    <Modal.Title>
                        <label htmlFor="" className="create-role-text">
                            Edit Cost
                        </label>
                    </Modal.Title>
                    <div className="rightAxe">
                        <img src={Axe} alt="" onClick={() => setEdit(false)} />
                    </div>
                    {/* <img src={close} alt="" onClick={handleClose} className="cr-class" /> */}
                </Modal.Header>
                <div className="hr-line-modal">
                    <hr style={{ margin: "0" }} />
                </div>

                <Modal.Body>
                    <div className="mb-4">
                        <label htmlFor="" className="enter-role-text">
                            Team Name
                        </label>
                        <select
                            class="form-select tasKCategory1 select-drop-icon"
                            aria-label="Default select example"
                            value={addCost?.TeamId ? addCost?.TeamId : ""}
                            onChange={(e) => {
                                changeData(e);
                            }}
                            name="TeamId"
                        >
                            <option value="">Team Name</option>
                            {
                                teamName.map((name, index) => {
                                    return (
                                        <option value={name.TeamId} key={index}>{name.TeamName}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="" className="enter-role-text">
                            Designation
                        </label>
                        <select
                            class="form-select tasKCategory1 select-drop-icon"
                            aria-label="Default select example"
                            value={addCost?.DesignationId ? addCost?.DesignationId : null}
                            onChange={(e) => {
                                changeData(e);
                            }}
                            name="DesignationId"
                        >
                            <option value="">Select Designation</option>
                            {
                                desigName.map((name, index) => {
                                    return (
                                        <option value={name.DesignationId} key={index}>{name.Name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="modalProp mb-4">
                        <label
                            htmlFor=""
                            className="enter-role-text"
                        >
                            Cost
                        </label>
                        <input
                            onChange={(e) => {
                                changeData(e)
                            }}
                            name="cost"
                            value={addCost?.cost ? addCost?.cost : null}
                            type="number"
                            className="date-label-input tasKCategory1"
                            style={{
                                fontSize: "14px",
                                height: "38px",
                                border: "1px solid #ced4da",
                            }}
                        />
                    </div>
                </Modal.Body>

                <div className="hr-line-modal-1">
                    <hr style={{ margin: "0" }} />
                </div>
                <Modal.Footer className="model-footer-arbaz-responsive">
                    <div 
                    // className="Bottom-btn-arbaz-responsive"
                    >
                        <button
                            className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                            onClick={() => setEdit(false)}
                        >
                            Cancel
                        </button>
                        <button className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive" onClick={updateCost}>
                            Update
                        </button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default ProjectCost
