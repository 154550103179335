import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import { Link } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";

const NotCallClassification = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const crmInboundCallsData = [
    {
      name: "+919309482204",
      callDate: "05-06-2024",
      callStartTime: "4:40 p.m.",
      callEndTime: "4:40 p.m.",
      agentName: "---",
      callStatus: "Not Answered",
      callRecording: "",
    },
    {
      name: "+918765432109",
      callDate: "04-06-2024",
      callStartTime: "3:00 p.m.",
      callEndTime: "3:05 p.m.",
      agentName: "Agent 2",
      callStatus: "Answered",
      callRecording: "",
    },
    {
      name: "+917654321098",
      callDate: "03-06-2024",
      callStartTime: "1:30 p.m.",
      callEndTime: "1:40 p.m.",
      agentName: "Agent 3",
      callStatus: "Answered",
      callRecording: "",
    },
    {
      name: "+916543210987",
      callDate: "03-06-2024",
      callStartTime: "11:00 a.m.",
      callEndTime: "11:10 a.m.",
      agentName: "Agent 4",
      callStatus: "Missed",
      callRecording: "",
    },
    {
      name: "+915432109876",
      callDate: "02-06-2024",
      callStartTime: "10:00 a.m.",
      callEndTime: "10:05 a.m.",
      agentName: "Agent 5",
      callStatus: "Answered",
      callRecording: "",
    },
    {
      name: "+914321098765",
      callDate: "02-06-2024",
      callStartTime: "9:45 a.m.",
      callEndTime: "9:50 a.m.",
      agentName: "Agent 6",
      callStatus: "Answered",
      callRecording: "",
    },
    {
      name: "+913210987654",
      callDate: "01-06-2024",
      callStartTime: "5:00 p.m.",
      callEndTime: "5:00 p.m.",
      agentName: "---",
      callStatus: "Not Answered",
      callRecording: "",
    },
    {
      name: "+912109876543",
      callDate: "01-06-2024",
      callStartTime: "4:30 p.m.",
      callEndTime: "4:32 p.m.",
      agentName: "---",
      callStatus: "Not Answered",
      callRecording: "",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Not Call Classification</h6>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text" placeholder="Search here" />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                value={selectedRows}
                onChange={handleSelectChange}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            <div className="booksIcon" style={{ textAlign: "center" }}>
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">Name / Phone Number</th>
                <th scope="col">Call Date</th>
                <th scope="col">Call Start Time</th>
                <th scope="col">Call End Time</th>
                <th scope="col">Agent Name</th>
                <th scope="col">Call Status</th>
                <th scope="col">MAKE CALL</th>
                <th scope="col">Call Recording</th>
                <th scope="col">Add User</th>
              </tr>
            </thead>
            <tbody>
              {crmInboundCallsData.map((data, index) => (
                <tr key={index} className="table-row-custom">
                  <td>{data.name}</td>
                  <td>{data.callDate}</td>
                  <td>{data.callStartTime}</td>
                  <td>{data.callEndTime}</td>
                  <td>{data.agentName}</td>
                  <td>{data.callStatus}</td>
                  <td>
                    <button className="custoBtnForCrmCall">Make Call</button>
                  </td>
                  <td>
                    {data.callRecording ? (
                      <audio controls>
                        <source src={data.callRecording} type="audio/mpeg" />
                      </audio>
                    ) : (
                      "Not Found"
                    )}
                  </td>
                  <td>
                    <button className="custoBtnForCrmCall">
                      <Link
                        to="/RegisterUserDetails"
                        className="custoBtnForCrmCallLink"
                      >
                        User register
                      </Link>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default NotCallClassification;
