import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

import ic_view from "../../assets/images/ic_view.svg";
import image_prf from "../../assets/images/image_prf.svg";
import DelectAc from "../../assets/images/DelectAc.svg";
import Editac from "../../assets/images/Editac.svg";
import disabled_edit from "../../assets/images/disabled_edit.svg";
import disabled_delete from "../../assets/images/disabled_delete.svg";
import letter from "../../assets/images/letter.svg";
import download_icon from "../../assets/images/download_icon.svg";
import download_icon2 from "../../assets/images/download_icon2.svg";
import { Accordion } from "react-bootstrap";

import ic_bars_dot from "../../assets/images/ic_bars_dot.svg";
import Logo from "../../assets/images/Logo.svg";
import img_logo_bottom from "../../assets/images/img_logo_bottom.png";
import img_logo_left from "../../assets/images/img_logo_left.png";
import img_logo from "../../assets/images/img_logo.svg";
import img_logo_top from "../../assets/images/img_logo_top.png";
import img_custom from "../../assets/images/img_custom.png";
import ic_zoom from "../../assets/images/ic_zoom.svg";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import ic_green_pen from "../../assets/images/ic_green_pen.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Up_arrow_white from "../../assets/images/Up_arrow_white.svg";
import { Button, Modal } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { AppContext } from "../../context/AppContext";
import Axe from "../../assets/images/x.svg";
import downArrow from "../../assets/images/downArrow.png";
import upArrow from "../../assets/images/upArrow.png";

const ProjectDetails = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [dropdownacc, setDropdownacc] = useState(true);
  // const [activeIndex, setActiveIndex] = useState(null);

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content-main">
          <div className="sub-header-role">
            <h6 className="mt-2">Project Documentation</h6>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            {/* <div className="role-table-header"></div> */}
          </div>

          {/* <div className='emptyPage'>
                        <img src={emptypage} alt="" />
                    </div> */}
          <div className="project-table-wrapper mb-4">
            <table>
              <tr className="project-table-row">
                <td>
                  Project Name :{" "}
                  <span className="project-name">Quack To Do</span>
                </td>
              </tr>
              <tr className="project-table-row">
                <td>
                  Project Links :{" "}
                  <Link to="#" className="project-links">
                    https://wififrontoffice.ui.redbytes.in/
                  </Link>
                </td>
              </tr>
              <tr className="project-table-row">
                <td>
                  Project Screens :{" "}
                  <Link to="#" className="project-links">
                    https://wififrontoffice.ui.redbytes.in/
                  </Link>
                </td>
              </tr>
              <tr className="project-table-row">
                <td>
                  Project APK :{" "}
                  <Link to="#" className="project-links">
                    https://wififrontoffice.ui.redbytes.in/
                  </Link>
                </td>
              </tr>
              <tr className="project-table-row">
                <td>
                  Project Web URL :{" "}
                  <Link to="#" className="project-links">
                    https://wififrontoffice.ui.redbytes.in/
                  </Link>
                </td>
              </tr>
              <tr className="project-table-row project-row-2">
                <td className="project-data">
                  <div className="scope">Project Scope :</div>
                  <div className="download-btn">
                    <span>Download</span>
                    <img src={download_icon2} alt="" />
                  </div>
                </td>
              </tr>
              <tr className="project-table-row project-row-2">
                <td className="project-data">
                  <div className="scope">Project Milestone :</div>
                  <div className="download-btn">
                    <span>Download</span>
                    <img src={download_icon2} alt="" />
                  </div>
                </td>
              </tr>
              <Accordion 
              defaultActiveKey="0"
              className="accordion-table">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="table-wrapper table-row-custom-padding">
                      <table>
                        <tr
                          className="table-row-custom bodypadding"
                          onClick={() => {
                            setDropdownacc(!dropdownacc);
                          }}
                        >
                          <td className="accordion-heading">
                            Project Members{" "}
                            <span className="team-member-count">6</span>
                          </td>
                          <td></td>
                          <td></td>
                          <td className="accordion-img">
                            &nbsp;
                            {dropdownacc == true ? (
                              <img src={downArrow} alt="" />
                            ) : (
                              <img src={upArrow} alt="" />
                            )}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="AccordianBody">
                      <table>
                        <tr className="table-row-custom bodypadding">
                          <td>Sr. No.</td>
                          <td>Employee Name</td>
                          <td>Team Name</td>
                          <td>Designation</td>
                        </tr>
                        <tr className="tabel-body-acordince">
                          <td>1</td>
                          <td>Member Name</td>
                          <td>Graphic Design</td>
                          <td>UX / UI Designer</td>
                        </tr>
                        <tr className="tabel-body-acordince">
                          <td>2</td>
                          <td>Member Name</td>
                          <td>Graphic Design</td>
                          <td>UX / UI Designer</td>
                        </tr>
                        <tr className="tabel-body-acordince">
                          <td>3</td>
                          <td>Member Name</td>
                          <td>Graphic Design</td>
                          <td>UX / UI Designer</td>
                        </tr>
                        <tr className="tabel-body-acordince">
                          <td>4</td>
                          <td>Member Name</td>
                          <td>Graphic Design</td>
                          <td>UX / UI Designer</td>
                        </tr>
                        <tr className="tabel-body-acordince">
                          <td>5</td>
                          <td>Member Name</td>
                          <td>Graphic Design</td>
                          <td>UX / UI Designer</td>
                        </tr>
                        <tr className="tabel-body-acordince">
                          <td>6</td>
                          <td>Member Name</td>
                          <td>Graphic Design</td>
                          <td>UX / UI Designer</td>
                        </tr>
                      </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </table>
          </div>
        </div>
        {/* <div className="d-flex justify-content-end align-items-center paginationMain"> */}
        {/* <label className="mb-2">Total Items :-</label> */}
        {/* <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            // pageCount={pageCount}
            // onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default ProjectDetails;
