import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Loader from "../Loader";
import { PostCallWithErrorResponse, simpleGetCallWithToken, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import { type } from "@testing-library/user-event/dist/type";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";

const TeamNotAssigned = () => {

  const { sidebar, setSidebar, Dark, setDark, userData,permissions } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [teamsData, setTeamsData] = useState([]);
  const [projectId, setProjectId] = useState(0);
  const [empName, setEmpName] = useState([]);

  const [excelLoading, setExcelLoading] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(5);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    teamsData &&
    teamsData.length &&
    teamsData.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );


    const downloadExcelFile = (e) => {
      setExcelLoading(true);
      e.preventDefault();
      simpleGetCallWithErrorResponse(ApiConfig.GET_TEAMNOTASSIGNEDLIST_EXPORT)
        .then((res) => {
          console.log("Download File Response", res);
          if (res.error) {
            console.log("Error response", res);
          } else {
            if (res.json.Success === true) {
              setExcelLoading(false);
              let data = res.json.Data;
              FileSaver.saveAs(
                "data:" + data.ContentType + ";base64," + data.FileContents,
                data.FileDownloadName
              );
            } else {
              console.log("res", res);
              setExcelLoading(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

  const fetchTeamsNotAssignedList = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_TEAMNOTASSIGNEDLIST_DATA)
      .then((res) => {
        if (res.Success === true) {
          setLoading(false);
          setTeamsData(res.Data);
        }
      })
      .catch((err) => {
        console.log(err)
      })
  };

  const fetchEmpName = () => {
    simpleGetCallWithToken(ApiConfig.GET_DROPDOWNEMPLOYEE_LIST)
      .then((res) => {
        console.log('ATTENDANCE_PATH==>', res)
        if (res.Success === true) {
          setEmpName(res.Data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const fetchQueryData = () => {
    let empId = JSON.parse(projectId) ? JSON.parse(projectId) : 0;
    simpleGetCallWithToken(ApiConfig.GET_TEAMNOTASSIGNEDLIST_DATA + `?employeeid=${empId}`)
      .then((res) => {
        if (res.Success === true) {
          setTeamsData(res.Data);
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleChange = () => {
    if (projectId !== 0) {
      fetchQueryData();
    }
    else {
      fetchTeamsNotAssignedList();
    }
  }

  useEffect(() => {
    fetchEmpName()
    // fetchData()
    fetchTeamsNotAssignedList();
  }, [])

  const displayEmployeeData = <table>
  <thead>
    <tr className="firstTabelRow">
      <th>Sr.no.</th>
      <th>Employee Name</th>
    </tr>
  </thead>
  <tbody>
    {
      teamsData.slice(startIndex, endIndex).map((data, index) => {
        return (
          <tr className="table-row-custom">
            <td>{startIndex + index + 1}</td>
            <td>{data.EmployeeName}</td>
          </tr>
        )
      })
    }
  </tbody>
</table>


const displaySearchEmployeeData = <table>
<thead>
  <tr className="firstTabelRow">
    <th>Employee Name</th>
  </tr>
</thead>
<tbody>
  {
    teamsData
    .filter(item => item.EmployeeName.toLowerCase().includes(searchValue.toLowerCase()))
    .slice(startIndex, endIndex).map((data) => {
      return (
        <tr className="table-row-custom">
          <td>{data.EmployeeName}</td>
        </tr>
      )
    })
  }
</tbody>
</table>

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role">
            <h6 className="mt-2">List of Employee - Team Not Assigned</h6>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
            <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search by Team Name"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              {/* <div className="search-input-wrapper">
                {/* <label htmlFor="" className="enter-role-text mt-2">
                  Employee Name
                </label> 
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setProjectId(e.target.value)
                  }}
                  onClick={handleChange}
                  name="projectId"
                  value={projectId}
                >
                  <option selected value="0">Select Employee Name</option>
                  {
                    empName.map((name, index) => {
                      return (
                        <option value={name.EmployeeId}>{name.Name}</option>
                      )
                    })
                  }
                </select>
              </div> */}
              <div className="entries-wrapper">
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
              </div>
              <div className="import-export-btn-wrapper">
                {
                  permissions&&permissions['Team Not Assigned Export']&&
                <button>
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={ic_excel}                      
                      onClick={(e) => downloadExcelFile(e)}
                      alt=""
                    />
                  )}
                </button>
                }
              </div>            
          </div>

          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            {
              loading ? (
                <Loader />
              ) :
                (
                  teamsData && teamsData.length > 0 ?
                    <>
                     {searchValue === "" ? displayEmployeeData : displaySearchEmployeeData}
                      {/* <table>
                        <thead>
                          <tr className="firstTabelRow">
                            <th>Employee Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            teamsData.slice(startIndex, endIndex).map((data) => {
                              return (
                                <tr className="table-row-custom">
                                  <td>{data.EmployeeName}</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table> */}
                    </>
                    :
                    <NoData />
                )
            }
          </div>
        </div>
        <Pagination
          data={teamsData}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
      </div>
    </>
  )
}

export default TeamNotAssigned;
