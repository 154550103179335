import Modal from "react-bootstrap/Modal";
import React, { useState, useContext, useEffect } from "react";
import "../../assets/css/tasklist.scss";
import searchicon from "../../assets/images/ic_search.svg";
import { AppContext } from "../../context/AppContext";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import ReactPaginate from "react-paginate";
import { async } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { json } from "react-router-dom";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import Loader from "../Loader";
import Pagination from "../../sharedComponent/Pagination";
import { getDateddmmyyyy } from "../../common";
import NoData from "../../sharedComponent/NoData";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const TeamTask = () => {
  const { sidebar, setSidebar, Dark, setDark, userData,permissions } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const [excelLoading, setExcelLoading] = useState(false);

  // States
  const [teamTaskList, setTeamTaskList] = useState([
    // {
    //   ResourceName: "Sayyed Irfan .",
    //   ProjectName: "Vehicle Tracking",
    //   TaskName: "UI Development",
    //   TaskStartDate: "2/1/2023 12:00:00 AM +05:30",
    //   TaskEndDate: "5/31/2023 12:00:00 AM +05:30",
    //   Status: "A",
    //   Hours: "200"
    // },
    // {
    //   ResourceName: "Sayyed Irfan .",
    //   ProjectName: "Vehicle Tracking",
    //   TaskName: "UI Development",
    //   TaskStartDate: "2/1/2023 12:00:00 AM +05:30",
    //   TaskEndDate: "5/31/2023 12:00:00 AM +05:30",
    //   Status: "A",
    //   Hours: "200"
    // },
    // {
    //   ResourceName: "Sayyed Irfan .",
    //   ProjectName: "Vehicle Tracking",
    //   TaskName: "UI Development",
    //   TaskStartDate: "2/1/2023 12:00:00 AM +05:30",
    //   TaskEndDate: "5/31/2023 12:00:00 AM +05:30",
    //   Status: "A",
    //   Hours: "200"
    // },
    // {
    //   ResourceName: "Sayyed Irfan .",
    //   ProjectName: "Vehicle Tracking",
    //   TaskName: "UI Development",
    //   TaskStartDate: "2/1/2023 12:00:00 AM +05:30",
    //   TaskEndDate: "5/31/2023 12:00:00 AM +05:30",
    //   Status: "A",
    //   Hours: "200"
    // },
  ]);
  // States

  useEffect(() => {
    getTeamTaskList();
  }, []);

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    simpleGetCallWithErrorResponse(ApiConfig.TEAMTASK_DOWNLOAD_EXCEL)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTeamTaskList = () => {
    setLoading(true);
    let payLoad = {
      EmpId: Number(userData.EmpId),
    };
    PostCallWithErrorResponse(
      ApiConfig.GET_TEAM_TASK_LIST,
      JSON.stringify(payLoad)
    ).then((res) => {
      setLoading(false);
      setTeamTaskList([...teamTaskList, ...res.json.Data]);
    });
  };

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(5);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    teamTaskList &&
    teamTaskList.length &&
    teamTaskList.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue.toLowerCase())
    );

  const displayData = teamTaskList
    .slice(startIndex, endIndex)
    .map((temtask, index) => {
      return (
        <tr className="table-row-custom" key={"sadfsfsf" + index}>
          <td id="sr-no-padding"> {startIndex + index + 1}</td>
          <td>{temtask.ProjectName} </td>
          <td> {temtask.ResourceName}</td>
          <td className="break-ln-spaces">{temtask.TaskName}</td>

          <td> {getDateddmmyyyy(temtask.TaskStartDate)}</td>
          <td>{getDateddmmyyyy(temtask.TaskEndDate)} </td>
          <td
            className={
              temtask.Status === "P"
                ? "pending-tabel-data"
                : temtask.Status === "A"
                ? "Approve-tabel-data"
                : temtask.Status === "R"
                ? "Rejected-tabel-data"
                : ""
            }
          >
            {temtask.Status === "P" ? (
              <span>Pending</span>
            ) : temtask.Status === "A" ? (
              <span>Approved</span>
            ) : temtask.Status === "R" ? (
              <span>Rejected</span>
            ) : (
              ""
            )}
          </td>
        </tr>
      );
    });

  const displaySearchedData = teamTaskList
    ?.filter((item) =>
      item?.ProjectName.toLowerCase().includes(searchValue.toLowerCase())
    )
    .slice(startIndex, endIndex)
    .map((temtask, index) => {
      return (
        <tr className="table-row-custom" key={"sadfsfsf" + index}>
          <td id="sr-no-padding"> {startIndex + index + 1}</td>
          <td>{temtask.ProjectName} </td>
          <td> {temtask.ResourceName}</td>
          <td className="break-ln-spaces">{temtask.TaskName}</td>
          {/* <td>{temtask?.AssignBy ? temtask?.AssignBy : ""}</td> */}
          <td> {getDateddmmyyyy(temtask.TaskStartDate)}</td>
          <td>{getDateddmmyyyy(temtask.TaskEndDate)} </td>
          <td
            className={
              temtask.Status === "P"
                ? "pending-tabel-data"
                : temtask.Status === "A"
                ? "Approve-tabel-data"
                : temtask.Status === "R"
                ? "Rejected-tabel-data"
                : ""
            }
          >
            {temtask.Status === "P" ? (
              <span>Pending</span>
            ) : temtask.Status === "A" ? (
              <span>Approved</span>
            ) : temtask.Status === "R" ? (
              <span>Rejected</span>
            ) : (
              ""
            )}
          </td>
        </tr>
      );
    });

  return (
    <>
      {/* <Header />
            <Sidebar /> */}
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Team Task</h6>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search By ProjectName..."
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                  // onChange={(e) =>
                  //   setTeamTaskObj({
                  //     ...teamTaskObj,
                  //     PageSize: Number(e.target.value),
                  //   })
                  // }
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
            </div>
            {
              permissions&&permissions['Team Task Export']&&           
            <div className="import-export-btn-wrapper">
              <button className="booksIcon">
                {excelLoading ? (
                  <ExcelLoader />
                ) : (
                  <img
                    src={ic_excel}
                    onClick={(e) => downloadExcelFile(e)}
                    alt=""
                  />
                )}
              </button>
            </div>
            }
          </div>
          {/* <div className='emptyPage'>
                        <img src={emptypage} alt="" />
                    </div> */}
          {loading ? (
            <Loader />
          ) : (
            <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
              {displaySearchedData.length > 0 &&
              teamTaskList &&
              searchedDataPageCounts.length > 0 ? (
                <table>
                  <tr className="firstTabelRow">
                    <th>Sr. no</th>
                    <th>Project Name</th>
                    <th>Team Member</th>
                    <th>Task Name</th>

                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Status</th>
                  </tr>
                  {searchValue === "" ? displayData : displaySearchedData}
                </table>
              ) : (
                <>
                  <NoData />
                </>
              )}
            </div>
          )}

          {!loading && searchedDataPageCounts.length > usersPerPage && (
            <Pagination
              data={teamTaskList}
              pageChangeHandler={pageChangeHandler}
              usersPerPage={usersPerPage}
              currentPage={currentPage}
              searchValue={searchValue}
              searchedDataPageCounts={searchedDataPageCounts}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TeamTask;
