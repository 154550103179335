import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import NoData from "../../sharedComponent/NoData";
import DelectAc from "../../assets/images/DelectAc.svg";
import Editac from "../../assets/images/Editac.svg";
import Multiselect from "multiselect-react-dropdown";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { Form } from "react-bootstrap";
import { notifyError, notifySuccess } from "../../toaster/notify";
import ReactPaginate from "react-paginate";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import BackDropLoader from "./../../sharedComponent/BackDropLoader";
import Pagination from "../../sharedComponent/Pagination";

const ProjectAssignment = () => {
  const { sidebar, Dark,permissions } = useContext(AppContext);

  const [validated, setValidated] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employeeNames, setEmployeeNames] = useState([]);
  const [projectsName, setProjectsName] = useState([]);
  const [assignedProjectsList, setAssignedProjectsList] = useState([]);
  const [assignData, setAssignData] = useState({
    EmployeeId: [],
    projectId: 0,
    FromDate: "",
    ToDate: "",
    IsPartTime: "",
    BudgetHours: 0,
  });
  const [openModal, setOpenModal] = useState(false);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () =>{ 
    setShow3(false)}
  const handleShow3 = () => setShow3(true);
  const [useDeleteTaskList, setDeleteTaskList] = useState('');

  const [assignOpen,setOpenAssign] = useState()

  const [deAssignDetails,setDeAssignDetails] = useState(
    {
      projectName:'',
      employeeName:''
    }
  )

  const minOffset = 0
  const maxOffset = 1
  let crryear = new Date().getFullYear();
  let allYears = []
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(crryear - x)
  }
  const [monthArr, setMonthArr] = useState([
    { "value": '1', "label": "January" },
    { "value": '2', "label": "February" },
    { "value": '3', "label": "March" },
    { "value": '4', "label": "April" },
    { "value": '5', "label": "May" },
    { "value": '6', "label": "June" },
    { "value": '7', "label": "July" },
    { "value": '8', "label": "August" },
    { "value": '9', "label": "September" },
    { "value": '10', "label": "October" },
    { "value": '11', "label": "November" },
    { "value": '12', "label": "December" }
  ]);

  const [attendancePayLoad, setAttendancePayLoad] = useState({
    // EmpId: Number(userData.EmpId),
    // OrgId: Number(userData.OrgId),
    Month: new Date().getMonth() + 1,
    Year: 1
  })

  console.log("assignData,", assignData)
  const onSelect = (selectedList) => {
    let employeeIdArr =
      selectedList &&
      selectedList.map((item) => {
        return item.id;
      });
    setAssignData({
      ...assignData,
      EmployeeId: employeeIdArr,
    });
    setEmployeeNameErrMsg("");
  };
  const onRemove = (selectedList, removedItem) => {
    console.log("selectedList", selectedList, "removedItem", removedItem);
    let employeeIdArr =
      selectedList &&
      selectedList.map((item) => {
        return item.id;
      });
    setAssignData({
      ...assignData,
      EmployeeId: employeeIdArr,
    });
  };

  useEffect(() => {
    getProjectNamesForDropdown();
    getEmployeeNames();
  }, []);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getAssignedProjectList();
  }, [searchValue]);

  const getAssignedProjectList = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_ASSIGNED_PROJECT_LIST)
      .then((res) => {
        if (res.Success === true) {
          if (searchValue !== "") {
            let filteredSearchData =
              res.Data &&
              res.Data.filter((item) => {
                //return item.EmployeeName?.toLowerCase().includes(searchValue);
                return (
                  searchValue &&
                  item.EmployeeName &&
                  item.EmployeeName?.toLowerCase().includes(searchValue.toLowerCase())
                );
              });
            setAssignedProjectsList(filteredSearchData);
          } else {
            setAssignedProjectsList(res.Data);
          }
        }
      })
      .catch((err) => {
        console.log("error,", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProjectNamesForDropdown = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_PROJECTS_NAMES)
      .then((res) => {
        if (res.Success === true) {
          setProjectsName(res.Data);
        }
      })
      .catch((err) => {
        console.log("error,", err);
      });
  };

  const getEmployeeNames = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_DROPDOWN_EMPLOYEE_NAMES)
      .then((res) => {
        if (res.Success === true) {
          const tempArr =
            res.Data &&
            res.Data.map((item) => {
              return {
                value: item.Id,
                label: item.FirstName,
              };
            });
          tempArr && setEmployeeNames(tempArr);
        }
        else
        {
          notifyError(res.json.Message);
        }
      })
      .catch((err) => {
        console.log("error,", err);
      });
  };
  const [errEmployeeNameMsg, setEmployeeNameErrMsg] = useState("");

  const assignProject = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else if (assignData.EmployeeId.length === 0) {
      setEmployeeNameErrMsg("Employee name is required");
    } else {
      setBtnLoader(true);
      PostCallWithErrorResponse(
        ApiConfig.ASSIGN_PROJECT,
        JSON.stringify(assignData)
      )
        .then((res) => {
          if (res.json.Success === true  ) {
            setOpenModal(false)
            notifySuccess(res.json.Message);
            getAssignedProjectList();
            setAssignData({
              EmployeeId: [],
              projectId: 0,
              FromDate: "",
              ToDate: "",
              IsPartTime: "",
            });
          } else {
            notifyError(res.json.Message);
            console.log("error response", res);
          }
        })
        .catch((err) => {
      
          console.log("error,", err);
        })
        .finally(() => {
          setBtnLoader(false);
        });
    }
    // 
  };

  const [deassignLoader, setDeassignLoader] = useState(false);

  const deassignProject = (assignId) => {
    setDeassignLoader(true);
    let payLoad = {
      AssignementId: assignId,
    };
    PostCallWithErrorResponse(
      ApiConfig.DEASSIGN_PROJECT,
      JSON.stringify(payLoad)
    )
      .then((res) => {
        if (res.json.Success === true) {
          notifySuccess("Deassign successfully");
          getAssignedProjectList();
        } else {
          console.log("error response", res);
        }
      })
      .catch((err) => {
        console.log("error,", err);
      })
      .finally(() => {
        setDeassignLoader(false);
      });
  };

  // const [currentPage, setCurrentPage] = useState(0);
  // const startIndex = currentPage * 10;
  // const endIndex = startIndex + 10;

  // const pageChangeHandler = ({ selected }) => {
  //   setCurrentPage(selected);
  // };  assignedProjectsList 
  const searchedDataPageCounts =
    assignedProjectsList &&
    assignedProjectsList.length &&
    assignedProjectsList.filter(item =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    )
  const [currentPage, setCurrentPage] = useState(0)
  const [usersPerPage, setUsersPerPage] = useState(5)

  const startIndex = currentPage * usersPerPage
  const endIndex = startIndex + parseInt(usersPerPage)

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected)
  }

  return (
    <>
      {btnLoader && <BackDropLoader />}
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">Assign Project To Employee</h6>
            {
              permissions&&permissions['Assign Project']&&
            <button className="allocateionBtn" onClick={() => setOpenModal(true)}>Assign Project</button>
            }
          </div>
          <div className="d-flex justify-content-between mainHeader custom-mb">
            <div className="role-table-header-main">
              <div className="search-input-wrapper2">
                <input
                  type="text"
                  placeholder="Search Employee"
                  className="tasKCategory1"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className='entries-wrapper-main'>
                <p>Entries per page </p>&nbsp;
                <select
                  className='form-select select-drop-icon'
                  aria-label='Default select example'
                  onChange={e => {
                    setUsersPerPage(e.target.value)
                    setCurrentPage(0)
                  }}
                >
                  <option value='5'>5</option>
                  <option value='10'>10</option>
                  <option value='15'>15</option>
                  <option value='20'>20</option>
                  <option value='25'>25</option>
                </select>
              </div>
            </div>
            {/* <div className="col-lg-2">
              <div className='search-input-wrapper-main'>
                <select
                  className='form-select input-select-option'
                  aria-label='Default select example'
                  onChange={e => {
                    // setYear(Number(e.target.value) + 1).toString();
                    setAttendancePayLoad({
                      ...attendancePayLoad,
                      Year: Number(e.target.value) + 1
                    })
                  }}
                  value={attendancePayLoad.Year - 1}
                >
                  {/* <option value=''>Select Year</option> */}
                  {/*{allYears.map((year, i) => {
                    return (
                      <option key={i} value={i}>
                        {year}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div> */}
            {/* <div className="col-lg-2">
              <div className='search-input-wrapper-main'>
                <select
                  className='form-select input-select-option'
                  aria-label='Default select example'
                  onChange={e => {
                    // setMonth(e.target.value);
                    setAttendancePayLoad({
                      ...attendancePayLoad,
                      Month: Number(e.target.value)
                    })
                  }}

                  value={attendancePayLoad.Month}
                >
                  {/* <option value=''>Select Month</option> */}
                   {/*{
                    monthArr && monthArr.map((data, index) => {
                      return (
                        <option value={data?.value} key={index}>{data?.label}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div> */}
          </div>
          <div className="table-wrapper" id="scrol-tbl">
            <table >
              <thead>
                <tr className="firstTabelRow">
                  <th>Sr.No</th>
                  <th>Employee Name</th>
                  <th>Project Name</th>
                  <th>Project Admin</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Estimated Hours</th>
                  <th>Budget Hours</th>
                  <th>Actual Hours</th>
                  <th>Is Internal</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {assignedProjectsList &&
                  assignedProjectsList
                    .slice(startIndex, endIndex)
                    .map((item, i) => {
                      return (
                        <tr
                          key={"assignedProjectsList" + i}
                          className="table-row-custom"
                        >
                          <td>{startIndex + i + 1}</td>
                          <td>{item?.EmployeeName}</td>
                          <td>{item?.ProjectName}</td>
                          <td>{item?.ProjectAdmin}</td>
                          <td>{item?.StartDate}</td>
                          <td>{item?.EndDate}</td>
                          <td>{item?.EstimatedHours}</td>
                          <td>{item?.BudgetHours}</td>
                          <td>{item?.ActualHours}</td>
                          <td>{item?.IsInternal ? "Internal" : "External"}</td>
                          <td>
                            {
                              permissions&&permissions['De-Assign Project']&&
                            <button
                              className="deassignBtn"
                              onClick={() => {
                                // deassignProject(item.ProjectAssignmentID)
                                handleShow3()
                                setDeleteTaskList(item.ProjectAssignmentID)
                                setDeAssignDetails({
                                  projectName:item?.ProjectName?item.ProjectName:'Project Name',
                                  employeeName:item?.EmployeeName?item.EmployeeName:"Employee Name"
                                })
                              }}
                            >
                              De-Assign
                            </button>
                            }
                            {/* <img src={Editac} className="mx-2 editBtn" alt="" />
                                        <img src={DelectAc} className="deleteBtn" alt="" /> */}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
              {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
            </table>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={Math.ceil(assignedProjectsList?.length / 10)}
                onPageChange={pageChangeHandler}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              //   forcePage={currentPage}
              />
              {/* <ReactPaginate                
                data={assignedProjectsList}
                pageChangeHandler={pageChangeHandler}
                usersPerPage={usersPerPage}
                currentPage={currentPage}
                searchValue={searchValue}
                searchedDataPageCounts={searchedDataPageCounts}
              /> */}
            </div>
          </div>
        </div>
      </div>
      {/* De-Assign News and Blogs Modal Start */}
      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            De-Assign Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to De-Assing {`( ${deAssignDetails.projectName} from ${deAssignDetails.employeeName} )`}?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button className="btn-width saveBtn"
            onClick={() => {
              deassignProject(useDeleteTaskList)
              handleClose3()
            }}
          >De-Assign</button>
        </div>
      </Modal>
      {/* Assign Employe modal */}
      <Modal
        className="delete-news-modal"
        size="xl"
        show={openModal}
        onHide={() => setOpenModal(false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Assign Project
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <Form noValidate validated={validated} onSubmit={assignProject }>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                <div className="projectMenu">
                  <label htmlFor="" className="labelForASsign">
                    Project Name
                  </label>
                  <select
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    value={assignData.projectId}
                    onChange={(e) => {
                      setAssignData({
                        ...assignData,
                        projectId: Number(e.target.value),
                      });
                    }}
                    required
                  >
                    <option value={""}>Please select project name</option>
                    {projectsName &&
                      projectsName.map((name, i) => {
                        return (
                          <option
                            key={i + "projectsName"}
                            value={name.ProjectId}
                          >
                            {name.ProjectName}
                          </option>
                        );
                      })}
                  </select>
                  <Form.Control.Feedback type="invalid">
                    Project name is required
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                <div className="projectMenu">
                  <label htmlFor="" className="labelForASsign">
                    Employee Name
                  </label>
                  <Select
                    className=""
                    options={employeeNames}
                    value={employeeNames.filter((item) => assignData.EmployeeId.includes(item.value))}
                    isMulti
                    onChange={(e) => {

                      let employeeIdArr =
                        e &&
                        e.map((item) => {
                          return item.value;
                        });
                      setAssignData({
                        ...assignData,
                        EmployeeId: employeeIdArr,
                      });
                      setEmployeeNameErrMsg("");
                    }}
                  />
                  {/* <Multiselect
                    options={employeeNames}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue="name"
                    closeOnSelect="false"
                    className="form-select tasKCategory1 select-drop-icon"
                    // required
                  /> */}
                  {errEmployeeNameMsg && (
                    <div style={{ color: "#dc3545", fontSize: "0.875em" }}>
                      {errEmployeeNameMsg}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12  col-sm-12 mb-3">
                <div className="projectMenu">
                  <label htmlFor="" className="labelForASsign">
                    From Date
                  </label>
                  <input
                    type="date"
                    className="form-control tasKCategory2" id="date-picker"
                    value={assignData.FromDate}
                    onChange={(e) => {
                      setAssignData({
                        ...assignData,
                        FromDate: e.target.value,
                      });
                    }}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Start date is required
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 mb-3">
                <div className="projectMenu">
                  <label htmlFor="" className="labelForASsign">
                    To Date
                  </label>
                  <input
                    type="date"
                    className="form-control tasKCategory2"
                    value={assignData.ToDate}
                    onChange={(e) => {
                      setAssignData({
                        ...assignData,
                        ToDate: e.target.value,
                      });
                    }}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    End date is required
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="col-md-6">
                <label
                  htmlFor=""
                  className="enter-role-text mt-2"
                  style={{ fontSize: "12.8px" }}
                >
                  Budget Hours
                </label>
                <input
                  onChange={(e) => {
                    const { value } = e.target
                    setAssignData({
                      ...assignData,
                      BudgetHours: Number(value),

                    });
                  }}
                  name="budgethours"
                  // value={adminPostData.budgethours}
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                    borderRadius: "8px"
                  }}
                  required
                />
              </div>
              <div className="col-md-6">
                <div className="projectMenu">
                  <label htmlFor="" className="labelForASsign">
                    Project Type
                  </label>
                  <div className="d-flex customizeMedia">
                    <div class="form-check me-3">
                      <input
                        class="form-check-input"
                        //checked={assignData.IsPartTime}                        
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        onChange={(e) => {
                          setAssignData({
                            ...assignData,
                            IsPartTime: e.target.checked,
                          });
                        }}
                        required
                      />
                      <label
                        class="form-check-label labelDiff"
                        for="flexRadioDefault1"
                      >
                        Part Time
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        //checked={!assignData.IsPartTime}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        onChange={(e) => {
                          setAssignData({
                            ...assignData,
                            IsPartTime: !e.target.checked,
                          });
                        }}
                        required
                      />
                      <label
                        class="form-check-label labelDiff"
                        for="flexRadioDefault2"
                      >
                        Full Time
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 text-end">            
                <button
                  type="submit"
                  className="allocateionBtn btn-width"
                  disabled={btnLoader ? true : false}
                 // onClick={() =>  setOpenModal(false)}
                >
                  Assign
                </button>
              </div>
          </Form>         
        </Modal.Body>
        </Modal>
        
      {/* De-Assign News and Blogs Modal Start */}
      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            De-Assign Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to De-Assing {`( ${deAssignDetails.projectName} from ${deAssignDetails.employeeName} )`}?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width saveBtn"
          onClick={() => {
            deassignProject(useDeleteTaskList)
            handleClose3()
          }}
          >De-Assign</button>
        </div>
      </Modal>
    </>
  );
};

export default ProjectAssignment;
