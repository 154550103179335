import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";

const TransactionReceipt = () => {
    const { sidebar, setSidebar, Dark, setDark, userData } = useContext(AppContext);
    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="employee_transaction_receipt">
                    <div className="sub-header-role mb-4">
                        <h6 className="mt-2"> <Link to="/WalletSales">Employee Wallet</Link> / Transaction Receipt </h6>
                    </div>
                    <div className="contentBox">
                        <div className="tableBordered">
                            <div className="topFirst">
                                <div className="firstLeft">
                                    <h1>Transaction Receipt</h1>
                                </div>
                                <div className="firstRight">
                                    <h1>Date & Time : <label>29-02-2024:10:10:00PM</label></h1>
                                </div>
                            </div>
                            <div className="Second">
                                <div className="firstLeft">
                                    <h1>Company Name</h1>
                                </div>
                                <div className="firstRight">
                                    <label>Grand Collage Market</label>
                                </div>
                            </div>
                            <div className="Third">
                                <h1>Employee : <label>Abdo Abou-Antoun - 403</label></h1>
                                <h1>Class : <label>CM1</label></h1>
                                <h1>Division : <label>A</label></h1>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>CATEGORY</th>
                                        <th>ITEM</th>
                                        <th>QTY</th>
                                        <th>PRICE</th>
                                        <th>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Snacks</td>
                                        <td>Carottes</td>
                                        <td>1</td>
                                        <td>800.JJ</td>
                                        <td>800.JJ</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="subTotal">Sub Total</td>
                                        <td>800.JJ</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <button className="printBtn">Print</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TransactionReceipt;
