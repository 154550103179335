import BarChart from "../../component/Barchart.js";
import React, { useContext, useEffect, useState } from "react";
import deleteicon from "../../assets/images/delete_icon_gray.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const AgentcallList = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const dataArray = [
    { id: 1, name: "Call", Count: "23232" },
    { id: 2, name: "Users", Count: "23232" },
    { id: 3, name: "Not Call Classification", Count: "23232" },
    { id: 4, name: "Zero Call List", Count: "23232" },
    { id: 5, name: "Candidates", Count: "23232" },
    { id: 6, name: "Services Request", Count: "23232" },
    { id: 7, name: "Agents", Count: "23232" },
  ];

  const dataArrayLocation = [
    {
      id: 1,
      NAME: "Thushara",
      PRIORITY: "2",

      Action: "Delete",
    },

    {
      id: 2,
      NAME: "Thushara",
      PRIORITY: "2",

      Action: "Delete",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate the displayed items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataArrayLocation.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(dataArrayLocation.length / itemsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role ">
          <h6 className="mt-2">Agent's List And There Priority</h6>
          <button className="custoBtnForCrmCall">
            <a className=" textcolor" href="UserdetailsAgent">
              Add Priority
            </a>
          </button>
        </div>

        <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
          <div className="search-input-wrapper">
            <input type="text" placeholder="Search here" />

            {Dark === "lightMode" ? (
              <img src={searchicon} alt="" />
            ) : (
              <img src={ic_search_dark} alt="" />
            )}
          </div>

          <div className="entries-wrapper">
            <p>Entries per page</p>
            <select
              className="form-select select-drop-icon"
              aria-label="Default select example"
              value={selectedRows}
              onChange={handleSelectChange}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <div class="table-wrapper table-wrapper-2">
          <table className="">
            <thead className="firstTabelRow">
              <tr classname="row-hover modal-footer-table-body-tr">
                <th>NAME</th>
                <th>PRIORITY</th>
                <th>DELETE</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.id} className="table-row-custom">
                  <td>{item.NAME}</td>
                  <td>{item.PRIORITY}</td>

                  <td>
                    <Link to="" onClick={handleShow3}>
                      <img
                        src={deleteicon}
                        alt="Image 2"
                        className="cell-image "
                      />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        className="delete-news-modal"
        show={show3}
        onHide={handleClose3}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure you want to delete this Agent List ?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose3}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              handleClose3();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AgentcallList;
