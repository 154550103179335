import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import { simpleGetCallWithToken, simpleGetCallWithErrorResponse,  PostCallWithAuthToken} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import searchicon from "../../assets/images/search_icon.svg";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";


const UserNotLogin = () =>
{
    const { sidebar}=useContext(AppContext);
    const [userLogin, SetUserLogin] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [usersPerPage, setUsersPerPage] = useState(5);
    const startIndex = currentPage * usersPerPage;
    const endIndex = startIndex + parseInt(usersPerPage);
  
    const [loading, setLoading] = useState(false);
    
    const pageChangeHandler = ({ selected }) => {
        setCurrentPage(selected);
    };

    const FetchUsernotlogin = () => {
         setLoading(true);
        simpleGetCallWithToken(ApiConfig.GET_USER_NOTLOGIN)
            .then((res) => {
                if (res.Success === true) {
                    setLoading(false);
                    SetUserLogin(res.Data);
                    console.log("User not logged in++",res.Data);   
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    useState(() => {
        FetchUsernotlogin()
    }, [])

    const [excelLoading, setExcelLoading] = useState(false);

    const downLoadExcelFile = (e) => {
      setExcelLoading(true);
      e.preventDefault();
      simpleGetCallWithErrorResponse(ApiConfig.GET_USER_NOTLOGIN_EXPORT)
        .then((res) => {
          console.log("Download File Response", res);
          if (res.error) {
            console.log("Error response", res);
          } else {
            if (res.json.Success === true) {
              setExcelLoading(false);
              let data = res.json.Data;
              FileSaver.saveAs(
                "data:" + data.ContentType + ";base64," + data.FileContents,
                data.FileDownloadName
              );
            } else {
              console.log("res", res);
              setExcelLoading(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    
   

    return (
        <>
            <div
                className={ sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="role-content">
                    <div className="sub-header-role ">
                        <h6 className="mt-2">List of Employee - User Not Login</h6>
                    </div>
                    <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
                        <div className="role-table-header">
                            <div className="entries-wrapper">
                                <p>Entries per page</p>
                                <select
                                    className="form-select select-drop-icon"
                                    aria-label="Default select example"
                                    onChange={(e) => {
                                        setUsersPerPage(e.target.value);
                                        setCurrentPage(0);
                                    }}
                                >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                </select>
                            </div>                            
                        </div>
                        <div className="import-export-btn-wrapper mt-4">
                            <button onClick={downLoadExcelFile}>
                                {excelLoading ? (
                                    <ExcelLoader />
                                ) : (
                                    <img
                                        src={ic_excel}
                                      
                                        alt=""
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="table-wrapper">
                        { 
                        loading ? ( <Loader/>) :
                        (
                                userLogin && userLogin.length >0 ?
                                <table>
                                    <thead>
                                        <tr className="firstTabelRow">
                                            <></>
                                            <th>Sr.no</th>
                                            <th>Employee Name</th>
                                            <th> Last Login </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            // userLogin.map(data =>
                                            // { <tr className="table-row-custom">
                                            //    <td>{data.Name}</td>
                                            //    </tr>
                                            // })
                                            userLogin.map(( data,index) => 
                                            <tr className="table-row-custom"> 
                                            <td>{index +1 }</td>
                                            <td>{data.Name}</td>
                                            <td>{data.LoginTime}</td>
                                            </tr>)
                                         
                                        }
                                    </tbody>
                                </table>
                                :
                                <NoData/>
                        )
                        
                        }
                    </div>
                </div>
                <Pagination
                    data={userLogin}
                    pageChangeHandler={pageChangeHandler}
                    usersPerPage={usersPerPage}
                    currentPage={currentPage}
                    // searchValue={searchValue}
                    // searchedDataPageCounts={searchedDataPageCounts}
                />
            </div>
        </>
    )
}



export default UserNotLogin;