import React, { useState, useContext, useEffect, useRef } from 'react'
import { AppContext } from '../../context/AppContext'
import optionIcon from '../../assets/images/bx_edit.svg'
import shareIcon from '../../assets/images/ic_export1.svg'
import BookIcon from '../../assets/images/ic_excel.svg'
import '../../assets/css/myAttendence.scss'
import ReactPaginate from 'react-paginate'
import { PostCallWithErrorResponse } from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import { json } from 'react-router-dom'
import Loader from '../Loader'
import FileSaver from 'file-saver'
import ExcelLoader from '../../sharedComponent/ExcelLoader'
import { getDDateddmmyyyy, minutesInHors } from '../../common'
import NoData from '../../sharedComponent/NoData'

const MyAttendence = () => {
  const { sidebar, setSidebar, Dark, setDark, userData,permissions } =
    useContext(AppContext)
  const [attendanceList, setAttendanceList] = useState([])
  const [DataShift, setDataShift] = useState([])
  const [loading, setLoading] = useState(false)
  const [excelLoading, setExcelLoading] = useState(false)
  const minOffset = 0
  const maxOffset = 1
  let crryear = new Date().getFullYear();
  let allYears = []
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(crryear - x)
  }
  const [monthArr, setMonthArr] = useState([
    { "value": '1', "label": "January" },
    { "value": '2', "label": "February" },
    { "value": '3', "label": "March" },
    { "value": '4', "label": "April" },
    { "value": '5', "label": "May" },
    { "value": '6', "label": "June" },
    { "value": '7', "label": "July" },
    { "value": '8', "label": "August" },
    { "value": '9', "label": "September" },
    { "value": '10', "label": "October" },
    { "value": '11', "label": "November" },
    { "value": '12', "label": "December" }
  ]);

  const [attendancePayLoad, setAttendancePayLoad] = useState({
    EmpId: Number(userData.EmpId),
    OrgId: Number(userData.OrgId),
    Month: new Date().getMonth() + 1,
    Year: 1
  })

  useEffect(() => {
    getAttendanceList()
  }, [attendancePayLoad])
  const getAttendanceList = () => {
    setLoading(false)
    if (attendancePayLoad.Month >= 1 && attendancePayLoad.Year) {
      setLoading(true)
      PostCallWithErrorResponse(
        ApiConfig.ATTENDANCE_LIST,
        JSON.stringify(attendancePayLoad)
      )
        .then(res => {
        console.log("Shift time data ------>>>", res.json?.Data.ShiftTime)

          setLoading(false)
          setAttendanceList(
            res.json?.Data.Attendance ? res.json?.Data.Attendance : []
          )
          setDataShift(
            res.json?.Data.ShiftTime ? res.json?.Data.ShiftTime : []
          )
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const downLoadExcelSheet = () => {
    setExcelLoading(true)
    let body = {
      EmpId: Number(userData.EmpId),
      OrgId: Number(userData.OrgId),
      Month: attendancePayLoad.Month,
      Year: attendancePayLoad.Year,
      ExportToExcel: true
    }
    PostCallWithErrorResponse(ApiConfig.ATTENDANCE_LIST, JSON.stringify(body))
      .then(res => {
        console.log('res', res)
        if (res.error) {
          console.log('Error response', res)
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false)
            let data = res.json.Data
            FileSaver.saveAs(
              'data:' + data.ContentType + ';base64,' + data.FileContents,
              data.FileDownloadName
            )
          } else {
            console.log('res', res)
            setExcelLoading(false)
          }
        }
      })
      .catch(err => {
        setExcelLoading(false)
        console.log(err)
      })
  }

  return (
    <>
      <div
        className={sidebar ? 'taskMain ' : 'cx-active taskMain'}
        id='cx-main'
      >
        <div className='role-content-main'>
          <div className='sub-header-role mb-4'>
            <h6 className='mt-2'>My Attendance </h6>
          </div>
          <div className='d-flex justify-content-between mainHeader custom-mb'>
            <div className='role-table-header-main'>
              <div className='search-input-wrapper-main'>
                <select
                  className='form-select input-select-option'
                  aria-label='Default select example'
                  onChange={e => {
                    // setYear(Number(e.target.value) + 1).toString();
                    setAttendancePayLoad({
                      ...attendancePayLoad,
                      Year: Number(e.target.value) + 1
                    })
                  }}
                  value={attendancePayLoad.Year - 1}
                >
                  {/* <option value=''>Select Year</option> */}
                  {allYears.map((year, i) => {
                    return (
                      <option key={i} value={i}>
                        {year}
                      </option>
                    )
                  })}
                </select>
              </div>
              <div className='search-input-wrapper-main mx-4'>
                <select
                  className='form-select input-select-option'
                  aria-label='Default select example'
                  onChange={e => {
                    // setMonth(e.target.value);
                    setAttendancePayLoad({
                      ...attendancePayLoad,
                      Month: Number(e.target.value)
                    })
                  }}

                  value={attendancePayLoad.Month}
                >
                  {/* <option value=''>Select Month</option> */}
                  {
                    monthArr && monthArr.map((data, index) => {
                      return (
                        <option value={data?.value} key={index}>{data?.label}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
            <div className='d-flex justify-content-between mainIcons'>
              {/* <div className="shareIcon mx-3">
                <img src={shareIcon} className="innerIconsBtn" alt="" />
              </div> */}
              {
                permissions&&permissions['My Attendance Export']&&
                <div className='booksIcon' style={{ textAlign: 'center' }}>
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={BookIcon}
                      className='innerIconsBtn'
                      alt=''
                      onClick={e => {
                        downLoadExcelSheet()
                      }}
                    />
                  )}
                </div>
              }
            </div>
          </div>
          {/* <div className='emptyPage'>
                        <img src={emptypage} alt="" />
                    </div> */}
          <div className='table-wrapper-main mb-4'>
            {loading ? (
              <Loader />
            ) : (
              <table>
                <thead>
                  <tr className='firstTabelRow'>
                    <th>Sr. No</th>
                    <th>Date</th>
                    <th>Shift Timing</th>
                    <th>Check In</th>
                    <th>Check Out</th>
                    <th>Total Hours</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {attendanceList && attendanceList.length > 0 ? (
                    attendanceList.map((attend, index) => {                     
                      return (
                        <tr
                          key={attend?.AttendanceId && attend?.AttendanceId}
                          className='table-row-main'
                        >
                          <td>{index + 1}</td>
                          <td>{getDDateddmmyyyy(attend.InTime)}</td>
                          <td>{(DataShift?.InTime?.split('T')[1].substring(0, 5))} -{(DataShift?.OutTime?.split('T')[1].substring(0, 5))} </td>
                          {/* <td>10 to 7</td> */}
                          <td>{attend.PunchIn}
                          </td>
                          <td>{attend.PunchOut} </td>

                          <td>{attend.DayTotal} </td>
                          <td
                            className={
                              attend.Remark === 'Absent'
                                ? 'status-label-danger'
                                : attend.Remark === 'Present'
                                  ? 'status-label-green'
                                  : attend.Remark === 'Weekend'
                                    ? 'status-grey'
                                    : attend.Remark === 'Leave'
                                      ? 'status-h-leave'
                                      : attend.Remark === "Half day Leave"
                                        ? 'status-h-leave'
                                        : 'status-label-grey'
                            }
                          >
                            {attend.Remark}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <>
                      <tr className='ErrorDataNotFound'>
                        <td colSpan={6}>
                          <NoData />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default MyAttendence
