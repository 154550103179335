import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";

export default function UserdetailsAgent() {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role ">
          <h6 className="mt-2">User Details</h6>
        </div>

        <div className="WeeklyMain">
          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Agent Priority :
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <input
                  //   value={newPassword}
                  placeholder="Agent Priorit"
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    //   fontSize: "14px",
                    height: "38px",
                    borderRadius: "10px",
                  }}
                  //   onChange={(e) => {
                  //     setNewPassword(e.target.value);
                  //   }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Agent :
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <select
                  className="tasKshowusser"
                  // onChange={(e) => setService(e.target.value)}
                >
                  <option>Select Agent</option>
                  <option>74737284748(Thushara)</option>
                  <option>983943939(Shobhisha)</option>
                </select>
              </div>
            </div>
          </div>

          <div className="align-items-center justify-content-center d-flex mt-5 mb-3">
            <button className="custoBtnForCrmCall">
              <a className=" textcolor" href="AgentCallSummary">
                Add
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
