import React from "react";

const BackDropLoader = () => {
  return (
    <div id="backdrop">
      <div class="center-loading loading">
        <div class="spinner-border text-light" role="status">
        </div>
      </div>
    </div>
  );
};

export default BackDropLoader;
