import moment from 'moment'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router'
import ApiConfig, { BASE_URL_CHAT_Image } from '../../api/ApiConfig'
import ic_come_back from '../../assets/images/chat/ic_come_back.svg'
import profile_img from '../../assets/images/profile_img.svg'
import { AppContext } from '../../context/AppContext'
export default function CallHystoryComp ({ callHistory, missed }) {
  const {
    setCallPage,
    callPage,
    pinnedChats,
    setCurrentSChannel,
    setActiveChat,
    recentChats,
    projects,
    CallLoadMore
  } = useContext(AppContext)
  const navigate = useNavigate()
  return (
    <div className='callerlistWrapper'>
      {callHistory.map((call, index) => {
        var parameter_Start_index =
          call.call_start_time && call.call_start_time.indexOf('.')
        let startTime =
          call.call_start_time.substring(0, parameter_Start_index) + '.209Z'
        let parameter_End_index =
          call.call_end_time && call.call_end_time.indexOf('.')
        let endTime =
          call.call_end_time &&
          call.call_end_time.substring(0, parameter_End_index) + '.209Z'
        var now = moment(new Date(endTime)) //todays date
        var end = moment(new Date(startTime))
        var duration = moment.duration(now.diff(end))
        return (
          <div
            className='single-all-contact'
            key={'allcall' + index}
            onClick={e => {
              e.preventDefault()
              setCurrentSChannel(call.schannel_id)
              if (call.Team_Channel_Name == 'private') {
                console.log('private')
                let activechat = recentChats.filter(
                  chat => chat.Team_Channel_Id == call.schannel_id
                )
                console.log('activechat', activechat)
                activechat
                  ? setActiveChat({ ...activechat[0], pin: false })
                  : setActiveChat({
                      ...pinnedChats.filter(
                        chat => chat.Team_Channel_Id == call.schannel_id
                      )[0],
                      pin: true
                    })
                navigate('/chat')
              } else {
                console.log('private')
                let project = projects.filter(
                  proj => proj.channel_name == call.channel_name
                )
                let channel =
                  project &&
                  project[0].subchannel.filter(
                    channel => channel.Team_Channel_Id == call.schannel_id
                  )[0]
                console.log('channel', channel)
                channel &&
                  setActiveChat({ ...channel[0], project: call.channel_name })
                navigate('/projects')
              }
            }}
          >
            <div className='all-left'>
              <div className='img-call-wrap'>
                <img
                  src={
                    call.call_type === 'Outgoing'
                      ? ApiConfig.BASE_URL_FOR_IMAGES + call.PhotoPath
                      : ApiConfig.BASE_URL_FOR_IMAGES + call.incomingPhotoPath
                  }
                  alt=''
                />
                <span>
                  <img src={ic_come_back} alt='' />
                </span>
              </div>
              <div className='name'>
                <p>
                  {call.call_type === 'Outgoing' ||
                  call.call_type === 'Incoming'
                    ? call.FirstName == null
                      ? 'Group'
                      : call.incomingFirstName + ' ' + call.incomingLastName
                    : call.FirstName + ' ' + call.LastName}{' '}
                  {'(' + call.channel_name == 'private' &&
                    call.channel_name + '-' + call.Team_Channel_Name + ')'}
                </p>
                <p
                  className={
                    call.call_type === 'Outgoing'
                      ? 'orange'
                      : call.call_type === 'Incoming'
                      ? 'green'
                      : 'red'
                  }
                >
                  {missed && call.call_status === 'Missed'
                    ? call.call_status
                    : call.call_type}
                </p>
              </div>
            </div>
            <div className='all-right'>
              <p className='time'>{new Date(startTime).toLocaleString()}</p>
              <p className='duration'>
                {call.call_status === 'Missed'
                  ? ''
                  : duration.asMinutes().toLocaleString()}
              </p>
            </div>
          </div>
        )
      })}
      <div className='text-center'>
        <button
          type='button'
          class='btn btn btn-light'
          onClick={() => setCallPage(callPage - 1 >= 0 ? callPage - 1 : 0)}
          style={{ backgroundColor: '#53b7e8', color: 'white', margin: 20 }}
        >
          Prev
        </button>
        <button
          type='button'
          class='btn btn btn-light'
          onClick={() => setCallPage(callPage + 1)}
          style={{ backgroundColor: '#53b7e8', color: 'white' }}
        >
          Next
        </button>
      </div>
    </div>
  )
}
