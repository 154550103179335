import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const ProgressCircle = ({ timesheetCounts, timesheetCounts2 ,timesheetCounts4}) => {

  const {permissions} = useContext(AppContext)
  //   {
  //     "Total": 9,
  //     "Approval": 9,
  //     "Pending": 0,
  //     "Reject": 0
  // }

  // const percentage = 75;
  // const percentage1 = 60;
  // const percentage2 = 80;
  // const percentage3 = 100;

  const percentage = timesheetCounts?.Total ? timesheetCounts.Total : 0;
  const percentage1 = timesheetCounts?.Approval ? timesheetCounts.Approval : 0;
  const percentage2 = timesheetCounts?.Reject ? timesheetCounts.Reject : 0;
  const percentage3 = timesheetCounts?.Pending ? timesheetCounts.Pending : 0;
  const percentage4 = timesheetCounts4?.totalWorkingHours ? timesheetCounts4.totalWorkingHours : 0;
  const percentage5 = timesheetCounts4?.totalTimesheetWorkHours?timesheetCounts4.totalTimesheetWorkHours: 0;

  return (
    <>
      <div className="timesheet-progress-wrapper">
        <div className="timesheet-progress-main-heading">
          <h5 className="mt-2">Timesheet</h5>
          {
            permissions&&permissions['Add Timesheet Dashboard']&&
            <Link to="/timesheet" className="" variant="primary">
            + Add New{" "}
          </Link>
          }
        </div>

        <div className="progress-circles-section">
          <div className="row align-items-center text-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <Link to="/timesheet">
                <div className="progress-circle">
                  <div className="circle">
                    <CircularProgressbar
                      value={percentage}
                      text={`${percentage}`}
                      styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: "butt",
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: `#FFA903`,
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    />
                  </div>
                  <div className="progress-label pt-2">
                    <p>
                      Timesheet <br /> Submitted
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <Link to="/timesheethistory">
                <div className="progress-circle">
                  <div className="circle">
                    <CircularProgressbar
                      value={percentage1}
                      text={`${percentage1}`}
                      styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: "butt",
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: `#00D823`,
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    />
                  </div>
                  <div className="progress-label">
                    <p>
                      Timesheet <br /> Approved
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <Link to="/timesheethistory">
                <div className="progress-circle">
                  <div className="circle">
                    <CircularProgressbar
                      value={percentage2}
                      text={`${percentage2}`}
                      styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: "butt",
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: `#FF0000`,
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    />
                  </div>
                  <div className="progress-label">
                    <p>
                      Timesheet <br /> Rejected
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <Link to="/timesheet">
                <div className="progress-circle">
                  <div className="circle">
                    <CircularProgressbar
                      value={percentage3}
                      text={`${percentage3}`}
                      styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: "butt",
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: `#11111199`,
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    />
                  </div>
                  <div className="progress-label">
                    <p>
                      Timesheet For
                      <br /> Approval
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <Link to="/timesheethistory">
                <div className="progress-circle">
                  <div className="circle">
                    <CircularProgressbar
                      value={percentage4}
                      text={`${percentage4}`}
                      styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: "butt",
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: `#FF0000`,
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    />
                  </div>
                  <div className="progress-label">
                    <p>
                      Total <br /> Working Hrs
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <Link to="/timesheet">
                <div className="progress-circle">
                  <div className="circle">
                    <CircularProgressbar
                      value={percentage5}
                      text={`${percentage5}`}
                      styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: "butt",
                        textSize: "16px",
                        pathTransitionDuration: 0.5,
                        pathColor: `#11111199`,
                        textColor: "#000",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    />
                  </div>
                  <div className="progress-label">
                    <p>
                      Timesheet
                      <br /> Filled Hrs
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressCircle;
