import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";

export default function Updatewhatsapp() {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role ">
          <h6 className="mt-2">Update WhatsApp Settings</h6>
        </div>

        <div className="WeeklyMain">
          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Token :
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <input
                  //   value={newPassword}
                  placeholder="TOKEN"
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    //   fontSize: "14px",
                    height: "38px",
                    borderRadius: "10px",
                  }}
                  //   onChange={(e) => {
                  //     setNewPassword(e.target.value);
                  //   }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Source :
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <input
                  placeholder="SOURCE"
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    //   fontSize: "14px",
                    height: "38px",
                    borderRadius: "10px",
                  }}
                  //   onChange={(e) => {
                  //     setNewPassword(e.target.value);
                  //   }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Phone Number ID :
                </label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-3">
                <input
                  //   value={newPassword}
                  placeholder="PHONE NUMBER ID"
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    //   fontSize: "14px",
                    height: "38px",
                    borderRadius: "10px",
                  }}
                  //   onChange={(e) => {
                  //     setNewPassword(e.target.value);
                  //   }}
                />
              </div>
            </div>
          </div>

          <div className="align-items-center justify-content-center d-flex mt-5 mb-3">
            <button className="custoBtnForCrmCall">
              <a className=" textcolor" href="WhatsappSetting">
                Update Whatsapp Token
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
