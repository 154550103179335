import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";

const FrequentCaller = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const crmFrequentCallerData = [
    {
      phoneNumber: "+919309482204",
      counts: "6",
    },
    {
      phoneNumber: "+918765432109",
      counts: "5",
    },
    {
      phoneNumber: "+917654321098",
      counts: "4",
    },
    {
      phoneNumber: "+916543210987",
      counts: "2",
    },
    {
      phoneNumber: "+915432109876",
      counts: "3",
    },
    {
      name: "+914321098765",
      phoneNumber: "+918765432109",
      counts: "6",
    },
    {
      phoneNumber: "+914321098765",
      counts: "6",
    },
    {
      phoneNumber: "+912109876543",
      counts: "2",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Frequent Caller</h6>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text" placeholder="Search here" />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                value={selectedRows}
                onChange={handleSelectChange}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            <div className="booksIcon" style={{ textAlign: "center" }}>
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">PHONE NUMBER</th>
                <th scope="col">COUNTS</th>
              </tr>
            </thead>
            <tbody>
              {crmFrequentCallerData.map((data, index) => (
                <tr key={index} className="table-row-custom">
                  <td>{data.phoneNumber}</td>
                  <td>{data.counts}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default FrequentCaller;
