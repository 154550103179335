import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import profile_img from "../../assets/images/profile_img.svg";
import ic_away from "../../assets/images/chat/ic_away.svg";
import { Dropdown } from "react-bootstrap";
import bx_phone_call from "../../assets/images/chat/bx_phone_call.svg";
import { AppContext } from "../../context/AppContext";
import { useContext } from "react";
import more_vertical from "../../assets/images/chat/B_more_three.svg";
import more_blue from "../../assets/images/chat/more_vertical.svg";
import fileImg1 from "../../assets/images/chat/fileImg1.svg";
import fileImg12 from "../../assets/images/chat/fileImg12.svg";
import fileImg3 from "../../assets/images/chat/fileImg3.svg";
import fileImg4 from "../../assets/images/chat/fileImg4.svg";
import { ShareSocial } from "react-share-social";
import { getWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig, { BASE_URL_CHAT_Image } from "../../api/ApiConfig";
const Files = () => {
  const [filesList, setFilesList] = useState([]);
  const { sidebar, currentSChannel } = useContext(AppContext);
  const [currentFiles, setCurrentFiles] = useState(null);
  const [share, setShare] = useState(false);
  useEffect(() => {
    getFiles();
  }, []);
  const getFiles = () => {
    getWithAuthCallWithErrorResponse(
      ApiConfig.GET_FILES + currentSChannel
    ).then((res) => {
      console.log(res);
      if (res.json.success) setFilesList(res.json.data);
    });
  };

  function download(url, name, e) {
    e.preventDefault();
    fetch(url, {
      method: "GET",
      responseType: "blob",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      });
  }

  return (
    <>
      <div className="main_file_body" onClick={() => share && setShare(false)}>
        {filesList.length
          ? filesList.map((file, index) => {
              let path =
                BASE_URL_CHAT_Image + file.Resource_Path.split(".com")[1];
              let name = file.Resource_Path.split("/");
              let fileName = name[name.length - 1];
              return (
                <div
                  className="single_Card_File"
                  key={"file" + file.Resource_Id}
                >
                  <div className="img_file">
                    <img src={path} alt="" />
                  </div>
                  <div className="file_contain">
                    <div className="left">
                      <p className="name">{fileName}</p>
                      <p className="date">23-02-2023, 05:25 PM</p>
                    </div>
                    <div className="right">
                      <Dropdown className="ActionDropdown right-icon-chat">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="ActionToggle"
                        >
                          <img src={more_vertical} alt="" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="ActionMenu"
                          onClick={() => setCurrentFiles(path)}
                        >
                          <ul className="actionnmenulist">
                            <li>
                              <a href={path} target="_blank">
                                Open
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                onClick={(e) => {
                                  download(path, fileName, e);
                                }}
                              >
                                {" "}
                                Download{" "}
                              </a>
                            </li>
                            <li onClick={() => setShare(true)}>Share</li>
                            <li
                              onClick={() => {
                                navigator.clipboard.writeText(path);
                              }}
                            >
                              Copy Link
                            </li>
                            {/* <li>Delete</li> */}
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              );
            })
          : "No files found"}
        {share && (
          <ShareSocial
            url={currentFiles}
            socialTypes={["facebook", "twitter", "reddit", "linkedin"]}
            onSocialButtonClicked={(data) => console.log(data)}
          />
        )}
      </div>
    </>
  );
};

export default Files;
