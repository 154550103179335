import Modal from "react-bootstrap/Modal";
import React, { useState, useContext, useEffect } from "react";
import "../../assets/css/tasklist.scss";
import searchicon from "../../assets/images/ic_search.svg";
import { AppContext } from "../../context/AppContext";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import ReactPaginate from "react-paginate";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import Pagination from "../../sharedComponent/Pagination";
import { getDateddmmyyyy } from "../../common";
import NoData from "../../sharedComponent/NoData";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const ProjectTask = () => {
  // States
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const { sidebar, setSidebar, Dark, setDark, userData,permissions } =
    useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [projectTaskList, setProjectTaskList] = useState([]);

  const [excelLoading, setExcelLoading] = useState(false);

  useEffect(() => {
    getProjectTaksList();
  }, []);

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    simpleGetCallWithErrorResponse(ApiConfig.PROJECTTASK_DOWNLOAD_EXCEL)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjectTaksList = () => {
    setLoading(true);
    let payLoad = {
      EmpId: Number(userData.EmpId),
      OrgId: Number(userData.OrgId),
    };
    PostCallWithErrorResponse(
      ApiConfig.GET_PROJECT_TASK_LIST,
      JSON.stringify(payLoad)
    )
      .then((response) => {
        if (response.json.Success) {
          setLoading(false);
          setProjectTaskList(response.json.Data);
        } else {
          console.log("error response,", response);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // PROJECT TASKLIST API

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(5);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    projectTaskList &&
    projectTaskList.length &&
    projectTaskList.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue.toLowerCase())
    );

  const displayData =
    projectTaskList &&
    projectTaskList.slice(startIndex, endIndex).map((item, index) => {
      return (
        <tr key={index} className="table-row-custom">
          <td>{startIndex + index + 1}</td>
          <td>{item.ProjectName}</td>
          <td>{item.EmployeeName}</td>
          <td className="break-ln-spaces">{item.TaskName}</td>
          <td>{getDateddmmyyyy(item.Startdate)}</td>
          {/* <td>{item.Startdate.slice(0, 9)}</td> */}
          <td>{getDateddmmyyyy(item.enddate)}</td>
          {/* <td>{item.enddate.slice(0, 9)}</td> */}
          <td
            className={
              item.status === "P"
                ? "pending-tabel-data"
                : item.status === "A"
                ? "Approve-tabel-data"
                : item.status === "R"
                ? "Rejected-tabel-data"
                : ""
            }
          >
            {item.status === "P" ? (
              <span>Pending</span>
            ) : item.status === "A" ? (
              <span>Approved</span>
            ) : item.status === "R" ? (
              <span>Reject</span>
            ) : (
              ""
            )}
          </td>
        </tr>
      );
    });

  const displaySearchedData =
    projectTaskList &&
    projectTaskList
      ?.filter((item) =>
        item?.ProjectName.toLowerCase().includes(searchValue.toLowerCase())
      )
      .slice(startIndex, endIndex)
      .map((item, index) => {
        return (
          <tr key={index} className="table-row-custom">
            <td>{startIndex + index + 1}</td>
            <td>{item.ProjectName}</td>
            <td>{item.EmployeeName}</td>
            <td className="break-ln-spaces">{item.TaskName}</td>
            <td>{item.Startdate.slice(0, 9)}</td>
            <td>{item.enddate.slice(0, 9)}</td>
            <td
              className={
                item.status === "P"
                  ? "pending-tabel-data"
                  : item.status === "A"
                  ? "Approve-tabel-data"
                  : item.status === "R"
                  ? "Rejected-tabel-data"
                  : ""
              }
            >
              {item.status === "P" ? (
                <span>Pending</span>
              ) : item.status === "A" ? (
                <span>Approved</span>
              ) : item.status === "R" ? (
                <span>Reject</span>
              ) : (
                ""
              )}
            </td>
          </tr>
        );
      });

  return (
    <>
      {/* <Header />
            <Sidebar /> */}
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Project Task </h6>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search By ProjectName..."
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
            </div>
            {
              permissions&&permissions['Project Task Export']&&
            <div className="import-export-btn-wrapper">
              <button className="booksIcon">
                {excelLoading ? (
                  <ExcelLoader />
                ) : (
                  <img
                    src={ic_excel}
                    onClick={(e) => downloadExcelFile(e)}
                    alt=""
                  />
                )}
              </button>
            </div>
            }
          </div>
          {/* <div className='emptyPage'>
                        <img src={emptypage} alt="" />
                    </div> */}
          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            {loading ? (
              <Loader />
            ) : projectTaskList && searchedDataPageCounts.length > 0 ? (
              <table>
                <tr className="firstTabelRow">
                  <th>Sr. no</th>
                  <th>Project Name</th>
                  <th>Employee Name</th>
                  <th>Task Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Status</th>
                </tr>
                {searchValue === "" ? displayData : displaySearchedData}
              </table>
            ) : (
              <>
                <NoData />
              </>
            )}
          </div>
          {!loading && searchedDataPageCounts.length > usersPerPage && (
            <Pagination
              data={projectTaskList}
              pageChangeHandler={pageChangeHandler}
              usersPerPage={usersPerPage}
              currentPage={currentPage}
              searchValue={searchValue}
              searchedDataPageCounts={searchedDataPageCounts}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProjectTask;
