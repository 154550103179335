import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import VectorImg from "../../assets/images/icon-user-default.png";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const EditGroup = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const navigate = useNavigate();

  const employeeOptions = [
    { value: "member1", label: "Member 1" },
    { value: "member2", label: "Member 2" },
    { value: "member3", label: "Member 3" },
  ];
  const [assignData, setAssignData] = React.useState({
    EmployeeId: [],
  });

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Edit Group</h6>
          </div>
          <Form noValidate>
            <div id="tabsBelow" className="mt-4">
              <div className="row">
                <div
                  className="col-md-12 col-lg-6"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="selectImg">
                    <div className="mb-1 text-center">
                      <label htmlFor="imgHolder">
                        <img src={VectorImg} className="modalImg mb-4" alt="" />
                        <input
                          type="file"
                          accept="image/*"
                          id="imgHolder"
                          required
                          className="form-control tasKCategory1"
                          name="EmployeePhoto"
                        />
                      </label>
                    </div>
                    {/* <div className="modalProp mb-2">
                      <label
                        htmlFor=""
                        className="enter-role-text mt-2"
                        style={{ fontSize: "14px" }}
                      >
                        First Name
                      </label>
                      <input
                        name="FirstName"
                        type="text"
                        className="date-label-input tasKCategory1"
                        style={{
                          fontSize: "14px",
                          height: "38px",
                          border: "1px solid #ced4da",
                        }}
                        required
                      />
                    </div> */}
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="modalProp my-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                      name="Name"
                      required
                    />
                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Description
                    </label>
                    <input
                      type="text"
                      className="date-label-input tasKCategory1"
                      style={{
                        fontSize: "14px",
                        height: "38px",
                        border: "1px solid #ced4da",
                      }}
                      name="Description"
                      required
                    />
                  </div>
                  <div className="modalProp mb-2">
                    <label
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{ fontSize: "14px" }}
                    >
                      Member
                    </label>
                    {/* <select
                      class="form-select tasKCategory1 select-drop-icon"
                      aria-label="Default select example"
                      name="Member"
                      required
                    >
                      <option selected>Select Member</option>
                      <option value="member 1">Member 1</option>
                      <option value="member 2">Member 2</option>
                      <option value="member 3">Member 3</option>
                    </select> */}

                    <Select
                      className=""
                      options={employeeOptions}
                      value={employeeOptions.filter((item) =>
                        assignData.EmployeeId.includes(item.value)
                      )}
                      isMulti
                      onChange={(e) => {
                        const employeeIdArr = e
                          ? e.map((item) => item.value)
                          : [];
                        setAssignData({
                          ...assignData,
                          EmployeeId: employeeIdArr,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center mt-2">
                <button
                  className="nextBtn"
                  style={{ width: "auto", padding: "8px 16px" }}
                >
                  Update Group
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EditGroup;
