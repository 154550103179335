import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
function CustomGroup() {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [defaultgroup, setDefaultgroup] = useState(true);
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Whatsapp Promotion</h6>
            <button className="create-btn-sub-header" variant="primary">
              + Custom Group
            </button>
          </div>
          <div className="innerRoleSec">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Title
                  </label>
                  <input
                    name="firsttitle"
                    placeholder="Enter Title"
                    type="text"
                    className="date-label-input tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </div>
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Message/Description
                  </label>
                  <input
                    name="message"
                    placeholder="Enter Message"
                    type="text"
                    className="date-label-input tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </div>
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Title
                  </label>
                  <input
                    name="title"
                    placeholder="Enter Title"
                    type="text"
                    className="date-label-input tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </div>
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Select Source
                  </label>
                  <select
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    name="Source"
                    required
                  >
                    <option selected>Select Source</option>
                    <option value="1">Source 1</option>
                    <option value="2">Source 2</option>
                    <option value="3">Source 3</option>
                  </select>
                </div>
                <div className="modalProp mb-2">
                  <div className="d-flex justify-content-between  w-50 ">
                    <label
                      onClick={() => setDefaultgroup(!false)}
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{
                        fontSize: "14px",
                        color:
                          defaultgroup === !false
                            ? "var(--blue_53B7E8_LightMode)"
                            : null,
                      }}
                    >
                      Default Group
                    </label>
                    <label
                      onClick={() => setDefaultgroup(false)}
                      htmlFor=""
                      className="enter-role-text mt-2"
                      style={{
                        fontSize: "14px",
                        color:
                          defaultgroup === false
                            ? "var(--blue_53B7E8_LightMode)"
                            : null,
                      }}
                    >
                      Custom Group
                    </label>
                  </div>
                  {defaultgroup ? (
                    <div className="">
                      <button className="imporButton">
                        <Link className="details-link">Channel</Link>
                      </button>
                      <button
                        className="imporButton"
                        style={{ marginLeft: "4px" }}
                      >
                        <Link className="details-link">Source</Link>
                      </button>
                      <button
                        className="imporButton"
                        style={{ marginLeft: "4px" }}
                      >
                        <Link className="details-link">Time</Link>
                      </button>
                      <button
                        className="imporButton"
                        style={{ marginLeft: "4px" }}
                      >
                        <Link className="details-link">State</Link>
                      </button>
                      <button
                        className="imporButton"
                        style={{ marginLeft: "4px" }}
                      >
                        <Link className="details-link">City</Link>
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Select Group
                  </label>
                  <select
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    name="Group"
                    required
                  >
                    <option selected>Select Group</option>
                    <option value="4">Group 1</option>
                    <option value="5">Group 2</option>
                    <option value="6">Group 3</option>
                  </select>
                </div>
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Test Number
                  </label>
                  <input
                    name="TestNumber"
                    placeholder="Enter Test Number"
                    type="text"
                    className="date-label-input tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </div>
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Date
                  </label>
                  <input
                    name="Date"
                    placeholder="Enter Date"
                    type="date"
                    className="date-label-input tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </div>
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Time
                  </label>
                  <input
                    name="Time"
                    placeholder="Enter Time"
                    type="time"
                    className="date-label-input tasKCategory1"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </div>
                <div className="modalProp mb-2">
                  <label
                    htmlFor=""
                    className="enter-role-text mt-2"
                    style={{ fontSize: "14px" }}
                  >
                    Attachment
                  </label>
                  <input
                    type="file"
                    id="choosefile"
                    required
                    className="form-control tasKCategory1"
                    name="attachement"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomGroup;
