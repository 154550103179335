import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import { Link } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";

const Users = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const UsersData = [
    {
      srno: "1",
      name: "Ashu",
      type: "Inquiry",
      email: "917898578143@noreply.com",
      createdate: "10-06-2024",
      gender: "",
      Location: "",
    },
    {
      srno: "2",
      name: "Irin",
      type: "Prospect",
      email: "Irin@gmail.com",
      createdate: "10-06-2024",
      gender: "",
      Location: "",
    },
    {
      srno: "3",
      name: "Merin",
      type: "Prospect",
      email: "merin@gmail.com",
      createdate: "10-06-2024",
      gender: "",
      Location: "",
    },
    {
      srno: "4",
      name: "Jerin",
      type: "Prospect",
      email: "Jerin@gmail.com",
      createdate: "10-06-2024",
      gender: "",
      Location: "",
    },
    {
      srno: "5",
      name: "Mikku",
      type: "Inquiry",
      email: "Mikku@gmail.com",
      createdate: "10-06-2024",
      gender: "",
      Location: "",
    },
    {
      srno: "6",
      name: "",
      type: "Prospect",
      email: "",
      createdate: "10-06-2024",
      gender: "",
      Location: "",
    },
    {
      srno: "7",
      name: "",
      type: "Prospect",
      email: "",
      createdate: "10-06-2024",
      gender: "",
      Location: "",
    },
    {
      srno: "8",
      name: "Hari",
      type: "Inquiry",
      email: "hari@gmail.com",
      createdate: "10-06-2024",
      gender: "",
      Location: "",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Users</h6>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text" placeholder="Search here" />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                value={selectedRows}
                onChange={handleSelectChange}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            <div className="booksIcon" style={{ textAlign: "center" }}>
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>
        <div className="registerbutton">
          <div></div>
          <button className="sml-btn-sub-header">
            <Link to="/RegisterUserDetails" className="details-link">
              User Register
            </Link>
          </button>
        </div>

        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">Sr.No</th>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Mobile Number</th>
                <th scope="col">Email</th>
                <th scope="col">Create Date</th>
                <th scope="col">Gender</th>
                <th scope="col">Location</th>
                <th scope="col">View Details</th>
              </tr>
            </thead>
            <tbody>
              {UsersData.map((data, index) => (
                <tr key={index} className="table-row-custom">
                  <td>{data.srno}</td>
                  <td>{data.name}</td>
                  <td>{data.type}</td>
                  <td>
                    <button className="imporButton">
                      <Link to="/CRMCalls" className="details-link">
                        Make Call
                      </Link>
                    </button>
                  </td>
                  <td>{data.email}</td>
                  <td>{data.createdate}</td>
                  <td>
                    {data.gender ? <gender controls></gender> : "Not Found"}
                  </td>

                  <td>
                    {data.Location ? (
                      <location controls></location>
                    ) : (
                      "Not Found"
                    )}
                  </td>
                  <td>
                    <button className="imporButton">
                      <Link to="/UserDetailsForm" className="details-link">
                        View Details
                      </Link>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default Users;
