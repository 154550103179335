import { ReactMic } from "react-mic";
import React from "react";
import mic from "../assets/images/chat/CallAcceptRed.svg";
import start_recording from "../assets/images/chat/start-recording (2).svg";
import micOn from "../assets/images/chat/mic-off (1).svg";

export class AudioRecording extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: false,
    };
    this.onData = this.onData.bind(this);
    this.onStop = this.onStop.bind(this);
  }

  startRecording = () => {
    this.setState({ ...this.state, record: true });
    this.props.closeOutsid();
  };

  stopRecording = (recordedBlob) => {
    this.setState({
      record: false,
      //url: URL.createObjectURL(recordedBlob),
    });
    //this.props.submitRecording(recordedBlob);
  };

  onData(recordedBlob) {
    //console.log("chunk of real-time data is: ", recordedBlob);
  }

  onStop(recordedBlob) {
    console.log(recordedBlob);
    this.props.submitRecording(recordedBlob.blob);
  }

  componentWillUnmount() {
    this.stopRecording();
  }
  render() {
    return (
      <div>
        <ReactMic
          record={this.state.record}
          onStop={this.onStop}
          //onData={this.onData}
          visualSetting="frequencyBars"
          strokeColor="#53b7e8"
          mimeType="audio/mp3"
          bitRate={256000} // defaults -> 128000 (128kbps).  React-Mic-Gold only.
          sampleRate={96000} // defaults -> 44100 (44.1 kHz).  It accepts values only in range: 22050 to 96000 (available in React-Mic-Gold)
          timeSlice={3000}
        />
        {/* <h5 onClick={this.startRecording}>Recording</h5> */}
        <div className="d-flex justify-content-center">
          <img
            src={this.state.record ? micOn : start_recording}
            style={{ cursor: "pointer", width: "20px", marginTop: "20px" }}
            alt="mic"
            onClick={() => {
              if (this.state.record) {
                this.stopRecording();
              } else {
                this.startRecording();
              }
            }}
          />
        </div>
      </div>
    );
  }
}
