import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import DelectAc from "../../assets/images/DelectAcBlc.svg";
import Editac from "../../assets/images/bx_edit.svg";
import Pagination from "../../sharedComponent/Pagination";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
function Academic() {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [createacedamic, setCreateAcedamic] = useState(false);
  const [editacedamic, setEditAcedamic] = useState(false);
  const [deleteacedamic, setDeleteAcedemic] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(5);

  const acedamic = [
    {
      srno: "1",
      startdate: "1-06-2022",
      enddate: "24-03-2023",
    },
    {
      srno: "2",
      startdate: "1-06-2022",
      enddate: "24-03-2023",
    },
    {
      srno: "3",
      startdate: "1-06-2022",
      enddate: "24-03-2023",
    },
    {
      srno: "4",
      startdate: "1-06-2022",
      enddate: "24-03-2023",
    },
    {
      srno: "5",
      startdate: "1-06-2022",
      enddate: "24-03-2023",
    },
  ];
  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Financial Year</h6>
            <button
              className="create-btn-sub-header"
              variant="primary"
              onClick={() => setCreateAcedamic(true)}
            >
              + Financial Year
            </button>
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
            </div>
          </div>

          <div className="table-wrapper">
            <table>
              <thead>
                <tr className="firstTabelRow">
                  <></>
                  <th>Sr.no</th>
                  <th>Start Dtae</th>
                  <th> End Date </th>
                  <th> Actions </th>
                </tr>
              </thead>
              <tbody>
                {acedamic.map((item) => (
                  <tr className="table-row-custom">
                    <td>{item.srno}</td>
                    <td>{item.startdate}</td>
                    <td>{item.enddate}</td>
                    <td>
                      <img
                        src={Editac}
                        className="mx-2 editBtn"
                        alt=""
                        onClick={() => setEditAcedamic(true)}
                      />
                      <img
                        src={DelectAc}
                        className="deleteBtn"
                        alt=""
                        onClick={() => setDeleteAcedemic(true)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination />
      </div>
      {/* Add Financial Year */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={createacedamic}
        onHide={() => setCreateAcedamic(false)}
      >
        <Form noValidate>
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                Add Financial Year
              </label>
            </Modal.Title>
            <div className="rightAxe">
              <img src={Axe} alt="" onClick={() => setCreateAcedamic(false)} />
            </div>
          </Modal.Header>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Body>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Start Date
              </label>
              <input
                name="HolidayName"
                placeholder="Enter Start Date"
                type="text"
                className="date-label-input tasKCategory1 focusBlc"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter Start Date
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                End Date
              </label>
              <input
                name="HolidayName"
                placeholder="Enter End Date"
                type="text"
                className="date-label-input tasKCategory1 focusBlc"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter End Date
              </Form.Control.Feedback>
            </div>
          </Modal.Body>

          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={() => setCreateAcedamic(false)}
              type="button"
            >
              Cancel
            </button>
            <button
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={() => setCreateAcedamic(false)}
              type="submit"
            >
              Add
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* Edit Financial Year */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={editacedamic}
        onHide={() => setEditAcedamic(false)}
      >
        <Form noValidate>
          <Modal.Header>
            <Modal.Title>
              <label htmlFor="" className="create-role-text">
                Edit Financial Year
              </label>
            </Modal.Title>
            <div className="rightAxe">
              <img src={Axe} alt="" onClick={() => setEditAcedamic(false)} />
            </div>
          </Modal.Header>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Body>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                Start Date
              </label>
              <input
                name="HolidayName"
                placeholder="Enter Start Date"
                type="text"
                className="date-label-input tasKCategory1 focusBlc"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter Start Date
              </Form.Control.Feedback>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "14px" }}
              >
                End Date
              </label>
              <input
                name="HolidayName"
                placeholder="Enter End Date"
                type="text"
                className="date-label-input tasKCategory1 focusBlc"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter End Date
              </Form.Control.Feedback>
            </div>
          </Modal.Body>

          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={() => setEditAcedamic(false)}
              type="button"
            >
              Cancel
            </button>
            <button
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={() => setEditAcedamic(false)}
              type="submit"
            >
              Update
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* Confirmation popup Acedamic year */}

      <Modal
        className="delete-news-modal"
        show={deleteacedamic}
        onHide={() => setDeleteAcedemic(false)}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">Confirmation</Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure, You want to delete?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button
            className="btn-width cancelBtn mx-3"
            onClick={() => setDeleteAcedemic(false)}
          >
            Cancel
          </button>

          <button
            className="btn-width saveBtn"
            onClick={() => setDeleteAcedemic(false)}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Academic;
