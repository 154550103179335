import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import Pagination from "../../sharedComponent/Pagination";
import Editac from "../../assets/images/Editac.svg";

const ProjectCostReport = () => {
    const { sidebar, setSidebar, Dark, setDark, userData,permissions } = useContext(AppContext);

    const [forcastData, setForcastData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [searchValue, setSearchValue] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [usersPerPage, setUsersPerPage] = useState(5);

    const startIndex = currentPage * usersPerPage;
    const endIndex = startIndex + parseInt(usersPerPage);

    const pageChangeHandler = ({ selected }) => {
        setCurrentPage(selected);
    };

    const searchedDataPageCounts =
        forcastData &&
        forcastData.length &&
        forcastData.filter((item) =>
            item.ProjectName?.toLowerCase().includes(searchValue)
        );

    const fetchForecastList = () => {
        setLoading(true);
        simpleGetCallWithToken(ApiConfig.GET_PROJECTFORECAST_LIST)
            .then((res) => {
                if (res.Success === true) {
                    setLoading(false);
                    setForcastData(res.Data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    useEffect(() => {
        fetchForecastList();
    }, [])

    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="role-content">
                    <div className="sub-header-role ">
                        <h6 className="mt-2">PROJECT COST LIST</h6>
                    </div>
                    <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
                        <div className="role-table-header">
                            <div className="search-input-wrapper">
                                <input
                                    type="text"
                                    placeholder="Search Project Name"
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                        setCurrentPage(0);
                                    }}
                                />
                                {Dark === "lightMode" ? (
                                    <img src={searchicon} alt="" />
                                ) : (
                                    <img src={ic_search_dark} alt="" />
                                )}
                            </div>
                            <div className="entries-wrapper">
                                <p>Entries per page</p>
                                <select
                                    className="form-select select-drop-icon"
                                    aria-label="Default select example"
                                    onChange={(e) => {
                                        setUsersPerPage(e.target.value);
                                        setCurrentPage(0);
                                    }}
                                >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="table-wrapper">
                        {
                            loading ?
                                <>
                                    <Loader />
                                </> :
                                <>
                                    {
                                        forcastData && forcastData.length > 0 ?
                                            <table>
                                                <thead>
                                                    <tr className="firstTabelRow">
                                                        <th>Project Id</th>
                                                        <th>Project Name</th>
                                                        <th>Cost</th>
                                                        <th>Weekly Cost</th>
                                                        <th>Monthly Cost</th>
                                                        <th>Project Forecast</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        forcastData.filter(item => item.ProjectName.toLowerCase().includes(searchValue.toLowerCase()))
                                                            .slice(startIndex, endIndex).map((data, index) => {
                                                                return (
                                                                    <tr className="table-row-custom" key={index}>
                                                                        <td>{data.ProjectId}</td>
                                                                        <td>
                                                                            <Link to={`/ProjectCostEnd/${data.ProjectId}`}>
                                                                                {data.ProjectName}
                                                                            </Link>
                                                                        </td>
                                                                        <td>{data.Cost}</td>
                                                                        <td>{data.WeeklyCost}</td>
                                                                        <td>{data.MonthlyCost}</td>
                                                                        <td>
                                                                            {
                                                                                permissions&&permissions['Edit Project Cost Report']&&
                                                                            <img src={Editac} className="mx-2 editBtn" alt="" />
                                                                          }</td>
                                                                    </tr>
                                                                )
                                                            })
                                                    }
                                                </tbody>
                                            </table>
                                            :
                                            <>
                                                <NoData />
                                            </>
                                    }
                                </>
                        }
                    </div>
                </div>
                <Pagination
                    data={forcastData}
                    pageChangeHandler={pageChangeHandler}
                    usersPerPage={usersPerPage}
                    currentPage={currentPage}
                    searchValue={searchValue}
                    searchedDataPageCounts={searchedDataPageCounts}
                />
            </div>
        </>
    )
}

export default ProjectCostReport
