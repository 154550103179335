import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import {
  putMultipartWithAuthCallWithErrorResponse,
  putWithAuthCall,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";

const ViewCategory = () => {
  const items = [
    { name: "Category :", description: "General" },
    { name: "Domain Complexity :", description: "CRM" },
  ];

  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [complianceList, setComplianceList] = useState([]);
  const [complianceObj, setComplianceObj] = useState([]);

  const getComplianceSettings = () => {
    simpleGetCallWithToken(ApiConfig.GET_COMPLIANCE_SETTINGS)
      .then((res) => {
        console.log("res", res.Data);
        if (res.Success === true) {
          setComplianceList(res.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getComplianceSettings();
  }, []);

  //   const handleSelect = (val) => {
  //     console.log("handleSelect", val);
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      ComplianceSetting: [...complianceObj],
    };
    console.log("data", data);
    putWithAuthCall(ApiConfig.SET_COMPLIANCE_SETTINGS, JSON.stringify(data))
      .then((res) => {
        if (res.Success === true) {
          swal(res.Message);
          // setComplianceObj([]);
          // setComplianceObj([
          //   {
          //     ComplianceType: "",
          //     SettingsId: 0,
          //     SenderId: 0,
          //   },
          // ]);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">View Category</h6>
          </div>
          <div className="EnquiryCard">
            <ul
              style={{
                listStyleType: "none",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              {items.map((item, index) => (
                <li key={index}>
                  <div className="row col-md-12 ml-2 mt-2">
                    <p className="col-md-4 mt-2">{item.name}</p>:
                    <p className="col-md-4"> {item.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCategory;
