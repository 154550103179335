import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { useParams } from "react-router-dom";
import { PostCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import { projectHoursCount } from "../../common";

const ProjectCostEnd = (props) => {
    const { sidebar } = useContext(AppContext);
    const { id } = useParams(props.ProjectId);

    const [projectCostingList, setProjectCostingList] = useState([]);
    const [duplicateArr, setDuplicateArr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [empName, setEmpName] = useState([]);

    const totalCosted =
        projectCostingList &&
        projectCostingList?.reduce((sum, item) => sum + item.TotCost, 0);
    const totalhrs =
        projectCostingList &&
        projectCostingList?.reduce((sum, item) => sum + item.TotHours, 0);
    var commaNumber = require("comma-number");

    const fetchprojectCostingList = () => {
        setLoading(true);
        let payload = {
            ProjectId: JSON.parse(id),
        };
        PostCallWithErrorResponse(
            ApiConfig.POST_WITH_ID_FORCAST,
            JSON.stringify(payload)
        )
            .then((res) => {
                if (res.json.Success === true) {
                    setLoading(false);
                    setProjectCostingList(res.json.Data);
                    setDuplicateArr(res.json.Data);
                    let employeeName =
                        res.json.Data &&
                        res.json.Data.map((item) => {
                            return item.EmployeeName;
                        });
                    setEmpName(employeeName);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (event, index) => {
        const updatedData = [...projectCostingList];
        updatedData[index][event.target.name] = event.target.value;
        setProjectCostingList(updatedData);
    };

    const handleDate = (data) => {
        console.log("handleData", data);
    };
    useEffect(() => {
        fetchprojectCostingList();
    }, []);

    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="role-content">
                    <div className="sub-header-role ">
                        <h6 className="mt-2">Costing Result</h6>
                    </div>
                    {loading ? (
                        <>
                            <Loader />
                        </>
                    ) : (
                        <>
                            {projectCostingList && projectCostingList.length > 0 ? (
                                <>
                                    <div className="costingResultDetail">
                                        <div className="d-flex">
                                            <h4 className="headingTxt">Project Name : </h4>
                                            <span>{projectCostingList[0]?.ProjectName}</span>
                                        </div>
                                        <div className="d-flex">
                                            <h4 className="headingTxt">Start Date : </h4>
                                            <span>{projectCostingList[0]?.StartDate}</span>
                                        </div>
                                        <div className="d-flex">
                                            <h4 className="headingTxt">End Date : </h4>
                                            <span>{projectCostingList[0]?.EndDate}</span>
                                        </div>
                                        <div className="d-flex">
                                            <h4 className="headingTxt">Budgeted Hours : </h4>
                                            <span>{projectCostingList[0]?.BudgetedHours}</span>
                                        </div>
                                    </div>
                                    <div className="table-wrapper">
                                        <table>
                                            <thead>
                                                <tr className="firstTabelRow">
                                                    <th></th>
                                                    <th>Employee Name</th>
                                                    <th>Resource Start Date</th>
                                                    <th>Resource End Date</th>
                                                    <th>Employee Cost</th>
                                                    <th>Hours</th>
                                                    <th>Cost</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {projectCostingList.map((data, index) => {
                                                    return (
                                                        <tr className="table-row-custom" key={index}>
                                                            <td>
                                                                <input
                                                                    class="form-check-input"
                                                                    type="checkbox"
                                                                    id={index}
                                                                    // checked={getObj.TotHours}
                                                                    checked={empName?.includes(data.EmployeeName)}
                                                                    onChange={(event) => {
                                                                        console.log("event,", event.target.checked);
                                                                        let empNameFilterArr = empName?.filter(
                                                                            (name) => name !== data?.EmployeeName
                                                                        );
                                                                        if (event.target.checked === true) {
                                                                            let selectedObj = duplicateArr[index];
                                                                            let generatedArr = projectCostingList.map(
                                                                                (item, innerIndex) => {
                                                                                    if (innerIndex === index)
                                                                                        return {
                                                                                            ...item,
                                                                                            TotCost: data?.TotCost
                                                                                                ? data?.TotCost
                                                                                                : selectedObj?.TotCost,
                                                                                            TotHours: data?.TotHours
                                                                                                ? data?.TotHours
                                                                                                : selectedObj?.TotHours,
                                                                                            TotCost:
                                                                                                (data?.TotHours
                                                                                                    ? data?.TotHours
                                                                                                    : selectedObj?.TotHours) *
                                                                                                data.EmpCost,
                                                                                        };
                                                                                    else return item;
                                                                                }
                                                                            );
                                                                            setProjectCostingList(generatedArr);
                                                                            setEmpName([
                                                                                ...empName,
                                                                                data?.EmployeeName,
                                                                            ]);
                                                                        } else {
                                                                            let generatedArr = projectCostingList.map(
                                                                                (item, innerIndex) => {
                                                                                    if (innerIndex === index)
                                                                                        return {
                                                                                            ...item,
                                                                                            TotCost: 0,
                                                                                            TotHours: 0,
                                                                                        };
                                                                                    else return item;
                                                                                }
                                                                            );
                                                                            setProjectCostingList(generatedArr);
                                                                            console.log(
                                                                                "empNameFilterArr",
                                                                                empNameFilterArr
                                                                            );
                                                                            setEmpName(empNameFilterArr);
                                                                        }
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <label htmlFor={index}>
                                                                    {data.EmployeeName}
                                                                </label>
                                                            </td>
                                                            <td>{data.StartDate}</td>
                                                            <td>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    value={data.EndDate}
                                                                    name="EndDate"
                                                                    onChange={(event) => {
                                                                        let selectedObj = duplicateArr[index];
                                                                        let newArr = projectCostingList.map(
                                                                            (item, innerIndex) => {
                                                                                if (innerIndex === index)
                                                                                    return {
                                                                                        ...item,
                                                                                        EndDate: event.target.value,
                                                                                        TotHours:
                                                                                            selectedObj?.TotHours +
                                                                                            (projectHoursCount(
                                                                                                new Date(selectedObj.EndDate),
                                                                                                new Date(event.target.value)
                                                                                            ) *
                                                                                                8 -
                                                                                                8),
                                                                                        TotCost:
                                                                                            selectedObj?.TotCost +
                                                                                            (projectHoursCount(
                                                                                                new Date(selectedObj.EndDate),
                                                                                                new Date(event.target.value)
                                                                                            ) *
                                                                                                8 -
                                                                                                8) *
                                                                                            selectedObj?.EmpCost,
                                                                                    };
                                                                                else return item;
                                                                            }
                                                                        );
                                                                        setProjectCostingList(newArr);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={data.EmpCost}
                                                                    disabled={
                                                                        !empName?.includes(data.EmployeeName)
                                                                    }
                                                                    name="EmpCost"
                                                                    onChange={(event) => {
                                                                        let selectedObj = duplicateArr[index];
                                                                        let newArr = projectCostingList.map(
                                                                            (item, innerIndex) => {
                                                                                if (innerIndex === index)
                                                                                    return {
                                                                                        ...item,
                                                                                        EmpCost: event.target.value,
                                                                                        TotCost:
                                                                                            Number(event.target.value) *
                                                                                            item.TotHours,
                                                                                    };
                                                                                else return item;
                                                                            }
                                                                        );
                                                                        setProjectCostingList(newArr);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    disabled={
                                                                        !empName?.includes(data.EmployeeName)
                                                                    }
                                                                    value={data?.TotHours}
                                                                    name="TotHours"
                                                                    onChange={(event) => {
                                                                        let newArr = projectCostingList.map(
                                                                            (item, innerIndex) => {
                                                                                if (innerIndex === index)
                                                                                    return {
                                                                                        ...item,
                                                                                        TotHours: Number(
                                                                                            event.target.value
                                                                                        ),
                                                                                        TotCost:
                                                                                            Number(event.target.value) *
                                                                                            data.EmpCost,
                                                                                    };
                                                                                else return item;
                                                                            }
                                                                        );
                                                                        setProjectCostingList(newArr);
                                                                        // Duplicate Array
                                                                        let newArrOfList = projectCostingList.map(
                                                                            (item, innerIndex) => {
                                                                                if (innerIndex === index)
                                                                                    return {
                                                                                        ...item,
                                                                                        TotHours: Number(
                                                                                            event.target.value
                                                                                        ),
                                                                                        TotCost:
                                                                                            Number(event.target.value) *
                                                                                            data.EmpCost,
                                                                                    };
                                                                                else return item;
                                                                            }
                                                                        );
                                                                        setDuplicateArr(newArrOfList);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>{data.TotCost}</td>
                                                        </tr>
                                                    );
                                                })}
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="d-flex justify-content-end">
                                                        <span className="boldText">TOTAL &#8758;</span>
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <span className="boldText ml-4">
                                                            {commaNumber(totalhrs?.toFixed(2))}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <span className="boldText">
                                                            {commaNumber(totalCosted?.toFixed(2))}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            ) : (
                                <NoData />
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ProjectCostEnd;
