import React from 'react';
import noDataImg from "../assets/images/no_data.svg";


const NoData = () => {
    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <img src={noDataImg} alt="" className='mb-3' />
                <span className='blueColor'>Data Not Found</span>
            </div>
        </>
    )
}

export default NoData;
