import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import ExcelLoader from "../../sharedComponent/ExcelLoader";
import ic_excel from "../../assets/images/ic_excel.svg";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { set } from "lodash";
import FileSaver from "file-saver";

const UserTracking = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const [excelLoading, setExcelLoading] = useState(false);

  const [employeeList, setEmployeeList] = useState([]);

  const minOffset = 0;
  const maxOffset = 1;
  let crryear = new Date().getFullYear();
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(crryear - x);
  }

  const [years, setYears] = useState([]);

  const [months, setMonths] = useState([]);

  const [monthArr, setMonthArr] = useState([
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ]);

  const [apiData, setApiData] = useState([]);

  const [attendancePayLoad, setAttendancePayLoad] = useState({
    EmpId: Number(userData.EmpId),
    OrgId: Number(userData.OrgId),
    Month: new Date().getMonth() + 1,
    Year: 1,
  });

  const [searchUserDetails, setSearchUsersDetais] = useState({
    EmployeeId: Number(userData.EmpId),
    OrgId: Number(userData.OrgId),
    month: new Date().getMonth() + 1,
    year: 2023,
  });

  const getEmployeeList = () => {
    PostCallWithErrorResponse(
      ApiConfig.EMPLOEE_NAME_LIST,
      JSON.stringify({ OrgId: Number(userData.OrgId) })
    ).then((res) => {
      if (res.json.Success) {
        console.log(res);

        setEmployeeList(res?.json?.Data ? res.json.Data : []);
      } else {
        console.log("something wen wrong");
      }
    });
  };

  const getMonths = () => {
    console.log("Change");

    simpleGetCallWithErrorResponse(
      ApiConfig.GET_MONTH + searchUserDetails.year
    ).then((res) => {
      if (res.json.Success) {
        setMonths(res.json.Data);
      }
    });
  };

  const getYears = () => {
    simpleGetCallWithErrorResponse(ApiConfig.GET_YEARS).then((res) => {
      if (res.json.Success) {
        setYears(res.json.Data);
      }
    });
  };

  useEffect(() => {
    getEmployeeList();

    getYears();
  }, []);

  useEffect(() => {
    getDataFromApi();

    getMonths();
  }, [searchUserDetails]);

  const getDataFromApi = () => {
    console.log(searchUserDetails);

    PostCallWithErrorResponse(
      ApiConfig.ATTANDANCE_USER_TRACKING,
      JSON.stringify({
        EmpId: Number(searchUserDetails.EmployeeId),
        OrgId: Number(searchUserDetails.OrgId),
        Month: Number(searchUserDetails.month),
        Year: Number(searchUserDetails.year),
      })
    ).then((res) => {
      console.log(res);

      if (res.json.Success) {
        setApiData(res.json.Data);
      }
    });
  };
  const downloadExcelFile = () => {
    setExcelLoading(true);
    let payload = {
      EmpId: Number(searchUserDetails.EmployeeId),
      OrgId: Number(searchUserDetails.OrgId),
      Month: Number(searchUserDetails.month),
      Year: Number(searchUserDetails.year),
    };
    PostCallWithErrorResponse(
      ApiConfig.ATTANDANCE_USER_TRACKING_EXPORT,
      JSON.stringify(payload)
    )
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <div className="textHeader">
              <h6 className="mt-2">User Tracking</h6>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="wrapperMt">
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSearchUsersDetais({
                      ...searchUserDetails,
                      EmployeeId: e.target.value,
                    });

                    //  getDataFromApi()
                  }}
                  value={searchUserDetails.EmployeeId}
                >
                  {employeeList.map((emp, index) => {
                    return (
                      <option key={index} value={emp.Id}>
                        {emp.FullName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="wrapperMt ml-4">
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //     setUsersPerPage(e.target.value);
                  //     setCurrentPage(0);
                  // }}
                >
                  <option value="">Location List</option>
                  <option value="Aurangabad">Aurangabad</option>
                  <option value="Trivendram">Trivendram</option>
                </select>
              </div>
              <div className=" mx-4 wrapperMt">
                <select
                  className="form-select input-select-option"
                  aria-label="Default select example"
                  onChange={(e) => {
                    // setMonth(e.target.value);
                    setSearchUsersDetais((prev) => ({
                      ...prev,
                      month: Number(e.target.value),
                    }));

                    // getDataFromApi()
                  }}
                  value={searchUserDetails.month}
                >
                  {/* <option value=''>Select Month</option> */}
                  {months &&
                    months.map((data, index) => {
                      return (
                        <option value={data?.MonthId} key={index}>
                          {data?.Month}{" "}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="wrapperMt" style={{ marginTop: "24px" }}>
                <select
                  className="form-select input-select-option"
                  aria-label="Default select example"
                  onChange={(e) => {
                    // setYear(Number(e.target.value) + 1).toString();
                    setSearchUsersDetais((prev) => ({
                      ...prev,
                      year: Number(e.target.value),
                    }));
                  }}
                  value={searchUserDetails.year}
                >
                  {/* <option value=''>Select Year</option> */}
                  {years.map((year, i) => {
                    return (
                      <option key={i} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="import-export-btn-wrapper mt-4">
                <button style={{ marginLeft: "0px" }}>
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={ic_excel}
                      onClick={(e) => downloadExcelFile(e)}
                      alt=""
                    />
                  )}
                </button>
              </div>
            </div>
            <div
              className="import-export-btn-wrapper"
              style={{ marginTop: "24px" }}
            >
              {permissions && permissions["User Tracking Excel"] && (
                <button>
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={ic_excel}
                      // onClick={(e) => downloadExcelFile(e)}
                      alt=""
                    />
                  )}
                </button>
              )}
              {/* <label htmlFor="selectFile" className="file-input-btn">
                                    <img src={ic_export1} alt="" />
                                    <input
                                        type="file"
                                        accept=".xlsx"
                                        // onChange={handleFileChange}
                                    // id="selectFile"
                                    />
                                </label> */}
            </div>
          </div>
          <div
            className="table-wrapper-main"
            id="scrol-tbl"
            style={{ marginBottom: "55px" }}
          >
            <table>
              <tr className="firstTabelRow">
                <th>Sr. no.</th>
                <th>Date</th>
                <th>Lat</th>
                <th>Long</th>
              </tr>
              {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
              <tbody>
                {apiData.map((i, index) => {
                  return (
                    <tr className="table-row-main" key={index}>
                      <td>{index + 1}</td>
                      <td>{i.Date}</td>
                      <td>{i.latitude}</td>
                      <td>{i.longitude}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTracking;
