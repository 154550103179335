import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Pagination from "../../sharedComponent/Pagination";
import BookIcon from "../../assets/images/ic_excel.svg";
import edit from "../../assets/images/edit_officeCaller.svg";
import eye from "../../assets/images/ic_show_eyes_officecaller.svg";
import plus from "../../assets/images/plus_officecaller.svg";
import deleteicon from "../../assets/images/delete_icon_gray.svg";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Form from "react-bootstrap/Form";
import close from "../../assets/images/close.svg";

const NonFunctionalFeatures = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  const [bulkUpload, setBulkUpload] = useState(false);
  const [addNonFunctionalFeature, setAddNonFunctionalFeature] = useState(false);
  const [editNonFunctionalFeature, setEditNonFunctionalFeature] =
    useState(false);
  const [deleteNonFunctionalFeature, setDeleteNonFunctionalFeature] =
    useState(false);

  const handleBulkUpload = () => setBulkUpload(true);
  const handleBulkUpldClose = () => setBulkUpload(false);

  const handleAddNonFunctionalFeature = () => setAddNonFunctionalFeature(true);
  const handleAddNonFunctnlFeatrClose = () => setAddNonFunctionalFeature(false);

  const handleEditNonFunctionalFeature = () =>
    setEditNonFunctionalFeature(true);
  const handleEditNonFunctnlFeatrClose = () =>
    setEditNonFunctionalFeature(false);

  const handleDeleteNonFunctionalFeature = () =>
    setDeleteNonFunctionalFeature(true);
  const handleDeleteNonFunctnlFeatrClose = () =>
    setDeleteNonFunctionalFeature(false);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const agentdata = [
    {
      srNo: 1,
      module: "Security	",
      feature: "Prohibit unauthorised access, usage, or behaviour modification",
      functionalUnit: "EI",
      weightingFactor: "High",
      basicOrAdvanced: "Advanced",
    },
    {
      srNo: 2,
      module: "Security	",
      feature: "Prohibit unauthorised access, usage, or behaviour modification",
      functionalUnit: "EI",
      weightingFactor: "High",
      basicOrAdvanced: "Advanced",
    },
    {
      srNo: 3,
      module: "Security	",
      feature: "Prohibit unauthorised access, usage, or behaviour modification",
      functionalUnit: "EI",
      weightingFactor: "High",
      basicOrAdvanced: "Advanced",
    },
    {
      srNo: 4,
      module: "Security	",
      feature: "Prohibit unauthorised access, usage, or behaviour modification",
      functionalUnit: "EI",
      weightingFactor: "High",
      basicOrAdvanced: "Advanced",
    },
    {
      srNo: 5,
      module: "Security	",
      feature: "Prohibit unauthorised access, usage, or behaviour modification",
      functionalUnit: "EI",
      weightingFactor: "High",
      basicOrAdvanced: "Advanced",
    },
    {
      srNo: 6,
      module: "Security	",
      feature: "Prohibit unauthorised access, usage, or behaviour modification",
      functionalUnit: "EI",
      weightingFactor: "High",
      basicOrAdvanced: "Advanced",
    },
    {
      srNo: 7,
      module: "Security	",
      feature: "Prohibit unauthorised access, usage, or behaviour modification",
      functionalUnit: "EI",
      weightingFactor: "High",
      basicOrAdvanced: "Advanced",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <div className="textHeader">
            <h6 className="mt-2">Non-Functional Features</h6>
          </div>
          <div id="crmOfc-calr-hdr" className="buttonsInsider">
            <button
              className="create-btn-sub-header"
              variant="primary"
              onClick={() => {
                handleBulkUpload();
              }}
            >
              BULK UPLOAD
            </button>
            <button
              className="create-btn-sub-header"
              variant="primary"
              onClick={() => {
                handleAddNonFunctionalFeature();
              }}
            >
              + ADD NON-FUNCTIONAL FEATURE
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text" placeholder="Search here" />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                value={selectedRows}
                onChange={handleSelectChange}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            <div className="booksIcon" style={{ textAlign: "center" }}>
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>
        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">Sr.No.</th>
                <th scope="col">Module</th>

                <th scope="col">Feature</th>
                <th scope="col">Functional Unit</th>
                <th scope="col">Weighting Factor</th>
                <th scope="col">Basic/ Advanced</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {agentdata.map((item, index) => (
                <tr key={index} className="table-row-custom">
                  <td>{item.srNo}</td>
                  <td>{item.module}</td>
                  <td> {item.feature}</td>
                  <td> {item.functionalUnit}</td>
                  <td> {item.weightingFactor}</td>
                  <td> {item.basicOrAdvanced}</td>
                  <td>
                    <div>
                      <img
                        src={edit}
                        alt="edit"
                        className="cell-image"
                        onClick={() => {
                          handleEditNonFunctionalFeature();
                        }}
                      />
                      <Link to="/ViewNonFunctionalFeatures">
                        <img src={eye} alt="view" className="cell-image ml-1" />
                      </Link>
                      <img
                        src={deleteicon}
                        alt="delete"
                        className="cell-image ml-1"
                        onClick={() => {
                          handleDeleteNonFunctionalFeature();
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination />
        </div>
      </div>

      <Modal
        className="edit-role-model-arbaz"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={bulkUpload}
        onHide={handleBulkUpldClose}
      >
        <Modal.Header>
          <Modal.Title>
            {/* <label htmlFor="" className="create-role-text"> */}
            Bulk Upload
            {/* </label> */}
          </Modal.Title>
          <img
            src={close}
            alt=""
            onClick={handleBulkUpldClose}
            className="cr-class"
          />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Form>
          <Modal.Body>
            <div className="modalProp mb-4">
              <label for="formFile" class="enter-role-text mt-2 my-1">
                Upload File
              </label>
              <input
                class="form-control tasKCategory1"
                type="file"
                id="formFile"
              />
            </div>
          </Modal.Body>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <div style={{ display: "inline-flex" }}>
              <button
                type="button"
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                onClick={handleBulkUpldClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              >
                Upload
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        className="edit-role-model-arbaz"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={addNonFunctionalFeature}
        onHide={handleAddNonFunctnlFeatrClose}
      >
        <Modal.Header>
          <Modal.Title>
            {/* <label htmlFor="" className="create-role-text"> */}
            Add Non-Functional Feature
            {/* </label> */}
          </Modal.Title>
          <img
            src={close}
            alt=""
            onClick={handleAddNonFunctnlFeatrClose}
            className="cr-class"
          />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Form>
          <Modal.Body>
            <div className="modalProp mb-3">
              <label htmlFor="" className="enter-role-text my-1">
                Module
              </label>
              <input
                type="text"
                name="Module"
                className="form-control tasKCategory1"
                required
              />
            </div>
            <div className="modalProp mb-3">
              <label htmlFor="" className="enter-role-text my-1">
                Feature
              </label>
              <input
                type="text"
                name="Feature"
                className="form-control tasKCategory1"
                required
              />
            </div>
            <div className="modalProp mb-3">
              <label htmlFor="" className="enter-role-text my-1">
                Functional Unit
              </label>
              <select
                name="FunctionalUnit"
                className="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                required
              >
                <option value="">Select Functional Unit 1</option>
                <option value="">Select Functional Unit 2</option>
                <option value="">Select Functional Unit 3</option>
              </select>
            </div>
            <div className="modalProp mb-3">
              <label htmlFor="" className="enter-role-text my-1">
                Weighting Factor
              </label>
              <select
                name="WeightingFactor"
                className="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                required
              >
                <option value="">Simple</option>
                <option value="">Average</option>
                <option value="">High</option>
              </select>
            </div>
            <div className="modalProp mb-3">
              <label htmlFor="" className="enter-role-text my-1">
                Feature Type
              </label>
              <select
                name="FeatureType"
                className="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                required
              >
                <option value="">Basic</option>
                <option value="">Advanced</option>
              </select>
            </div>
          </Modal.Body>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <div style={{ display: "inline-flex" }}>
              <button
                type="button"
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                onClick={handleAddNonFunctnlFeatrClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              >
                Save
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        className="edit-role-model-arbaz"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={editNonFunctionalFeature}
        onHide={handleEditNonFunctnlFeatrClose}
      >
        <Modal.Header>
          <Modal.Title>
            {/* <label htmlFor="" className="create-role-text"> */}
            Update Non-Functional Feature
            {/* </label> */}
          </Modal.Title>
          <img
            src={close}
            alt=""
            onClick={handleEditNonFunctnlFeatrClose}
            className="cr-class"
          />
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Form>
          <Modal.Body>
            <div className="modalProp mb-3">
              <label htmlFor="" className="enter-role-text my-1">
                Module
              </label>
              <input
                type="text"
                name="Module"
                className="form-control tasKCategory1"
                required
              />
            </div>
            <div className="modalProp mb-3">
              <label htmlFor="" className="enter-role-text my-1">
                Feature
              </label>
              <input
                type="text"
                name="Feature"
                className="form-control tasKCategory1"
                required
              />
            </div>
            <div className="modalProp mb-3">
              <label htmlFor="" className="enter-role-text my-1">
                Functional Unit
              </label>
              <select
                name="FunctionalUnit"
                className="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                required
              >
                <option value="">Select Functional Unit 1</option>
                <option value="">Select Functional Unit 2</option>
                <option value="">Select Functional Unit 3</option>
              </select>
            </div>
            <div className="modalProp mb-3">
              <label htmlFor="" className="enter-role-text my-1">
                Weighting Factor
              </label>
              <select
                name="WeightingFactor"
                className="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                required
              >
                <option value="">Simple</option>
                <option value="">Average</option>
                <option value="">High</option>
              </select>
            </div>
            <div className="modalProp mb-3">
              <label htmlFor="" className="enter-role-text my-1">
                Feature Type
              </label>
              <select
                name="FeatureType"
                className="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                required
              >
                <option value="">Basic</option>
                <option value="">Advanced</option>
              </select>
            </div>
          </Modal.Body>
          <div className="hr-line-modal">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <div style={{ display: "inline-flex" }}>
              <button
                type="button"
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                onClick={handleEditNonFunctnlFeatrClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              >
                Save
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        className="delete-news-modal"
        dialogClassName="modal-90w"
        centered
        show={deleteNonFunctionalFeature}
        onHide={handleDeleteNonFunctnlFeatrClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          <p>Are you sure, you want to delete this Non-Functional Feature?</p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div style={{ display: "inline-flex" }}>
            <button
              type="button"
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={handleDeleteNonFunctnlFeatrClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NonFunctionalFeatures;
