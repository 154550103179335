import React, { useState, useContext, useEffect } from 'react'
import { AppContext } from '../../context/AppContext'
import Loader from '../Loader'
import { Link, useLocation } from 'react-router-dom'
import { simpleGetCallWithToken } from '../../api/ApiServices'
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import NoData from '../../sharedComponent/NoData'
import Pagination from '../../sharedComponent/Pagination'
import { getDDateYYYYMMDD, getDateddmmyyyy } from '../../common'
import Beatloader from '../../sharedComponent/Beatloader'
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const UserActivity = () => {
  const { sidebar, userData } = useContext(AppContext)
  console.log('userData,', userData)

  const [empoleName, setEmpoleName] = useState([])
  // GET_USER_ACTIVITY
  const employeenames = () => {
    PostCallWithErrorResponse(
      ApiConfig.EMPLOEE_NAME_LIST,
      JSON.stringify({ OrgId: Number(userData.OrgId) })
    )
      .then(res => {
        if (res.json?.Success) {
          setEmpoleName(res.json?.Data)
        }
      })
      .catch(err => {
        console.error('errror catch ' + err)
      })
  }
  useEffect(() => {
    employeenames()
    getActivityList()
  }, [])
  const location = useLocation()
  const EmpId = location?.state?.EmpId

  const [activityList, setActivityList] = useState([])

  const [excelLoading, setExcelLoading] = useState(false);

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    let body = {
      EmpId: Number(userData.EmpId),
      OrgId: Number(userData.OrgId),
      Date:activityPayLoad.Date,
      ExportToExcel: true
    }
    e.preventDefault(); 
    PostCallWithErrorResponse(ApiConfig.GET_USER_ACTIVITY_EXPORT,JSON.stringify(body))
    //PostCallWithErrorResponse(ApiConfig.GET_USER_ACTIVITY_EXPORT, JSON.stringify(body))
 
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const [activityPayLoad, setActivityPayLoad] = useState({
    EmpId:
      EmpId && EmpId
        ? EmpId
        : userData && userData?.EmpId && Number(userData?.EmpId),
    OrgId: userData && userData?.EmpId && Number(userData?.OrgId),
    Date: new Date()
  })

  const [loader, setLoader] = useState(false)
  const getActivityList = () => {
    setLoader(true)
    PostCallWithErrorResponse(
      ApiConfig.GET_USER_ACTIVITY,
      JSON.stringify(activityPayLoad)
    )
      .then(res => {
        if (res.json.Success) {
          setActivityList(res.json.Data.reverse())
        }
      })
      .catch(err => console.log('error response,', err))
      .finally(() => {
        setLoader(false)
      })
  }

  const [currentPage, setCurrentPage] = useState(0)
  const [usersPerPage, setUsersPerPage] = useState(5)
  const startIndex = currentPage * usersPerPage
  const endIndex = startIndex + parseInt(usersPerPage)

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected)
  }

  const displayUserActivity =
    Array.isArray(activityList) &&
    activityList.length > 0 &&
    activityList.slice(startIndex, endIndex).map((item, index) => {
      return (
        <tr key={item?.ActivityId} className='table-row-custom'>
          <td>{startIndex + index + 1}</td>
          <td>{item?.ModuleName}</td>
          <td>{item?.Activity}</td>
          <td>{item?.ActivityDate}</td>
        </tr>
      )
    })

  return (
    <>
      <div
        className={sidebar ? 'taskMain ' : 'cx-active taskMain'}
        id='cx-main'
      >
        <div className='role-content'>
          <div className='sub-header-role '>
            <h6 className='mt-2'>User Activity</h6>
          </div>
          <div className='row'>
            <div className='d-flex justify-content-between role-searchbar-and-btns RspSalary'>
              <div className='entries-wrapper'>
                <p>
                  Employee Names
                </p>
                <select
                  class='form-select tasKCategory1 select-drop-icon'
                  aria-label='Default select example'
                  onChange={e => {
                    setActivityPayLoad({
                      ...activityPayLoad,
                      EmpId: Number(e.target.value)
                    })
                  }}
                  value={activityPayLoad?.EmpId && activityPayLoad?.EmpId}
                >
                  <option value={''}>Please select Employee name</option>
                  {empoleName &&
                    empoleName.map((emp, idx) => {
                      return (
                        <option key={emp?.Id} value={emp?.Id}>
                          {emp?.FullName}
                        </option>
                      )
                    })}
                </select>
              {/* </div> */}
              <div className='entries-wrapper-main'>
                <p>Entries per page</p>
                <select
                  className='form-select select-drop-icon'
                  aria-label='Default select example'
                  onChange={e => {
                    setUsersPerPage(e.target.value)
                    setCurrentPage(0)
                  }}
                >
                  <option value='5'>5</option>
                  <option value='10'>10</option>
                  <option value='15'>15</option>
                  <option value='20'>20</option>
                  <option value='25'>25</option>
                </select>
              </div>
              </div>
              <div className="import-export-btn-wrapper mt-4">
                <button>
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={ic_excel}                      
                      onClick={(e) => downloadExcelFile(e)}
                      alt=""
                    />
                  )}
                </button>
              </div>   
            </div>
            <div className='row'>
              <div className='col-lg-4 col-md-12 col-sm-12 mb-3'>
                <p 
                  style={{
                    fontSize: '0.8rem',
                    color: 'grey',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  Date
                </p>
                {/* <label className='labelForASsign'>Date</label> */}
                <input
                  type='date'
                  className='form-control'
                  style={{ background: '#fff' }}
                  onChange={e => {
                    setActivityPayLoad({
                      ...activityPayLoad,
                      Date: new Date(e.target.value).toISOString().toString()
                    })
                  }}
                  value={
                    activityPayLoad?.Date &&
                    getDDateYYYYMMDD(activityPayLoad?.Date)
                  }
                />
              </div>
            </div>
            <div className='col-md-8 text-end'>
              <button
                type='button'
                className='allocateionBtn'
                onClick={getActivityList}
              >
                Search
              </button>
            </div>
          </div>

          <div className='table-wrapper' id="scrol-tblNtable-wrapper-af">
            {loader ? (
              <Beatloader />
            ) : activityList.length > 0 ? (
              <table>
                <thead>
                  <tr className='firstTabelRow'>
                    <></>
                    <th>Sr.no</th>
                    <th>Module Name</th>
                    <th> Activity</th>
                    <th> Activity Date</th>
                  </tr>
                </thead>
                <tbody>{displayUserActivity}</tbody>
              </table>
            ) : (
              <NoData />
            )}
          </div>
          {!loader && (
            <Pagination
              data={activityList}
              pageChangeHandler={pageChangeHandler}
              usersPerPage={usersPerPage}
              currentPage={currentPage}
              searchValue={''}
              searchedDataPageCounts={[]}
            />
          )}
        </div>
        {/* <Pagination
        // data={userLogin}
        // pageChangeHandler={pageChangeHandler}
        // usersPerPage={usersPerPage}
        // currentPage={currentPage}
        // searchValue={searchValue}
        // searchedDataPageCounts={searchedDataPageCounts}
        /> */}
      </div>
    </>
  )
}

export default UserActivity
