import BookIcon from "../../assets/images/ic_excel.svg";
import Pagination from "../../sharedComponent/Pagination";
import React, { useContext, useEffect, useState } from "react";
import down_arrow from "../../assets/images/down_arrow.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import searchicon from "../../assets/images/ic_search.svg";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const AgentList = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);

  // show button
  const [selectedRows, setSelectedRows] = useState(10);

  const handleSelectChange = (event) => {
    setSelectedRows(event.target.value);
    console.log(`Selected number of rows: ${event.target.value}`);
    // You can add additional logic to handle the row change
  };
  // show button end

  const agentdata = [
    {
      name: "",
      department: "",
      mobilenumber: "9307776804",
      email: "pankaj.p@coudxperte.com",
      createdate: "2023-Dec-28",
      gender: "None",
      location: "None",
    },
    {
      name: "Prasad Andure",
      department: "",
      mobilenumber: "9158361888",
      email: "prasad.andure@redbytes.co.uk",
      createdate: "2022-oct-18",
      gender: "Male",
      location: "None",
    },
    {
      name: "Shabana Akil",
      department: "",
      mobilenumber: "8308506037",
      email: "accounts@redbytes.co.uk",
      createdate: "2022-Jul-15",
      gender: "None",
      location: "None",
    },
    {
      name: "Pratiksha",
      department: "",
      mobilenumber: "9834772311",
      email: "pratikshabhandari.redbytes@gmail.com",
      createdate: "2022-Apr-27",
      gender: "None",
      location: "None",
    },
    {
      name: "Anshika Srivastava",
      department: "",
      mobilenumber: "7007111696",
      email: "anshika.redbytes@gmail.com",
      createdate: "2021-Mar-10",
      gender: "Female",
      location: "Pune",
    },
    {
      name: "Sangam Jain",
      department: "",
      mobilenumber: "9101513393",
      email: "sangamjain@redbytes.in",
      createdate: "2021-Mar-10",
      gender: "Male",
      location: "Na",
    },
    {
      name: "Vrishali Deshmukh",
      department: "",
      mobilenumber: "8329918254",
      email: "vrishali.deshmukh@redbytes.co.uk",
      createdate: "2021-Mar-21",
      gender: "Female",
      location: "Pune",
    },
  ];

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Agent</h6>
        </div>

        <div className="d-flex justify-content-between mainHeader custom-mb">
          <div className="role-table-header-main">
            <div className="search-input-wrapper mt-0 cus-mb">
              <input type="text" placeholder="Search here" />

              {Dark === "lightMode" ? (
                <img src={searchicon} alt="" />
              ) : (
                <img src={ic_search_dark} alt="" />
              )}
            </div>
            <div className="entries-wrapper mt-0 cus-mb">
              <p>Entries per page</p>
              <select
                className="form-select select-drop-icon"
                aria-label="Default select example"
                value={selectedRows}
                onChange={handleSelectChange}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between mainIcons">
            <div className="booksIcon" style={{ textAlign: "center" }}>
              <img src={BookIcon} className="innerIconsBtn" alt="" />
            </div>
          </div>
        </div>

        <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
          <table>
            <thead>
              <tr className="firstTabelRow">
                <th scope="col">Name</th>
                <th scope="col">Department</th>

                <th scope="col">Mobile Number</th>
                <th scope="col">Email</th>
                <th scope="col">Create Date</th>
                <th scope="col">Gender</th>
                <th scope="col">Location</th>
                <th scope="col">View Details</th>
                <th scope="col">Assigned Calls</th>
              </tr>
            </thead>
            <tbody>
              {agentdata.map((item, index) => (
                <tr key={index} className="table-row-custom">
                  <td>{item.name}</td>
                  <td>{item.department}</td>

                  <td> {item.mobilenumber}</td>
                  <td>{item.email}</td>
                  <td>{item.createdate}</td>
                  <td>{item.gender}</td>
                  <td>{item.location}</td>
                  <td>
                    <button className="imporButton">
                      <Link to="/UserDetailsForm" className="details-link">
                        Details
                      </Link>
                    </button>
                  </td>
                  <td>
                    <button className="imporButton">
                      <Link to="/AgentAssignedCalls" className="details-link">
                        Assigned Calls
                      </Link>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default AgentList;
