import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";

import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import edit from "../../assets/images/edit.svg";
import eye from "../../assets/images/ic_show_eyes.svg";
import plus from "../../assets/images/plus.svg";
import { Link, Navigate } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import Pagination from "../../sharedComponent/Pagination";
import ic_excel from "../../assets/images/ic_excel.svg";

const categories = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const months = [
  "From Email",
  "To Email",
  "Message Id",
  "Subject",
  "Status",
  "Opens Count",
  "Clicks Count",
  "Last Event Time",
];
const data = [
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Total
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Tot // IOT
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Tot// Services
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Tot // Booking
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Totk,// Social
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Tote
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Toth
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Totn
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Tot
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Tot
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Tot
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Tot
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Tot
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Tot
  [
    "info@redbytes.in",
    "ravan899s@gmail.com",
    "1p5aefNYTWSDZa_GUZ1BqQ.filterdrecv-74cbf9986b-p8tzn-1-628C796E-58.0",
    "Redbytes - App Cost Calculator",
    "delivered",

    "0",
    "0",
    "24-05-22 11:51:37",
  ], // Tot
];

const EmailReport = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [key, setKey] = useState("personalDetails");
  console.log("Key data >>>>>>>>>>", key);

  const navigate = useNavigate();

  return (
    <div>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Email Report</h6>
          </div>

          <div className="LeadSearchrow mt-2 ">
            <h6 className="mt-2">Last Synced - Feb. 19, 2024, 1:54 p.m.</h6>
            <div className="reset">
              <p>Sync Mail</p>
            </div>
          </div>

          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search "
                  // onChange={(e) => {
                  //   setSearchValue(e.target.value);
                  //   setCurrentPage(0);
                  // }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  // onChange={(e) => {
                  //   setUsersPerPage(e.target.value);
                  //   setCurrentPage(0);
                  // }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
              </div>
            </div>

            <div className="import-export-btn-wrapper mt-4">
              <div className="reset mr-2">
                <p>Reset</p>
              </div>
              <button>
                {/* {excelLoading ? (
                  <ExcelLoader />
                ) : ( */}
                <img
                  src={ic_excel}
                  // onClick={(e) => downloadExcelFile(e)}
                  alt=""
                />
                {/* )} */}
              </button>
            </div>
          </div>

          <div className="table-wrapper" id="scrol-tblNtable-wrapper-af">
            <table>
              <thead>
                <tr className="firstTabelRow">
                  <th className="header">Sr.No</th>
                  {months.map((month, index) => (
                    <th key={index} className="header">
                      {month}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {categories.map((category, catIndex) => (
                  <tr key={catIndex} className="table-row-custom">
                    <td>{category}</td>
                    {data[catIndex].map((value, monthIndex) => (
                      <td key={monthIndex}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
      // data={EmployeeList}
      // pageChangeHandler={pageChangeHandler}
      // usersPerPage={usersPerPage}
      // currentPage={currentPage}
      // searchValue={searchValue}
      // searchedDataPageCounts={searchedDataPageCounts}
      />
    </div>
  );
};

export default EmailReport;
