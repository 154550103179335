import React, { useContext, useState, useEffect } from 'react'
import { AppContext } from '../../context/AppContext'
import searchicon from '../../assets/images/ic_search.svg'
import downArrow from '../../assets/images/downArrow.png'
import upArrow from '../../assets/images/upArrow.png'
import ic_search_dark from '../../assets/images/ic_search_dark.svg'
import { Accordion, Dropdown } from 'react-bootstrap'
import '../../assets/css/Timesheet.scss'
import Modal from 'react-bootstrap/Modal'
import BookIcon from '../../assets/images/ic_excel.svg'
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import { ToastMsg } from '../TostMsg'
import Loader from '../Loader'
import { getDateddmmyyyy } from '../../common'
import { notifyError, notifySuccess } from '../../toaster/notify'
import { getDDateddmmyyyy, minutesInHors } from '../../common'
import Pagination from '../../sharedComponent/Pagination'
import moment from 'moment'
import BackDropLoader from '../../sharedComponent/BackDropLoader'
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const TimesheetApproval = () => {
  const [dropdownacc, setDropdownacc] = useState(false)
  const { sidebar, setSidebar, Dark, setDark, userData,permissions } =
    useContext(AppContext)
  const [timeSheetApprove, setTimeSheetApprove] = useState([])
  const [timesheetApprovalList, setTimesheetApprovalList] = useState([])
  console.log('timesheetApprovalList,', timesheetApprovalList)
  const [searchValue, setSearchValue] = useState('')

  const [currentPage, setCurrentPage] = useState(0)
  const [usersPerPage, setUsersPerPage] = useState(5)

  const startIndex = currentPage * usersPerPage
  const endIndex = startIndex + parseInt(usersPerPage)

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected)
  }
  const [excelLoading, setExcelLoading] = useState(false);

  const searchedDataPageCounts =
    timesheetApprovalList &&
    timesheetApprovalList.length &&
    timesheetApprovalList.filter(item =>
      item.EmployeeName?.toLowerCase().includes(searchValue.toLowerCase())
    )

  useEffect(() => {
    getTimeSheetDetails()
  }, [])

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    console.log("Indside Download Excel File")
    e.preventDefault();
    simpleGetCallWithErrorResponse(ApiConfig.NO_BACKEND_API )
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [sortOrder, setSortOrder] = useState('asc');
  const handleSort = () => {
    const sortedData = [...timesheetApprovalList].sort((a, b) => {
      if (a.status < b.status) return sortOrder === 'asc' ? -1 : 1;
      if (a.status > b.status) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    setTimesheetApprovalList(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  }

  const [btnLoading, setBtnLoading] = useState({
    approveLoading: false,
    rejectLoading: false,
    index: 0
  })
  const getApproveProject = (
    id,
    isApprove,
    btntype,
    EmployeeId,
    innerIndex
  ) => {
    btntype === 'approve'
      ? setBtnLoading({
          approveLoading: true,
          rejectLoading: false,
          index: innerIndex
        })
      : setBtnLoading({
          approveLoading: false,
          rejectLoading: true,
          index: innerIndex
        })

    PostCallWithErrorResponse(
      ApiConfig.APPROVED_PROJECT,
      JSON.stringify({
        TimeSheetId: id,
        EmpId: EmployeeId,
        OrgId: Number(userData.OrgId),
        IsApproved: isApprove
      })
    )
      .then(res => {
        console.log('reject response,', res)
        setBtnLoading({
          approveLoading: false,
          rejectLoading: false,
          index: 0
        })
        if (res.json.Data.Success === true) {
          getTimeSheetDetails()

          ToastMsg('success', res.json.Data.Message)
          //notifySuccess(res.json.Data.Message)
        } else {
          setBtnLoading({
            approveLoading: false,
            rejectLoading: false,
            index: 0
          })
          ToastMsg('error', res.json.Message)
        }
      })
      .catch(err => {
        setBtnLoading({
          approveLoading: false,
          rejectLoading: false,
          index: 0
        })
        console.log(err)
      })
  }

  const getTimeSheetApproveApi = () => {
    PostCallWithErrorResponse(
      ApiConfig.TIMESHETT_APPROVECOUNT,
      JSON.stringify({
        EmployeeId: Number(userData.EmpId),
        OrgId: Number(userData.OrgId)
      })
    )
      .then(res => {
        setTimeSheetApprove(res.json.Data ? res.json?.Data : [])
      })
      .catch(err => {
        console.log(err)
      })
  }

  const [loader, setLoader] = useState(false)

  const getTimeSheetDetails = () => {
    setLoader(true)
    PostCallWithErrorResponse(ApiConfig.TIMESHEET_APPROVE_DETAILS)
      .then(res => {
        setLoader(false)
        if (res.json.Success === true) {
          setTimesheetApprovalList(res.json.Data)
        }
      })
      .catch(err => {
        setLoader(false)
        console.log(err)
      })
  }

  const [selectedTimesheets, setSelectedTimesheets] = useState([])
  console.log('selectedTimesheets,', selectedTimesheets)
  const [loading, setLoading] = useState(false)
  // APPROVE MULTIPLE TIMESHEET
  const approveMultipleTimesheet = () => {
    setLoading(true)
    let payLoad = {
      ApproveAndReject: selectedTimesheets
    }

    PostCallWithErrorResponse(
      ApiConfig.MULTIPLE_TIMESHEET_APPROVE,
      JSON.stringify(payLoad)
    )
      .then(res => {
        if (res.json.Success === true) {
          notifySuccess('Timesheet approve successfully')
          setSelectedTimesheets([])
          getTimeSheetDetails()
        } else {
          notifyError('Something went wrong')
        }
      })
      .catch(err => {
        console.log('err respnse,', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const btnLoader = (
    <div
      class='spinner-border text-info'
      role='status'
      style={{
        width: '0.8rem',
        height: '0.8rem',
        position: 'static',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: '8px'
      }}
    ></div>
  )

  const displayData =
    timesheetApprovalList &&
    timesheetApprovalList
      ?.slice(startIndex, endIndex)
      .map((timedeta, innerIndex) => {
        let selectedTimesheetIdsArr =
          selectedTimesheets &&
          selectedTimesheets.map(item => item?.TimesheetId)
        return (
          <tr
            className='tabel-body-acordince'
            key={'apoiosd' + innerIndex}
            // style={
            //   selectedTimesheetIdsArr?.includes(timedeta?.TimeSheetId)
            //     ? { backgroundColor: 'rgb(184 218 235)' }
            //     : {}
            // }
          >
            <td>
              <input
                style={{ cursor: 'pointer' }}
                checked={selectedTimesheetIdsArr?.includes(
                  timedeta?.TimeSheetId
                )}
                type='checkbox'
                onChange={() => {
                  if (
                    !selectedTimesheetIdsArr?.includes(timedeta?.TimeSheetId)
                  ) {
                    setSelectedTimesheets([
                      ...selectedTimesheets,
                      {
                        TimesheetId: timedeta?.TimeSheetId,
                        EmpId: timedeta?.EmpId,
                        OrgId: Number(userData?.OrgId),
                        IsApproved: true
                      }
                    ])
                  } else {
                    let filteredArr =
                      selectedTimesheets &&
                      selectedTimesheets.filter(item => {
                        return item?.TimesheetId !== timedeta?.TimeSheetId
                      })
                    setSelectedTimesheets(filteredArr)
                  }
                }}
              />
            </td>
            <td>{startIndex + innerIndex + 1}</td>
            {/* <td>{getDateddmmyyyy(timedeta.Date)}</td> */}
            <td>{timedeta.EmployeeName}</td>
            <td>{timedeta.ProjectName}</td>
            <td className='arrangeSpace'>{timedeta.TaskName}</td>
            <td className='fixed-width'>
              <ul>
                <li>{timedeta.Activity}</li>
              </ul>
            </td>
            <td>{timedeta.WorkedHour}</td>
            <td>{getDDateddmmyyyy(timedeta?.Date)}</td>

            <td>
              <div style={{ display: 'flex' }}>
                {permissions&&permissions['Timesheet Approval Approve']?btnLoading.approveLoading && btnLoading.index === innerIndex
                  ? btnLoader
                  : !selectedTimesheets.length > 0 && (
                      <button
                        className='btn-green'
                        onClick={e => {
                          e.stopPropagation()
                          getApproveProject(
                            timedeta.TimeSheetId,
                            true,
                            'approve',
                            timedeta.EmpId,
                            innerIndex
                          )
                        }}
                      >
                        Approve
                      </button>
                    ):null}
                {
                permissions&&permissions['Timesheet Approval Reject']?
                btnLoading.rejectLoading && btnLoading.index === innerIndex ? (
                  btnLoader
                ) : (
                  <button
                    className='btn-red'
                    onClick={e => {
                      e.stopPropagation()
                      getApproveProject(
                        timedeta.TimeSheetId,
                        false,
                        'reject',
                        timedeta.EmpId,
                        innerIndex
                      )
                    }}
                  >
                    Reject
                  </button>
                ):null}
              </div>
            </td>
          </tr>
        )
      })

  const displaySearchedData =
    timesheetApprovalList &&
    timesheetApprovalList
      ?.filter(item => item.EmployeeName?.toLowerCase().includes(searchValue.toLowerCase()))
      .slice(startIndex, endIndex)
      .map((timedeta, innerIndex) => {
        let selectedTimesheetIdsArr =
          selectedTimesheets &&
          selectedTimesheets.map(item => item?.TimesheetId)

        return (
          <tr
            className='tabel-body-acordince'
            key={'apoiosd' + innerIndex}
            // style={
            //   selectedTimesheetIdsArr?.includes(timedeta?.TimeSheetId)
            //     ? { backgroundColor: 'rgb(184 218 235)' }
            //     : {}
            // }
          >
            <td>
              <input
                style={{ cursor: 'pointer' }}
                checked={selectedTimesheetIdsArr?.includes(
                  timedeta?.TimeSheetId
                )}
                type='checkbox'
                onChange={() => {
                  if (
                    !selectedTimesheetIdsArr?.includes(timedeta?.TimeSheetId)
                  ) {
                    setSelectedTimesheets([
                      ...selectedTimesheets,
                      {
                        TimesheetId: timedeta?.TimeSheetId,
                        EmpId: timedeta?.EmpId,
                        OrgId: Number(userData?.OrgId),
                        IsApproved: true
                      }
                    ])
                  } else {
                    let filteredArr =
                      selectedTimesheets &&
                      selectedTimesheets.filter(item => {
                        return item?.TimesheetId !== timedeta?.TimeSheetId
                      })
                    setSelectedTimesheets(filteredArr)
                  }
                }}
              />
            </td>
            <td>{startIndex + innerIndex + 1}</td>
            {/* <td>{getDateddmmyyyy(timedeta.Date)}</td> */}
            <td>{timedeta.EmployeeName}</td>
            <td>{timedeta.ProjectName}</td>
            <td className='arrangeSpace'>{timedeta.TaskName}</td>
            <td className='fixed-width'>
              <ul>
                <li>{timedeta.Activity}</li>
              </ul>
            </td>
            <td>{timedeta?.WorkedHour}</td>
            <td>{getDDateddmmyyyy(timedeta?.Date)}</td>
            <td>
              <div style={{ display: 'flex' }}>
                {permissions&&permissions['Timesheet Approval Approve']?btnLoading.approveLoading && btnLoading.index === innerIndex
                  ? btnLoader
                  : !selectedTimesheets.length > 0 && (
                      <button
                        className='btn-green'
                        onClick={e =>
                          getApproveProject(
                            timedeta.TimeSheetId,
                            true,
                            'approve',
                            timedeta.EmpId,
                            innerIndex
                          )
                        }
                      >
                        Approve
                      </button>
                    ):null}
                {permissions&&permissions['Timesheet Approval Reject']?btnLoading.rejectLoading && btnLoading.index === innerIndex ? (
                  btnLoader
                ) : (
                  <button
                    className='btn-red'
                    onClick={e =>
                      getApproveProject(
                        timedeta.TimeSheetId,
                        false,
                        'reject',
                        timedeta.EmpId,
                        innerIndex
                      )
                    }
                  >
                    Reject 
                  </button>
                ):null}
              </div>
            </td>
          </tr>
        )
      })

  return (
    <>
      {loading && <BackDropLoader />}
      <div
        className={sidebar ? 'taskMain ' : 'cx-active taskMain'}
        id='cx-main'
      >
        <div className='role-content'>
          <div className='sub-header-role '>
            <h6 className='mt-2'>Timesheet Approval</h6>
            {
            permissions&&permissions['Timesheet Approval Approve All']?
            selectedTimesheets.length > 0 && (
              <button
                className='btn-green'
                type='button'
                onClick={approveMultipleTimesheet}
              >
                Approve All
              </button>
            ):null}
          </div>

          <div className='d-flex justify-content-between role-searchbar-and-btns RspSalary'>
            <div className='role-table-header'>
              <div className='search-input-wrapper'>
                <input
                  type='text'
                  placeholder='Search Employee Name'
                  onChange={e => {
                    setSearchValue(e.target.value)
                    setCurrentPage(0)
                  }}
                />
                {Dark === 'lightMode' ? (
                  <img src={searchicon} alt='' />
                ) : (
                  <img src={ic_search_dark} alt='' />
                )}
              </div>
              <div className='entries-wrapper'>
                <p>Sort By</p>
                <select
                  className='form-select select-drop-icon'
                  aria-label='Default select example'
                >
                  <option>All Runing Projects</option>
                  <option value='1' onClick={handleSort}>Project Name</option>
                  <option value='2'>FIFO</option>
                  <option value='3'>LIFO</option>
                  {/* <option value='2'>Quick To Do</option> */}
                </select>
              </div>
              <div className='entries-wrapper'>
                <p>Entries per page</p>
                <select
                  className='form-select select-drop-icon'
                  aria-label='Default select example'
                  onChange={e => {
                    setUsersPerPage(e.target.value)
                    setCurrentPage(0)
                  }}
                >
                  <option value='5'>5</option>
                  <option value='10'>10</option>
                  <option value='15'>15</option>
                  <option value='20'>20</option>
                  <option value='25'>25</option>
                </select>
              </div>
            </div>
            {
              permissions&&permissions['Timesheet Approval Excel']&&
            <div className='d-flex justify-content-between mainIcons mt-4'>
              <div className='booksIcon'>
                
                  {excelLoading ? (
                    <ExcelLoader />
                   ) : (
                  <img 
                   src={BookIcon} className='innerIconsBtn' 
                    onClick={(e) => downloadExcelFile(e)}
                   alt='' />
                  )}
                
              </div>
            </div>
            }
          </div>
          {loader ? (
            <Loader />
          ) : timesheetApprovalList && searchedDataPageCounts.length > 0 ? (
            <div className='AccordianBody' id='scrol-tblNtable-wrapper-af'>
              <table id='tbl-rowBld'>
                <thead style={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: "#fff",
                  zIndex: "1"
                
                }}>
                  <tr className='table-row-custom bodypadding'>
                    <th></th>
                    <th>Sr. No</th>
                    <th>Employee Name</th>
                    <th onClick={handleSort} style={{ cursor: 'pointer' }}
                  >Project Name {sortOrder === 'asc' ? '▲' : '▼'} </th>
                    <th>Task Name</th>
                    <th>Activity</th>
                    <th>Total Working Hrs</th>
                    <th>Date</th>
                    <th>Action</th>
                    {/* {!selectedTimesheets.length > 0 && <td>Action</td>} */}
                  </tr>
                </thead>
                <tbody>
                  {searchValue === '' ? displayData : displaySearchedData}
                </tbody>
              </table>
            </div>
          ) : (
            <div
              style={{
                marginTop: '50px',
                marginBottom: '10px',
                textAlign: 'center',
                width: '100%',
                color: 'red'
              }}
            >
              Data Not Found
            </div>
          )}

          {/* {list === "" ? (
            <Accordion>
              <Accordion.Item eventKey="" className="topAccordiance">
                <Accordion.Header>
                  <div className="table-wrapper ">
                    <table>
                      <tr>
                        <th className="serialnumber">Sr.No</th>
                        <th className="projectname">Date</th>
                        <th className="totalbudget">No of Projects</th>
                        <th className="remark">Remark</th>
                      </tr>
                    </table>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
              {loading ? (
                <Loader />
              ) : timeSheetApprove && timeSheetApprove.length > 0 ? (
                timeSheetApprove &&
                timeSheetApprove.map((timeAprove, index) => {
                  return (
                    <Accordion.Item eventKey={"inosadf" + index}>
                      <Accordion.Header>
                        <div className="table-wrapper table-row-custom-padding">
                          <table>
                            <tr
                              className="table-row-custom bodypadding"
                              onClick={() => {
                                setDropdownacc(!dropdownacc);
                                getTimeSheetDetails(timeAprove.WorkDate);
                              }}
                            >
                              <td> {index + 1} </td>
                              <td> {timeAprove.WorkDate}</td>
                              <td>{timeAprove.NoOfProjects}</td>
                              <td>
                                <label htmlFor="" className="me-3">
                                  {" "}
                                  {timeAprove.ApprovedActivityCount} of{" "}
                                  {timeAprove.ActivityCount} Approved &nbsp;
                                </label>
                                {dropdownacc == true ? (
                                  <img src={upArrow} alt="" />
                                ) : (
                                  <img src={downArrow} alt="" />
                                )}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="AccordianBody">
                          <table>
                            <thead>
                              <tr className="table-row-custom bodypadding">
                                <td>Employee Name</td>
                                <td>Project Name</td>
                                <td>Task Name</td>
                                <td>Activity</td>
                                <td>Total Working Hrs</td>
                                <td>Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {loader ? (<div
                                style={{
                                  marginLeft: "246%",
                                  marginTop: "20px",
                                  marginBottom: "20px",
                                  position: "static"
                                }}
                                class="spinner-border text-info"
                                role="status"
                              ></div>) : timesheetApprovalList && timesheetApprovalList.length > 0 ? ((timesheetApprovalList &&
                                timesheetApprovalList.map((timedeta, innerIndex) => {
                                  return (
                                    <tr
                                      className="tabel-body-acordince"
                                      key={"apoiosd" + index}
                                    >
                                      <td>{timedeta.EmployeeName}</td>
                                      <td>{timedeta.ProjectName}</td>
                                      <td>{timedeta.TaskName}</td>
                                      <td className="fixed-width">
                                        <ul>
                                          <li>{timedeta.Activity}</li>
                                        </ul>
                                      </td>
                                      <td>{timedeta.WorkedeHours}</td>
                                      <td>
                                        <div
                                          style={{ display: "flex" }}
                                        >
                                          {
                                            btnLoading.approveLoading && btnLoading.index === innerIndex 
                                            ? btnLoader :
                                              <button
                                                className="btn-green"
                                                onClick={(e) =>
                                                  getApproveProject(
                                                    timedeta.TimeSheetId,
                                                    true,
                                                    "approve",
                                                    innerIndex
                                                  )
                                                }
                                              >
                                                Approve
                                              </button>
                                          }
                                          {
                                            btnLoading.rejectLoading && btnLoading.index === innerIndex 
                                            ? btnLoader :
                                              <button
                                                className="btn-red"
                                                onClick={(e) =>
                                                  getApproveProject(
                                                    timedeta.TimeSheetId,
                                                    false,
                                                    "reject",
                                                    innerIndex
                                                  )
                                                }
                                              >
                                                Reject
                                              </button>
                                          }
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }))) : (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    marginLeft : "190%",
                                    textAlign: "center",
                                    width: "100%",
                                    color: "red"
                                  }}
                                >
                                  Data Not Found
                                </div>
                              )
                              }
                            </tbody>
                          </table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })
              ) : (
                <tr className="ErrorDataNotFound">
                  <td colSpan={6}>Data Not Found</td>
                </tr>
              )}
            </Accordion>
          ) : (
            <div className="table-wrapper">
              <table>
                <tr className="firstTabelRow">
                  <th>Sr. no</th>
                  <th>Date</th>
                  <th>Employee Name</th>
                  <th>Project Namece</th>
                  <th>Task Name</th>
                  <th>Activity</th>
                  <th>Budget Hrs</th>
                  <th>Action</th>
                </tr>

                <tr className="table-row-custom">
                  <td> 1</td>
                  <td>09-08-2022</td>
                  <td>Jhon Doe</td>
                  <td>Project Name</td>
                  <td>UI Design</td>
                  <td>Banner Designing</td>
                  <td>08</td>
                  <td>
                    <button className="btn-green">Approve</button>
                    <button className="btn-red">Reject</button>
                  </td>
                </tr>
                <tr className="table-row-custom">
                  <td> 2</td>
                  <td>29-08-2022</td>
                  <td>Jhon Doe</td>
                  <td>Project Name</td>
                  <td>UI Design</td>
                  <td>Banner Designing</td>
                  <td>07</td>
                  <td>
                    <button className="btn-green">Approve</button>
                    <button className="btn-red">Reject</button>
                  </td>
                </tr>
                <tr className="table-row-custom">
                  <td> 3</td>
                  <td>02-11-2022</td>
                  <td>Jhon Doe</td>
                  <td>Project Name</td>
                  <td>UI Design</td>
                  <td>Banner Designing</td>
                  <td>21</td>
                  <td>
                    <button className="btn-green">Approve</button>
                    <button className="btn-red">Reject</button>
                  </td>
                </tr>
                <tr className="table-row-custom">
                  <td> 4</td>
                  <td>09-09-2022</td>
                  <td>Jhon Doe</td>
                  <td>Project Name</td>
                  <td>UI Design</td>
                  <td>Banner Designing</td>
                  <td>03</td>
                  <td>
                    <button className="btn-green">Approve</button>
                    <button className="btn-red">Reject</button>
                  </td>
                </tr>
              </table>
            </div>
          )} */}
        </div>
        {!loader && searchedDataPageCounts.length > usersPerPage && (
          <Pagination
            data={timesheetApprovalList}
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
            searchValue={searchValue}
            searchedDataPageCounts={searchedDataPageCounts}
          />
        )}
      </div>
    </>
  )
}

export default TimesheetApproval
