import React, { useState, useContext, useEffect } from 'react'
import { AppContext } from '../../context/AppContext'
import {
  DeleteCallWithErrorResponseWithToken,
  PostCallWithErrorResponse,
  deleteWithAuthCall,
  simpleGetCallWithToken
} from '../../api/ApiServices'
import ApiConfig from '../../api/ApiConfig'
import Editac from '../../assets/images/Editac.svg'
import DelectAc from '../../assets/images/DelectAc.svg'
import Axe from '../../assets/images/sidebar/icons/axeMark.svg'
import Modal from 'react-bootstrap/Modal'
import { ToastMsg } from '../TostMsg'
import Pagination from '../../sharedComponent/Pagination'
import Loader from '../Loader'
import NoData from '../../sharedComponent/NoData'
import Select from 'react-select'
import BackDropLoader from '../../sharedComponent/BackDropLoader'
import { notifyError } from '../../toaster/notify'

const SalaryStructure = () => {
  const loggedEmployeeId = localStorage?.EmpId && Number(localStorage?.EmpId)
  const [employeeId, setEmployeeId] = useState(null)
  console.log('employeeId,', employeeId)
  const initialData = [
    {
      EmpSalaryStructureId: 0,
      EmployeeId: employeeId,
      SalaryStructureId: 1,
      MonthlyAmount: 0
    },
    {
      EmpSalaryStructureId: 0,
      EmployeeId: employeeId,
      SalaryStructureId: 2,
      MonthlyAmount: 0
    },
    {
      EmpSalaryStructureId: 0,
      EmployeeId: employeeId,
      SalaryStructureId: 3,
      MonthlyAmount: 0
    },
    {
      EmpSalaryStructureId: 0,
      EmployeeId: employeeId,
      SalaryStructureId: 4,
      MonthlyAmount: 0
    },
    {
      EmpSalaryStructureId: 0,
      EmployeeId: employeeId,
      SalaryStructureId: 5,
      MonthlyAmount: 0
    },
    {
      EmpSalaryStructureId: 0,
      EmployeeId: employeeId,
      SalaryStructureId: 6,
      MonthlyAmount: 0
    },
    {
      EmpSalaryStructureId: 0,
      EmployeeId: employeeId,
      SalaryStructureId: 7,
      MonthlyAmount: 0
    }
  ]

  const { sidebar,permissions} = useContext(AppContext)
  // STATES

  const [loading, setLoading] = useState(false)
  const [teamName, setTeamName] = useState([])
  const [desigName, setDesigName] = useState([])
  const [employeeattlist, setEmployeeattlist] = useState([])
  console.log('employeeattlist,', employeeattlist)
  const [Edit, setEdit] = useState(false)
  const [addCost, setAddCost] = useState({
    costDataId: 0,
    TeamId: 0,
    DesignationId: 0,
    cost: 0
  })
  const [selectLoader, setSelectLoader] = useState(false)
  const [totalEarnValue, setTotalEarnValue] = useState(null)
  const [employeeSalaryData, setEmployeeSalaryData] = useState([])
  console.log('employeeSalaryData,', employeeSalaryData)
  // STATES

  const changeData = e => {
    const { name, value } = e.target
    setAddCost({
      ...addCost,
      [name]: value
    })
  }

  const fetchTeamName = () => {
    simpleGetCallWithToken(ApiConfig.GET_DROPDOWNTEAMNAME_LIST)
      .then(res => {
        if (res.Success === true) {
          setTeamName(res.Data)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const submitHandle = e => {
    e.preventDefault()
    let payloadData = {
      TeamId: JSON.parse(addCost.TeamId),
      DesignationId: JSON.parse(addCost.DesignationId),
      cost: JSON.parse(addCost.cost)
    }
    PostCallWithErrorResponse(
      ApiConfig.POST_COST_DATA,
      JSON.stringify(payloadData)
    )
      .then(res => {
        if (res.json.Success === true) {
          ToastMsg('success', res.json.Message)
          setAddCost({
            ...addCost,
            TeamId: 0,
            DesignationId: 0,
            cost: 0
          })
          fetchEmpList()
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const fetchEmpList = () => {
    setSelectLoader(true)
    simpleGetCallWithToken(ApiConfig.GET_DROPDOWN_EMPLOYEE_NAMES)
      .then(res => {
        if (res.Success === true) {
          let employeDropDownList = res?.Data.map(data => {
            return {
              value: data?.Id,
              label: data?.FirstName
            }
          })
          setEmployeeattlist(employeDropDownList)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setSelectLoader(false)
      })
  }

  // SALARY STRUCTURED API CALL BY SEARCH ID
  const fetchSalaryStructureDatabyId = () => {
    setLoading(true)
    let payload = {
      EmpId: employeeId
    }
    PostCallWithErrorResponse(
      ApiConfig.GET_EMPLOYEE_SALARY_STRUCTURE,
      JSON.stringify(payload)
    )
      .then(res => {
        if (res.json.Success === true) {
          var salaryStructureData = res.json.Data.length > 0 && res.json.Data
          let getData =
            salaryStructureData &&
            salaryStructureData.map(ele => {
              return {
                EmpSalaryStructureId: ele?.Id ? ele?.Id : 0,
                EmployeeId: ele?.EmployeeId ? ele?.EmployeeId : employeeId,
                SalaryStructureId: ele?.SalaryStructureId
                  ? ele?.SalaryStructureId
                  : 0,
                MonthlyAmount: ele?.MonthlyAmount ? ele?.MonthlyAmount : 0
              }
            })
          salaryStructureData
            ? setEmployeeSalaryData(getData)
            : setEmployeeSalaryData(initialData)

          const totalEarnings =
            getData &&
            getData
              .map(item => {
                if (item?.SalaryStructureId === 7) {
                  return 0
                } else {
                  return item?.MonthlyAmount
                }
              })
              .reduce((total, monthlyAmount) => total + monthlyAmount, 0)

          totalEarnings
            ? setTotalEarnValue(totalEarnings)
            : setTotalEarnValue(null)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const addORUpdateSalaryStructure = e => {
    e.preventDefault()
    let payLoad = {
      EmployeeStructures: employeeSalaryData
    }
    setLoading(true)
    PostCallWithErrorResponse(
      ApiConfig.ADD_UPDATE_SALARY_STRUCTURE,
      JSON.stringify(payLoad)
    )
      .then(res => {
        if (res.json.Success === true) {
          ToastMsg('success', res.json.Message)
        } else {
          console.log('res,', res)
          ToastMsg('error', 'Something went wrong')
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const deleteSalaryStructure = () => {
    setLoading(true)
    let payload = {
      EmpId: employeeId
    }
    DeleteCallWithErrorResponseWithToken(
      ApiConfig.DELETE_SALARY_STRUCTURE,
      JSON.stringify(payload)
    )
      .then(res => {
        if (res.json.Success === true) {
          ToastMsg('success', 'Salary structure deleted successfully')
          fetchEmpList()
          setEmployeeSalaryData(initialData)
          setTotalEarnValue(null)
        }
      })
      .catch(err => {
        console.log('Catch error response', err)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchTeamName()
    fetchEmpList()
  }, [])

  const salaryStructureCalculations = event => {
    event.preventDefault()
    const totalEarnings = event.target.value ? Number(event.target.value) : 0
    setTotalEarnValue(totalEarnings ? totalEarnings : '')
    let bacis = totalEarnings * 0.5
    let HRA = bacis * 0.4
    let conveyance = 1250
    let medicalAllowance = 1600
    let educationAllowance = 500
    let totalAmt =
      bacis + HRA + conveyance + medicalAllowance + educationAllowance

    let others = totalEarnings && totalEarnings - totalAmt

    let professionalTax = 200

    let calculatedSalaryStructureArr =
      employeeSalaryData &&
      employeeSalaryData.length > 0 &&
      employeeSalaryData.map(item => {
        let calculatedAmt =
          item?.SalaryStructureId === 1
            ? bacis
            : item?.SalaryStructureId === 2
            ? HRA
            : item?.SalaryStructureId === 3
            ? conveyance
            : item?.SalaryStructureId === 4
            ? medicalAllowance
            : item?.SalaryStructureId === 5
            ? educationAllowance
            : item?.SalaryStructureId === 6
            ? Math.abs(others)
            : item?.SalaryStructureId === 7
            ? professionalTax
            : 0

        return {
          ...item,
          MonthlyAmount: Number(calculatedAmt?.toFixed(2))
        }
      })
    setEmployeeSalaryData(calculatedSalaryStructureArr)
  }

  return (
    <>
      {loading && <BackDropLoader />}
      <div
        className={sidebar ? 'taskMain ' : 'cx-active taskMain'}
        id='cx-main'
      >
        <div className='role-content'>
          <div className='sub-header-role '>
            <h6 className='mt-2'>Employee Salary Structure</h6>
          </div>
          <div className='innerRoleSec'>
            <div className='row d-flex'>
              <div className='col-lg-4 col-md-4'>
                <div className='mb-4'>
                  <label htmlFor='' className='mb-2'>
                    Select Employee Name
                  </label>
                  <Select
                    value={
                      employeeattlist.length &&
                      employeeattlist.find(item => item?.value == employeeId)
                    }
                    options={employeeattlist}
                    isLoading={selectLoader}
                    onChange={event => {
                      setEmployeeId(event?.value)
                    }}
                  />
                  {/* <select
                    name="TeamId"
                    value=""
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Select Employee Name"
                  >
                    <option value="">Select Employee Name</option>
                    {
                      employeeattlist 
                      && employeeattlist.map(ele => {
                        return (
                          <option value={ele?.Id}>{ele?.FirstName}</option>
                        )
                      })
                    }
                  </select> */}
                </div>
              </div>
              <div className='col-md-12'>
                <button
                  className='salary_structure_btn'
                  type='button'
                  onClick={employeeId && fetchSalaryStructureDatabyId}
                >
                  <span style={{ fontWeight: 'bold' }}>+ </span>
                  Search
                </button>
                {
                  permissions&&permissions['Salary Structure Delete']&&
                <button
                  className='salary_structure_btn mx-3'
                  type='button'
                  onClick={employeeId && deleteSalaryStructure}
                >
                  <span style={{ fontWeight: 'bold' }}>+ </span>
                  Delete Structure
                </button>
                }
              </div>
            </div>
          </div>
          {
            permissions&&permissions['Salary Structure Create']&&
          <div className='table-wrapper mt-5'>
            <table>
              <thead>
                <tr className='firstTabelRow'>
                  <th>Earnings</th>
                  <th>Amount</th>
                  <th>Deductions</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr className='table-row-custom'>
                  <td>Basic</td>
                  <td>
                    <input
                      type='number'
                      className='tasKCategory1 form-control'
                      disabled
                      value={
                        employeeSalaryData &&
                        employeeSalaryData?.length > 0 &&
                        employeeSalaryData[0]?.MonthlyAmount
                      }
                    />
                  </td>
                  <td>Professional Tax</td>
                  <td>
                    <input
                      type='number'
                      className='tasKCategory1 form-control'
                      disabled
                      value={
                        employeeSalaryData &&
                        employeeSalaryData?.length > 0 &&
                        employeeSalaryData[6]?.MonthlyAmount
                      }
                    />
                  </td>
                  {/* <td> </td>
                  <td> </td> */}
                </tr>
                <tr className='table-row-custom'>
                  <td>HRA</td>
                  <td>
                    <input
                      type='number'
                      className='tasKCategory1 form-control'
                      disabled
                      value={
                        employeeSalaryData &&
                        employeeSalaryData?.length > 0 &&
                        employeeSalaryData[1]?.MonthlyAmount
                      }
                    />
                  </td>
                  <td> </td>
                  <td> </td>
                </tr>
                <tr className='table-row-custom'>
                  <td>Conveyance</td>
                  <td>
                    <input
                      type='number'
                      className='tasKCategory1 form-control'
                      disabled
                      value={
                        employeeSalaryData &&
                        employeeSalaryData?.length > 0 &&
                        employeeSalaryData[2]?.MonthlyAmount
                      }
                    />
                  </td>
                  <td> </td>
                  <td> </td>
                </tr>
                <tr className='table-row-custom'>
                  <td>Medical Allowance</td>
                  <td>
                    <input
                      type='number'
                      className='tasKCategory1 form-control'
                      disabled
                      value={
                        employeeSalaryData &&
                        employeeSalaryData?.length > 0 &&
                        employeeSalaryData[3]?.MonthlyAmount
                      }
                    />
                  </td>
                  <td> </td>
                  <td> </td>
                </tr>
                <tr className='table-row-custom'>
                  <td>Education Allowance</td>
                  <td>
                    <input
                      type='number'
                      className='tasKCategory1 form-control'
                      disabled
                      value={
                        employeeSalaryData &&
                        employeeSalaryData?.length > 0 &&
                        employeeSalaryData[4]?.MonthlyAmount
                      }
                    />
                  </td>
                  <td> </td>
                  <td> </td>
                </tr>
                <tr className='table-row-custom'>
                  <td>Others</td>
                  <td>
                    <input
                      type='number'
                      className='tasKCategory1 form-control'
                      disabled
                      value={
                        employeeSalaryData &&
                        employeeSalaryData?.length > 0 &&
                        employeeSalaryData[5]?.MonthlyAmount
                      }
                    />
                  </td>
                  <td> </td>
                  <td> </td>
                </tr>
                <tr className='table-row-custom'>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                </tr>
                <tr className='table-row-custom'>
                  <td>(A) Total Earnings</td>
                  <td
                    onClick={() => {
                      !employeeId && notifyError('Please select employee name')
                    }}
                  >
                    <input
                      type='number'
                      className='tasKCategory1 form-control'
                      value={totalEarnValue ? totalEarnValue : ''}
                      disabled={!employeeId}
                      onChange={e =>
                        employeeId && salaryStructureCalculations(e)
                      }
                    />
                  </td>
                  <td>(B) Total Deductions</td>
                  <td>
                    <input
                      type='number'
                      className='tasKCategory1 form-control'
                      value={
                        employeeSalaryData &&
                        employeeSalaryData[6]?.MonthlyAmount
                          ? employeeSalaryData[6]?.MonthlyAmount
                          : ''
                      }
                      onChange={e => {
                        let { value } = e.target
                        let newArr =
                          employeeSalaryData &&
                          employeeSalaryData.length > 0 &&
                          employeeSalaryData.map((item, i) => {
                            return i === 6
                              ? {
                                  ...item,
                                  MonthlyAmount: Number(value)
                                    ? Number(value)
                                    : 0
                                }
                              : item
                          })
                        setEmployeeSalaryData(newArr)
                      }}
                    />
                  </td>
                </tr>
                <tr className='table-row-custom'>
                  <td colSpan={2}>
                    <button
                      className='salary_structure_btn'
                      type='button'
                      // onClick={e => addORUpdateSalaryStructure(e)}
                      onClick={employeeId && addORUpdateSalaryStructure}
                    >
                      Set Employee Salary Structure
                    </button>
                  </td>
                  <td>Net Salary = (A) - (B)</td>
                  <td>
                    <input
                      type='number'
                      className='tasKCategory1 form-control'
                      disabled
                      value={
                        (totalEarnValue && totalEarnValue) -
                          (employeeSalaryData &&
                            employeeSalaryData[6]?.MonthlyAmount) >
                          200 &&
                        (totalEarnValue && totalEarnValue) -
                          (employeeSalaryData &&
                            employeeSalaryData[6]?.MonthlyAmount)
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          }
        </div>
        {/* <Pagination
          data={employeeattlist}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        /> */}
      </div>

      {/* Edit Modal Task */}
      <Modal
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={Edit}
        onHide={() => setEdit(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor='' className='create-role-text'>
              Edit Cost
            </label>
          </Modal.Title>
          <div className='rightAxe'>
            <img src={Axe} alt='' onClick={() => setEdit(false)} />
          </div>
          {/* <img src={close} alt="" onClick={handleClose} className="cr-class" /> */}
        </Modal.Header>
        <div className='hr-line-modal'>
          <hr style={{ margin: '0' }} />
        </div>

        <Modal.Body>
          <div className='mb-4'>
            <label htmlFor='' className='enter-role-text'>
              Team Name
            </label>
            <select
              class='form-select tasKCategory1 select-drop-icon'
              aria-label='Default select example'
              value={addCost?.TeamId ? addCost?.TeamId : ''}
              onChange={e => {
                changeData(e)
              }}
              name='TeamId'
            >
              <option value=''>Team Name</option>
              {teamName.map((name, index) => {
                return (
                  <option value={name.TeamId} key={index}>
                    {name.TeamName}
                  </option>
                )
              })}
            </select>
          </div>
          <div className='mb-4'>
            <label htmlFor='' className='enter-role-text'>
              Designation
            </label>
            <select
              class='form-select tasKCategory1 select-drop-icon'
              aria-label='Default select example'
              value={addCost?.DesignationId ? addCost?.DesignationId : null}
              onChange={e => {
                changeData(e)
              }}
              name='DesignationId'
            >
              <option value=''>Select Designation</option>
              {desigName.map((name, index) => {
                return (
                  <option value={name.DesignationId} key={index}>
                    {name.Name}
                  </option>
                )
              })}
            </select>
          </div>
          <div className='modalProp mb-4'>
            <label htmlFor='' className='enter-role-text'>
              Cost
            </label>
            <input
              onChange={e => {
                changeData(e)
              }}
              name='cost'
              value={addCost?.cost ? addCost?.cost : null}
              type='number'
              className='date-label-input tasKCategory1'
              style={{
                fontSize: '14px',
                height: '38px',
                border: '1px solid #ced4da'
              }}
            />
          </div>
        </Modal.Body>

        <div className='hr-line-modal-1'>
          <hr style={{ margin: '0' }} />
        </div>
        <Modal.Footer className='model-footer-arbaz-responsive'>
          <div className='Bottom-btn-arbaz-responsive'>
            <button
              className='modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive'
              onClick={() => setEdit(false)}
            >
              Cancel
            </button>
            <button
              className='modal-create-btn branchList-modal-cancel-btn-arbaz-responsive'
              // onClick={updateCost}
            >
              Update
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SalaryStructure
