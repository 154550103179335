import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";

const RechargeWallet = () => {
    const { sidebar, setSidebar, Dark, setDark, userData } =
        useContext(AppContext);
    return (
        <>
            <div
                className={sidebar ? "taskMain " : "cx-active taskMain"}
                id="cx-main"
            >
                <div className="employee_wallet_main">
                    <div className="sub-header-role mb-4">
                        <h6 className="mt-2"> <Link to="/EmpoloyeeWallet">Employee Wallet</Link> / Recharge Wallet </h6>
                    </div>
                    <div className="mainAmntBox">
                        <div className="header">
                            <h1>Recharge Wallet</h1>
                        </div>
                        <div className="body">
                            <div className="inputBox">
                                <label htmlFor="">Enter Amount</label>
                                <input type="number" className="form-control" placeholder="Enter Amount to add in wallet..." />
                            </div>
                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-3 threeBox">
                                    <h1>Total Amount</h1>
                                    <h1>Transaction Charge (1.5%)</h1>
                                </div>
                                <div className="col-md-3 threeBox">
                                    <h1>&#8377; 500</h1>
                                    <h1>&#8377; 15</h1>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="row">
                                <div className="col-md-6 colSix">
                                    <h1>Payment gateway transaction charge of 1.5% is applicable.</h1>
                                </div>
                                <div className="col-md-3 threeBox">
                                    <h1>Total Amount To Pay</h1>
                                </div>
                                <div className="col-md-3 threeBox">
                                    <h1>&#8377; 515</h1>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <button className="cancelBtn">Cancel</button>
                            <button className="payNow">Pay Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RechargeWallet;
