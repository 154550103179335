import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import Multiselect from "multiselect-react-dropdown";
import NoData from "../../sharedComponent/NoData";
import React, { useContext, useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import { AppContext } from "../../context/AppContext";
import { ToastMsg } from "../TostMsg";

import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";

const GeneralSetting = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const multiselectRef = useRef();
  const [employeeNames, setEmployeeNames] = useState([]);
  const [empName, setEmpName] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [genericUserId, setgenericUserId] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [defaultSelectedUser, setDefaultSelectedUser] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState([]);
  const [userId, setUserId] = useState([]);

  const [mapping, setMapping] = useState(false);
  console.log("selectedUser", selectedUser);

  const onSelect = (selectedList) => {
    console.log("selectedList", selectedList);
    let employeeIdArr =
      selectedList &&
      selectedList.map((item) => {
        return item.id;
      });
    setSelectedUser(employeeIdArr);
    // let Employees = employeeIdArr;
    // console.log("IDS", Employees);
  };
  const onRemove = (selectedList, removedItem) => {
    console.log(
      "onRemoveselectedList ",
      selectedList,
      "removedItem",
      removedItem
    );
    // let employeeIdArr = selectedList && selectedList.map(item => { return item.id })
    // setAssignData({
    //     ...assignData,
    //     EmployeeId: employeeIdArr
    // })
  };

  const fetchEmpName = () => {
    PostCallWithErrorResponse(ApiConfig.EMPLOEE_NAME_LIST)
      .then((res) => {
        // console.log('ATTENDANCE_PATH==>', res)
        if (res.json.Success === true) {
          setEmployeeNames(res.json.Data);
          setEmpName(res.json.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changePasswd = (e) => {
    alert("Function call");
    let data = {
      Id: userId,
      ChangePassword: newPassword,
    };
    PostCallWithErrorResponse(
      ApiConfig.CHANGE_PASSWORD_BY_ADMIN
      //  JSON.stringify(data)
    );
    alert("Inside API")
      .then((res) => {
        console.log("Response ---->>>>", res);
        if (res.json.Success === true) {
          ToastMsg("success", res.json?.Message);
          setEmpName(res.json.Data);
        } else {
          ToastMsg("error", res.json?.Message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitGenericUser = (e) => {
    e.preventDefault();
    let data = {
      Id: 0,
      GenericUserId: genericUserId,
      Employees: selectedUser,
    };
    PostCallWithErrorResponse(ApiConfig.SET_GENERIC_USERS, JSON.stringify(data))
      .then((res) => {
        if (res.json.Success === true) {
          setgenericUserId(0);
          setSelectedUser([]);
          multiselectRef.current.resetSelectedValues();
          swal(res.json.Message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGenericUserMapping = (id) => {
    console.log("getGenericUserMapping", id);
    simpleGetCallWithToken(ApiConfig.GET_GENERIC_USER_MAPPING + id)
      .then((res) => {
        console.log("getGenericUserMapping", res);
        if (res.Success === true && res.Data.EmployeeDetails.length > 0) {
          setMapping(true);
          setSelectedEmployee(res?.Data?.EmployeeDetails);
        } else {
          fetchEmpName();
          setMapping(false);
        }
      })
      .catch((err) => {
        setMapping(false);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchEmpName();
  }, []);

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">General Setting</h6>
          </div>
          <div className="settingsCard">
            <div className="row">
              <div className="col-lg-6">
                <div className="my-3">
                  <label htmlFor="" className="enter-role-text mt-2">
                    Generic User
                  </label>
                  <select
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setgenericUserId(Number(e.target.value));
                      getGenericUserMapping(Number(e.target.value));
                    }}
                    value={genericUserId}
                  >
                    <option selected value="0">
                      Select Compliance Type
                    </option>
                    <option value="279">Accounts</option>
                    <option value="5032">Human Resource</option>
                    <option value="5033">Delivery Escalation</option>
                    <option value="5034">Application Support</option>
                    <option value="5035">Server Support</option>
                    {/* {
                                        empName.map((name, index) => {
                                            return (
                                                <option value={name.ProjectId}>{name.ProjectName}</option>
                                            )
                                        })
                                    } */}
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="my-3">
                  <label htmlFor="" className="enter-role-text mt-2">
                    Forward To
                  </label>
                  {mapping == true ? (
                    <Multiselect
                      options={employeeNames?.map((data) => {
                        return { id: data.Id, name: data.FullName };
                      })}
                      selectedValues={selectedEmployee?.map((data) => {
                        return { id: data.EmployeeId, name: data.EmployeeName };
                      })}
                      ref={multiselectRef}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="name"
                      closeOnSelect="false"
                      className="form-select tasKCategory1 select-drop-icon"
                    />
                  ) : (
                    <Multiselect
                      options={employeeNames?.map((data) => {
                        return { id: data.Id, name: data.FullName };
                      })}
                      ref={multiselectRef}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="name"
                      closeOnSelect="false"
                      className="form-select tasKCategory1 select-drop-icon"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12 text-end mt-2">
              <button
                className="impButton"
                onClick={(e) => {
                  submitGenericUser(e);
                }}
              >
                Submit
              </button>
            </div>
          </div>

          <div className="settingsCard">
            <div className="row">
              <div className="col-lg-6">
                <div className="my-3">
                  <label htmlFor="" className="enter-role-text mt-2">
                    User Name 1
                  </label>
                  <select
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setUserId(Number(e.target.value));
                    }}
                    value={userId}
                  >
                    <option selected value="0">
                      Select User Name
                    </option>
                    {empName.map((name, index) => {
                      return <option value={name.Id}>{name.FullName}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="my-3">
                  <label htmlFor="" className="enter-role-text mt-2">
                    Change Password
                  </label>
                  <input
                    value={newPassword}
                    name="ChangePassword"
                    type="text"
                    className="date-label-input tasKCategory1"
                    style={{
                      //   fontSize: "14px",
                      //   height: "38px",
                      borderRadius: "10px",
                    }}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 text-end mt-2">
              <button
                className="impButton"
                onClick={(e) => {
                  //changePasswd(e);
                }}
              >
                Submit
              </button>
            </div>
          </div>

          {/* Add Locations */}
          <div className="settingsCard">
            <br />
            <h3>Add Department</h3>
            <div className="col-lg-6">
              <div className="my-3">
                <label htmlFor="" className="enter-role-text mt-2">
                  Add New Department
                </label>
                <input
                  //onChange={handleChange}
                  //value={addEmply.MiddleName}
                  name="password"
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    //   fontSize: "14px",
                    //   height: "38px",
                    borderRadius: "10px",
                  }}
                />{" "}
                <br />
                <br />
                <button
                  className="impButton"
                  onClick={(e) => {
                    //submitGenericUser(e);
                  }}
                >
                  Add
                </button>
              </div>
              <div className="col-md-12 text-end mt-2"></div>
            </div>
            {/*<div className="row">
              <div className="col-lg-6"> */}
            <table id="tabledash">
              <thead>
                <tr className="table-row">
                  <th className="col">Sr.no</th>
                  <th className="col">Department</th>
                  <th className="col">Edit / Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>HR</td>
                  <td>Edit-icn / Delete-icn</td>
                </tr>
                {/* {
                    departmentData.slice(startIndex, endIndex).map((data, index) =>
                        <tr className="table-row-custom">
                            <td>{startIndex + index + 1}</td>
                            <td>
                                {/* <Link to="/ProjectAssignment"> 
                                {data.EmployeeName}
                                {/* </Link> 
                            </td>
                            <td>{data.ProjectName}</td>
                            <td> <Link to="/ProjectAssignment">
                                <button type="button"className="btn-infoaf">Assign</button>
                                </Link>                               
                            </td>
                        </tr>
                    )
                } */}
              </tbody>
            </table>
            {/* </div>
            </div> */}
            <div className="col-md-12 text-end mt-2">
              <button
                className="impButton"
                onClick={(e) => {
                  //submitGenericUser(e);
                }}
              >
                Submit
              </button>
            </div>
          </div>

          {/*End  Add Locations */}
        </div>
      </div>
    </>
  );
};

export default GeneralSetting;
